import server from 'api/server';
import TextField from 'components/_common/FormFields/TextField';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { required } from 'redux-form-validators';

const Forgot = (props) => {
  const { handleSubmit, submitting } = props;
  const [error, setError] = useState(null);
  async function onSubmit(values) {
    try {
      await server.post('/auth/reset/send', { ...values });
      props.onSuccess(values.email);
      setError(null);
    } catch (err) {
      setError(err.response.data);
    }
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h4 className="title is-5">Forgot Password</h4>
      <Field
        label="We'll send a recovery code to"
        className="input"
        placeholder="Enter Email"
        validate={[required({ msg: 'Please provide your email address' })]}
        component={TextField}
        type="email"
        name="email"
      />

      <p className="help is-danger">{error && <span>{error}</span>}</p>

      <div className={`field`}>
        <button
          type="submit"
          className={`button is-fullwidth is-info ${submitting && 'is-loading'}`}
        >
          Send email
        </button>
      </div>
      <span className="login-links">
        <Link to={`/login${window.location.search}`}>Return to log in</Link>
      </span>
    </form>
  );
};

const VerifyCode = (props) => {
  const { handleSubmit, submitting } = props;
  const [error, setError] = useState(null);
  const [resend, setResend] = useState(null);
  async function onSubmit(values) {
    try {
      await server.post('/auth/reset/verify', {
        email: props.email,
        ...values
      });
      props.onSuccess(values.code);
      setError(null);
    } catch (err) {
      setError(err.response.data);
    }
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h4 className="title is-5">Verify Recovery Code</h4>
      {resend && (
        <article className="message is-info">
          <div className="message-body">
            Code has been sent again! Please check your email.
          </div>
        </article>
      )}
      <Field
        label="Enter the recovery code"
        className="input"
        placeholder="Enter code"
        validate={[required({ msg: 'Please provide your code' })]}
        component={TextField}
        name="code"
      />

      <p className="help is-danger">{error && <span>{error}</span>}</p>
      <div className={`field`}>
        <button
          type="submit"
          className={`button is-fullwidth is-info ${submitting && 'is-loading'}`}
        >
          Verify
        </button>
      </div>
      <span className="login-links">
        <a
          onClick={async (e) => {
            try {
              await server.post('/auth/reset/send', { email: props.email });
              setResend(true);
            } catch (err) {
              setError(err.response.data);
            }
          }}
        >
          Send code again?
        </a>
      </span>
    </form>
  );
};

const Reset = (props) => {
  const { handleSubmit, submitting } = props;
  const [error, setError] = useState(null);
  async function onSubmit(values) {
    try {
      await server.post('/auth/reset', {
        email: props.email,
        code: props.code,
        ...values
      });
      props.onSuccess();
      setError(null);
    } catch (err) {
      setError(err.response.data);
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h4 className="title is-5">Reset Password</h4>
      <Field
        label="Enter new password"
        className="input"
        validate={[required({ msg: 'Please provide your new password' })]}
        component={TextField}
        type="password"
        name="password"
      />

      <Field
        label="Confirm new password"
        className="input"
        validate={[required({ msg: 'Please confirm your new password' })]}
        component={TextField}
        type="password"
        name="confirmPassword"
      />

      <p className="help is-danger">{error && <span>{error}</span>}</p>

      <div className={`field`}>
        <button
          type="submit"
          className={`button is-fullwidth is-info ${submitting && 'is-loading'}`}
        >
          Reset Password
        </button>
      </div>
    </form>
  );
};

export const ResetForm = reduxForm({ form: 'reset' })(Reset);
export const VerifyCodeForm = reduxForm({ form: 'send' })(VerifyCode);
export const ForgotForm = reduxForm({ form: 'forgot' })(Forgot);
