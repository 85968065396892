import Loading from 'components/Auth/Loading';
import queryString  from 'query-string';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, useHistory  } from 'react-router-dom';
import { getUser, isActionPending, isLoggedIn } from 'redux/selectors';

const AuthRoute = (props) => {
  const loggedIn = useSelector((state) => isLoggedIn(state));
  const pendingLogin = useSelector((state) => isActionPending(state, 'LOGIN') ?? false);
  const pendingToken = useSelector((state) =>
    isActionPending(state, 'REFRESH_TOKEN')
  );
  const [loading, setLoading] = useState(!loggedIn);
  let history = useHistory();
  const location = history.location;

  const user = useSelector(getUser);

  useEffect(() => {
    if (loggedIn !== undefined && loggedIn) {
      setLoading(false);
    }
  }, [setLoading, loggedIn]);

  useEffect(() => {
    if (pendingToken && pendingToken.pending === false) {
      if (
        pendingToken.error &&
        pendingToken.error.isLoggedIn === false &&
        !loggedIn
      ) {
        let query = queryString.parse(location.search, { ignoreQueryPrefix: true });
        if (location.pathname) {
          query['redirect'] = location.pathname;
        }
        history.push(`/login${JSON.stringify(query)}`);
      }
    }
  }, [pendingToken, location, history]);

  const renderRoute = (routeProps) => {
    const { component } = props;

    if (loading) return <Loading />;

    if (pendingLogin && !pendingLogin.pending && !loggedIn) {
      let query = queryString.parse(location.search, { ignoreQueryPrefix: true });
      if (location.pathname) {
        query['redirect'] = location.pathname;
      }
      history.push(`/login${JSON.stringify(query)}`);
    }

    // Check if user has appropriate role.
    if (user && user.role && props.roles) {
      if (!props.roles.includes(user.role)){
        history.push(`/`);
      } 
    }

    const Component = component;
    return <Component {...routeProps} />;
  };

  const render = () => {
    const { component: __, ...rest } = props;
    return <Route {...rest} render={renderRoute} />;
  };
  return render();
};
export default AuthRoute;
