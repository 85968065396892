import React from 'react';
import styled from 'styled-components';

export const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  bottom: 4px;
  color: white;
  font-size: 12px;
  font-weight: bold;
  opacity: 0;
  padding: 10px;
  pointer-events: none;
  position: absolute;
  right: 4px;
  transition: opacity 0.21s ease-in-out;
  user-select: none;
  z-index: 9999999999;
`;

export const Container = styled.div`
  clear: both;
  position: relative;
  width: 100%;
  &:hover ${Overlay} {
    opacity: 1;
  }
  touch-action: ${(props) => (props.allowTouch ? 'pinch-zoom' : 'auto')};
`;

export const Img = styled.img`
  display: block;
  width: 100%;
`;

export const Items = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

export const Target = Items;
