import { Editor } from '@tinymce/tinymce-react';
import React from 'react';

const TinyEditor = (props) => {
  const { value, onChange } = props.input;
  return (
    <Editor
      apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
      value={value}
      init={{
        plugins: 'link image code',
        toolbar:
          'undo redo | bold italic | alignleft aligncenter alignright | link  ',
        menubar: '',
        relative_urls: false,
        remove_script_host: false,
        convert_urls: true
      }}
      onEditorChange={(val) => {
        onChange(val);
      }}
    />
  );
};
export default TinyEditor;
