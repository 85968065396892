import React, { useState, useEffect } from 'react';
import { FieldSelect } from '../Select'
import uniqid from 'uniqid'

import './KeyValuePair.scss'

const Pair = props => {
  const [key, setKey] = useState(props.keyV)
  const [value, setValue] = useState(props.value)
  useEffect(() => {
    props.onDataChange(props.id, key, value)
  }, [key, value, props.id])
  return (
    <div className='field has-addons'>
      <p className='control'>
        <input className='input'
          placeholder={props.keyPlaceholder || 'Key'}
          onChange={e => {
            setKey(e.target.value)
          }} value={key} />
      </p>
      <p className='control is-expanded'>
        {props.prevData ? <FieldSelect
          input={{ value, onChange: (val) => { setValue(val) } }}
          meta={{ touched: false, error: '', warning: '' }}
          multiple={false}
          options={props.prevData}
          typeFilter='field'
          placeholder={props.valuePlaceholder || 'Value'} /> : <input
            className='input'
            placeholder={props.valuePlaceholder || 'Value'}
            onChange={e => {
              setValue(e.target.value)
            }} value={value} />}
      </p>
      <p className='control'>
        <button onClick={(e) => {
          e.preventDefault()
          props.onDelete(props.id)
        }}
          className='button'><i className='fas fa-times'></i></button>
      </p>
    </div>
  )

}
const KeyValuePair = props => {
  const { value, onChange } = props.input

  const [list, setList] = useState(value || [{ key: '', value: '', id: uniqid() }])

  useEffect(() => {
    onChange(list)
  }, [list])

  return (
    <div className='keyvaluepair'>
      {list && list.map(({ id, key, value }) => <Pair
        id={id}
        key={id}
        keyV={key}
        value={value}
        keyPlaceholder={props.keyPlaceholder}
        valuePlaceholder={props.valuePlaceholder}
        prevData={props.prevData}
        onDelete={id => { setList(list.filter(obj => obj.id !== id)) }}
        onDataChange={(id, key, value) => {
          let pairIndex = list.findIndex(item => item.id === id)
          if (pairIndex !== -1) {
            let l = list.slice()
            l[pairIndex] = { key, value, id }
            setList(l)
          }
        }}
      />
      )}
      <div className='field'>
        <p className='control'>
          <button onClick={(e) => {
            e.preventDefault();
            setList([...list, { key: '', value: '', id: uniqid() }])
          }}
            className='button'><i className='fas fa-plus'></i></button>
        </p>
      </div>
    </div>
  )
}

export default KeyValuePair