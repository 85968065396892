import * as moment from 'moment';
import React, { useEffect, useState } from 'react';
import Datetime from 'react-datetime';
import './DateTimePicker.scss';

const DateTimePicker = (props) => {
  const [value, setValue] = useState(props.input.value);

  const {
    label,
    meta: { touched, error, warning }
  } = props;

  let dateTimeFormat = props.dateTimeFormat || 'MM/DD/YYYY';
  dateTimeFormat =
    typeof dateTimeFormat === 'object' && dateTimeFormat !== null
      ? dateTimeFormat.value
      : dateTimeFormat;

  useEffect(() => {
    props.input.onChange(value);
  }, [value]);

  useEffect(() => {
    const evaluated = moment(props.evaluatedValue);
    const val = evaluated.isValid() ? evaluated.format(dateTimeFormat) : '';
    setValue(val);
  }, [props.evaluatedValue]);

  const render = () => {
    return (
      <div className="field" style={props.hidden ? { display: 'none' } : {}}>
        <label className="label ">
          {label}{' '}
          {props.required && (
            <span className="has-text-grey">
              ({props.requiredLabel || 'required'})
            </span>
          )}
        </label>
        <div className="control">
          <Datetime
            value={value}
            dateFormat={dateTimeFormat}
            timeFormat={false}
            inputProps={{
              className: 'input ',
              disabled: props.static ? true : false
            }}
            onChange={(momentObj) => {
              if (momentObj.format) {
                const dateString = momentObj.format(dateTimeFormat);
                setValue(dateString);
                if (props.track) props.track(dateString);
              }
            }}
            onOpen={props.focus}
            closeOnSelect
          />
        </div>
        {props.description && <p className="help">{props.description}</p>}
        <p className="help is-danger">
          {touched &&
            ((error && <span>{error}</span>) ||
              (warning && <span>{warning}</span>))}
          {props.warning
            ? props.warnings.map((warning, i) => <span>{warning}</span>)
            : null}
        </p>
      </div>
    );
  };
  return render();
};

export default DateTimePicker;
