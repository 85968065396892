import tooltips from 'components/DocumentEditor/tooltips';
import React from 'react';
import { useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { getFields } from 'redux/selectors';
import { TextField } from './components';
import MultiSelect from './components/MultiSelect';

const GroupFieldForm = (props) => {
  const fields = useSelector((state) => getFields(state));
  // Find id of fields that are already in another group.
  const groupedFieldIds = [];
  fields
    .filter(
      (field) =>
        field.data.type === 'group' && field.data.id !== props.field.data.id
    )
    .map((field) => field.data.fields)
    .forEach((f) => {
      const ids = f ? f.map((field) => field.value) : [];
      groupedFieldIds.push(...ids);
    });

  // Filter out fields that are already in a group or are a group themselves.
  const validFields = [];
  fields.forEach(({ data: { name, id, label, type } }) => {
    if (type === 'group') return;
    if (groupedFieldIds.includes(id)) return;
    validFields.push({
      label: `${name} (${label})`,
      value: id
    });
  });

  function renderData() {
    return (
      <>
        <Field
          component={TextField}
          alphaNumeric
          required
          tooltip={tooltips.name}
          // illegal={fieldNames}
          max={24}
          label="Name"
          name="name"
          type="text"
          placeholder="Field Name"
        />
        <Field
          label="Label"
          component={TextField}
          tooltip={tooltips.label}
          name="label"
          type="text"
          inputType="textarea"
          placeholder="Form Label"
        />
        <Field
          label="Description"
          component={TextField}
          tooltip={tooltips.description}
          name="description"
          type="text"
          inputType="textarea"
          placeholder="Form Description"
        />

        <Field
          label="Fields"
          component={MultiSelect}
          options={validFields}
          name="fields"
          placeholder="Fields"
          field={props.field}
          fields={fields}
        />
      </>
    );
  }

  function render() {
    return (
      <div className="text_field_editor" onClick={props.onSelect}>
        <form
          onKeyPress={(e) => {
            e.key === 'Enter' && e.preventDefault();
          }}
        >
          {props.view === 'data' ? renderData() : <></>}
        </form>
      </div>
    );
  }
  return render();
};

export default reduxForm()(GroupFieldForm);
