import React, { useEffect } from 'react';

const TextField = (props) => {
  const {
    input,
    label,
    type,
    step,
    placeholder,
    track,
    meta: { touched, error, warning }
  } = props;
  let { value, onChange } = input;

  useEffect(() => {
    onChange(props.evaluatedValue);
  }, [props.evaluatedValue]);
  return (
    <div className="field" style={props.hidden ? { display: 'none' } : {}}>
      <label className="label ">
        {label}{' '}
        {props.required && (
          <span className="has-text-grey">
            ({props.requiredLabel || 'required'})
          </span>
        )}
      </label>
      <div className="control">
        <input
          className={`input  ${touched && error ? 'is-danger' : ''}`}
          value={props.evaluatedValue ? props.evaluatedValue : value}
          type={type}
          step={step}
          disabled={props.static ? true : false}
          placeholder={placeholder}
          onBlur={() => (track ? track(value) : null)}
          onChange={(e) => {
            type === 'number'
              ? onChange(parseFloat(e.target.value))
              : onChange(e.target.value);
          }}
          onFocus={props.focus}
        />
      </div>
      {props.description && <p className="help">{props.description}</p>}
      <p className="help is-danger">
        {touched &&
          ((error && <span>{error}</span>) ||
            (warning && <span>{warning}</span>))}
        {props.warning
          ? props.warnings.map((warning, i) => <span>{warning}</span>)
          : null}
      </p>
    </div>
  );
};

export default TextField;
