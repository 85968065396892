import React, { useEffect } from 'react';

const TextArea = (props) => {
  useEffect(() => {
    props.input.onChange(props.evaluatedValue);
  }, [props.evaluatedValue]);

  const {
    input,
    label,
    placeholder,
    track,
    meta: { touched, error, warning }
  } = props;
  let { value, onChange } = input;

  return (
    <div className="field" style={props.hidden ? { display: 'none' } : {}}>
      <label className="label ">
        {label}{' '}
        {props.required && (
          <span className="has-text-grey">
            ({props.requiredLabel || 'required'})
          </span>
        )}
      </label>
      <div className="control">
        <textarea
          className={`textarea  ${touched && error ? 'is-danger' : ''}`}
          value={props.evaluatedValue ? props.evaluatedValue : value}
          disabled={props.static ? true : false}
          placeholder={placeholder}
          onBlur={() => (track ? track(value) : null)}
          onChange={(e) => {
            value = e.target.value;
            onChange(value);
          }}
          onFocus={props.focus}
        />
      </div>
      {props.description && <p className="help">{props.description}</p>}
      <p className="help is-danger">
        {touched &&
          ((error && <span>{error}</span>) ||
            (warning && <span>{warning}</span>))}
        {props.warning
          ? props.warnings.map((warning, i) => <span>{warning}</span>)
          : null}
      </p>
    </div>
  );
};

export default TextArea;
