import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setCurrentPage } from 'redux/actions';
import { getCurrentPage, getRenderedPages } from 'redux/selectors';

import {
  thumbnailHeight, thumbnailPadding
} from 'constants/js/documentEditor'
import './Thumbnail.scss';



class Thumbnail extends Component {

  constructor(props) {
    super(props);
    this.thumb = React.createRef();
  }
  onClick = e => {
    this.props.setCurrentPage(this.props.index + 1);
  }
  handleActive(index, currentPage) {

    const isActive = currentPage === index + 1;
    if (isActive && this.thumb.current) {

      // this.thumb.current.scrollIntoView({
      //   block: 'start', behavior: 'smooth'
      // });

      return 'thumbnail container selected';
    } else if (isActive) {
      return 'thumbnail container selected';
    } else {
      return 'thumbnail';
    }

  }
  renderPage(pageNumber) {
    if (this.props.pages[pageNumber]) {
      return <img className='page' key={pageNumber} height={thumbnailHeight} src={this.props.pages[pageNumber]} />
    } else {
      return <p>Loading...</p>
    }
  }

  render() {
    const { index, style, currentPage } = this.props;
    const classes = this.handleActive(index, currentPage);

    return <div className={classes}
      style={{
        ...style,
        top: `${parseFloat(style.top) + thumbnailPadding}px`,
        margin: `0 30px`
      }}
      onClick={this.onClick} ref={this.thumb}>
      <div style={{ height: `${60}px` }}></div>
      {this.renderPage(index)}
      <span className='page_number is-small'>{index + 1}</span>
      <div style={{ height: `${20}px` }}></div>
    </div>
  }
}
const mapStateToProps = state => ({
  currentPage: getCurrentPage(state),
  pages: getRenderedPages(state)
});

export default connect(mapStateToProps, {
  setCurrentPage
})(Thumbnail)