import React from 'react';

export const getIcon = (type) => {
  switch (type) {
    case 'document':
    case 'intermediateForm':
      return <i className="fas fa-clipboard-list step_icon" />;
    case 'email':
      return (
        <img className="step_logo" src="/assets/images/gmail.png" alt="Email" />
      );
    case 'smartvault':
    case 'smartvaultClientVault':
    case 'smartvaultCustomLocation':
      return (
        <img
          className="step_logo"
          src="/assets/images/smartvault-logo.png"
          alt="SmartVault"
        />
      );
    case 'drive':
      return (
        <img className="step_logo" src="/assets/images/drive.png" alt="Drive" />
      );
    case 'slack':
      return (
        <img className="step_logo" src="/assets/images/slack.png" alt="Slack" />
      );
    case 'zapier':
      return (
        <img
          className="step_logo zapier_logo"
          src="https://cdn.zapier.com/zapier/images/logos/zapier-logomark.png"
          alt="Zapier"
        />
      );
    case 'outgoingWebhook':
    case 'incomingWebhook':
      return (
        <img
          className="step_logo"
          src="/assets/images/webhook.png"
          alt="Webhook"
        />
      );
    default:
      return null;
  }
};

export default [
  {
    type: 'document',
    category: 'Trigger',
    enabled: true,
    name: 'Online Form',
    icon: getIcon('document'),
    description: 'Enter fields data an with online form '
  },
  {
    type: 'incomingWebhook',
    category: 'Trigger',
    enabled: true,
    name: 'Incoming Webhook',
    icon: getIcon('incomingWebhook'),
    description: 'Trigger workflow from an API request'
  },
  {
    type: 'intermediateForm',
    category: 'Action',
    enabled: true,
    name: 'Online Form',
    icon: getIcon('document'),
    description: 'Complete your document by adding additional data'
  },
  {
    type: 'email',
    category: 'Action',
    enabled: true,
    name: 'Email',
    icon: getIcon('email'),
    description: 'Send email with or without attachment'
  },
  {
    type: 'smartvault',
    enabled: true,
    category: 'Action',
    name: 'SmartVault Prospective Clients',
    icon: getIcon('smartvault'),
    description:
      'Upload completed document to Prospective Clients folder in SmartVault'
  },
  {
    type: 'smartvaultClientVault',
    enabled: true,
    category: 'Action',
    name: 'SmartVault Upload to Client Vault',
    icon: getIcon('smartvault'),
    description: 'Upload completed document to Client vault in SmartVault'
  },
  {
    type: 'smartvaultCustomLocation',
    enabled: true,
    category: 'Action',
    name: 'SmartVault Upload to Custom Location in Vault',
    icon: getIcon('smartvault'),
    description:
      'Upload completed document to a predefined location in SmartVault'
  },
  {
    type: 'drive',
    enabled: false,
    category: 'Action',
    name: 'Google Drive',
    icon: getIcon('drive'),
    description: 'Upload file to Google Drive'
  },
  {
    type: 'slack',
    category: 'Action',
    enabled: true,
    name: 'Slack Notification',
    icon: getIcon('slack'),
    description: 'Send slack notification via webhook'
  },
  {
    type: 'outgoingWebhook',
    category: 'Action',
    enabled: true,
    name: 'Outgoing Webhook',
    icon: getIcon('outgoingWebhook'),
    description: 'Send HTTP request to external service'
  }
];
