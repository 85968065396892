/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useSelector } from 'react-redux';
import { getFields } from 'redux/selectors';
import InputEditor from '../../InputEditor/index';

const ValueEditor = (props) => {
  const { value, handleOnChange, fieldData, operator } = props;

  const { data } = fieldData;
  const fields = useSelector(getFields);

  if (!operator || !fieldData) {
    return (
      <div className="field value_editor">
        <p className="control is-expanded"></p>
      </div>
    );
  }

  if (['null', 'notNull'].includes(operator)) {
    return null;
  }

  const renderInput = () => {
    const fieldOptions = [];
    fields.forEach((i) => {
      if (i.data.type === data.type)
        fieldOptions.push({ value: i.data.name, label: i.data.name });
    });
    switch (data.type) {
      case 'text': {
        if (data.dataType === 'multiple') {
          return (
            <InputEditor
              fields={fieldOptions}
              options={[
                ...data.fieldOptions.map((i) => ({ value: i.id, label: i.id }))
              ]}
              value={value}
              multiple={true}
              placeholder="Condition value"
              onChange={handleOnChange}
            />
          );
        } else {
          return (
            <InputEditor
              fields={fieldOptions}
              value={value}
              multiple={true}
              onChange={handleOnChange}
            />
          );
        }
      }
      case 'checkbox': {
        return (
          <InputEditor
            fields={fieldOptions}
            options={[
              { value: 'true', label: 'true' },
              { value: 'false', label: 'false' }
            ]}
            value={value}
            multiple={true}
            onChange={handleOnChange}
          />
        );
      }
      case 'dateTime':
        return (
          <InputEditor
            fields={fieldOptions}
            options={[
              { value: 'dateOfSubmission', label: 'Date of Submission' }
            ]}
            value={value}
            message="Please enter dates in YYYY-MM-DD format"
            multiple={true}
            onChange={handleOnChange}
          />
        );
      default:
        return (
          <InputEditor
            fields={fieldOptions}
            value={value}
            multiple={true}
            onChange={handleOnChange}
          />
        );
    }
  };
  return (
    <div className="field value_editor">
      <p className="control is-expanded">{renderInput()}</p>
    </div>
  );
};

export default ValueEditor;
