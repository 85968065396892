/* eslint-disable array-callback-return */
import axios from 'axios';
import {publicIp} from 'public-ip';
import React, { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useMediaQuery } from 'react-responsive';
import scrollIntoView from 'scroll-into-view';
import Auth from '../components/Auth';
import Form from '../components/Form';
import Preview from '../components/Preview';
import InnerHtml from 'components/_common/InnerHtml/InnerHtml';
import './TriggerForm.scss';
import { isInactiveAccountError } from '../helper';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const TriggerDocumentForm = (props) => {
  const stepId = props.match.params.id;

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [active, setActive] = useState(false);
  const [fields, updateFields] = useState([]);
  const [workflowId, updateWorkflowId] = useState('');
  const [workflowRunId, updateWorkflowRunId] = useState(
    props.match.params.workflowRunId
  );
  const [resourceURL, updateResourceURL] = useState(null);
  const [fileName, setFileName] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [isLoading, updateLoading] = useState(true);
  const [success, updateSuccess] = useState(false);
  const [download, setDownload] = useState(false);
  const [access, setAccess] = useState(null);
  const [token, setToken] = useState(null);
  const [submissionMessage, setSubmissionMessage] = useState(null);
  const [mode, setMode] = useState('normal');

  const [displayFields, setDisplayFields] = useState('all');
  const [selectedFields, setSelectedFields] = useState(null);
  const [initialValues, setInitialValues] = useState(null);
  const [displayDocument, setDisplayDocument] = useState(false);
  const [documentURL, setDocumentURL] = useState(null);
  const [labels, setLabels] = useState({});
  const [redirectURL, setRedirectURL] = useState(null);
  const [brand, setBrand] = useState(null);
  const [activeField, setActiveField] = useState(null);
  const [previewDocument, setPreviewDocument] = useState(false);

  // Tracking information.
  const [ip, setIp] = useState(null);
  const [userAgent, setUserAgent] = useState(null);
  const [startDateTime] = useState(new Date());
  const [trackingData, setTrackingData] = useState([]);

  const recaptchaRef = React.createRef();

  useEffect(() => {
    scrollIntoView(document.getElementById(activeField));
  }, [activeField]);

  useEffect(() => {
    const fetchFields = async () => {
      try {
        let response;
        if (workflowRunId) {
          response = await axios.get(
            `${baseURL}/form/document/${stepId}/${workflowRunId}`
          );
        } else {
          response = await axios.get(`${baseURL}/form/document/${stepId}`);
        }
        const { data, brand } = response.data;
        setBrand(brand);
        if (response.status === 200) {
          setActive(data.active);
          const {
            title,
            description,
            downloadFile,
            fileName,
            access,
            displayFields,
            selectedFields,
            submissionMessage,
            requiredLabel,
            submitLabel,
            submissionRedirect
          } = data.stepData;
          if (data.values) setInitialValues(data.values);
          if (data.documentURL) setDocumentURL(data.documentURL);
          if (submissionMessage) setSubmissionMessage(submissionMessage);
          if (data.stepData.displayDocument){
            setDisplayDocument(data.stepData.displayDocument.value==='true' || false);
          }
          setLabels({ requiredLabel, submitLabel });
          setRedirectURL(submissionRedirect);

          setDisplayFields(displayFields);
          setSelectedFields(selectedFields);

          setAccess(access.value || 'public');
          updateFields(data.fields);
          updateWorkflowId(data.workflowId);
          setTitle(title || '');
          setDescription(description || '');

          // Using the JSON.parse to convert "true"/"false" strings to booleans
          setDownload(JSON.parse(downloadFile));

          setFileName(fileName);
          updateLoading(false);
          setToken(data.token);
        }
      } catch (err) {
        setMode('unavailable');
        updateLoading(false);
      }
    };
    const getIp = async () => setIp(await publicIp());
    fetchFields();
    getIp();
    setUserAgent(navigator.userAgent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (title)
      document.title = `${title} ${brand && brand.name ? `| ${brand.name}` : ''
        }`;
  }, [title]);

  const [downloaded, setDownloaded] = useState(false);
  const [attempts, setAttempts] = useState(1);

  const downloadFile = async () => {
    if (success && download) {
      try {
        const response = await axios.get(
          `${baseURL}/resource/file/${stepId}/${workflowRunId}`,
          { headers: { Authorization: `public ${token}` } }
        );
        //SOURCE: https://www.roytuts.com/download-file-from-server-using-react/
        setDownloaded(true);
      } catch (error) {
        setAttempts(attempts + 1);
      }
    }
  };

  useEffect(() => {
    // Use something like https://github.com/tannerlinsley/swimmer for polling
    let id;
    if (!downloaded && attempts <= 8) {
      id = setTimeout(downloadFile, 1500 * attempts);
    }
    return () => clearInterval(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, attempts]);

  const onSubmit = async (values) => {
    try {
      setSubmitting(true);
      // const captchaToken = await recaptchaRef.current.executeAsync();
      setSubmitError(null);
      // Tracking information
      let fieldsTracking = {};
      let udpatedDate = new Date();
      (Object.keys(values) || []).forEach((name) => {
        let fld = fields.find((fl) => fl.data.name === name);
        if (fld && values[name]) {
          fieldsTracking[name] = {
            type: fld.data.type,
            updated: udpatedDate,
            value: values[name]
          };
        }
      });
      (trackingData || []).forEach(({ field, type, updated, value }) => {
        fieldsTracking[field] = { type, updated, value };
      });

      const _trackingLogData = {
        ip,
        userAgent,
        url: window.location.href,
        startDateTime,
        submitDateTime: new Date(),
        fields: fieldsTracking
      };
      // Submission values
      let submissionData = values;
      delete submissionData['generatedDocument'];
      const { data } = await axios.post(
        workflowRunId
          ? `${baseURL}/trigger/${workflowId}/${workflowRunId}`
          : `${baseURL}/trigger/${workflowId}`,
        { ...submissionData, _trackingLogData },
        { headers: { Authorization: `public ${token}` } }
      );
      updateWorkflowRunId(data.workflowRunId);
      updateResourceURL(data.resourceUrl);
      setSubmitting(false);
      if (redirectURL) {
        window.location.href = redirectURL;
      } else {
        updateSuccess(true);
        setDisplayDocument(false);
      }
    } catch (error) {
      isInactiveAccountError(error);

      if (error?.response?.data?.message) {
        setSubmitError(error.response.data.message);
      } else {
        setSubmitError('Form could not be submitted!');
      }
      setSubmitting(false);
    }
  };

  const renderLoading = () => {
    return (
      <div className="loader-wrapper is-active">
        <div className="loader is-loading"></div>
      </div>
    );
  };

  const renderSuccess = () => {
    const DownloadMessage = () => (
      <div className="content">
        {downloaded ? (
          <a
            className="button is-small is-info"
            download={`${fileName}.pdf`}
            // href={`data:application/pdf;base64,${downloadData}`}
            href={resourceURL}
          >
            Download PDF
          </a>
        ) : (
          'Please wait while your document is ready to download...'
        )}
      </div>
    );

    return (
      <div className="submit-success">
        {submissionMessage ? (
          <InnerHtml classes="content" unsanitizedData={submissionMessage} />
        ) : (
          <div className="content">Thank you for your submission. </div>
        )}

        {download ? <DownloadMessage/> : null}
      </div>
    );
  };

  const [authed, setAuthed] = useState(false);
  const renderForm = () => {
    const form = (
      <Form
        isSubmitting={submitting}
        disableSubmit={!active}
        errors={submitError}
        fields={fields}
        onSubmit={onSubmit}
        initialValues={initialValues}
        displayFields={displayFields}
        selectedFields={selectedFields}
        labels={labels}
        mode="preview"
        stepRunId={stepId}
        setTrackingData={setTrackingData}
        setActiveField={setActiveField}
      />
    );

    const auth = (
      <Auth
        onSuccess={() => {
          setAuthed(true);
        }}
        stepId={stepId}
        token={token}
      />
    );

    if (access === 'public') return form;
    else if (access === 'auth' && !authed) return auth;
    else if (access === 'auth' && authed) return form;
  };

  const renderFormOnlyMode = () => {
    return (
      <div
        className="trigger_document_form"
        style={{
          backgroundColor:
            brand && brand.backgroundColor ? brand.backgroundColor : '#fafafa'
        }}
      >
        <div
          className="form_cover"
          style={{
            backgroundColor:
              brand && brand.accentColor ? brand.accentColor : '#182838'
          }}
        ></div>
        <div className="columns is-mobile is-centered">
          <div
            className={`column ${isTabletOrMobile ? 'is-four-fifths' : 'is-three-fifths'
              }`}
          >
            <div
              className="form-wrapper"
              style={{
                backgroundColor:
                  brand && brand.backgroundColor
                    ? brand.backgroundColor
                    : '#fafafa'
              }}
            >
              <div className="form-header">
                <div className="title_description">
                  {brand && brand.logo && (
                    <img src={brand.logo.base64} className="form_logo" />
                  )}
                  <h1 className="is-size-3">{title}</h1>
                </div>
                {!success && (
                  <div className="html_description">
                    <InnerHtml classes="content" unsanitizedData={description} />
                  </div>
                )}
              </div>
              {!success && displayDocument && (
                <div className="view_document">
                  <a
                    className="view_document"
                    onClick={() => setPreviewDocument(!previewDocument)}
                  >
                    <i className="far fa-plus-square" /> View Document
                  </a>
                  {previewDocument && (
                    <>
                      <div className="view_document_container">
                        <Preview
                          pdfURL={documentURL}
                          fields={fields}
                          displayFields={displayFields}
                          selectedFields={selectedFields}
                          workflowRunId={workflowRunId}
                          token={token}
                          activeField={activeField}
                        />
                      </div>
                    </>
                  )}
                  <hr />
                </div>
              )}
              {success
                ? renderSuccess()
                : isLoading
                  ? renderLoading()
                  : renderForm()}
            </div>
          </div>
        </div>
        <div className="docdown_footer_credits">
          <p className=" is-centered has-text-centered">
            Powered by{' '}
            <a href="https://docdown.io" target="_blank">
              Docdown
            </a>
          </p>
        </div>
      </div>
    );
  };
  const renderSplitMode = () => {
    return (
      <div
        className="trigger_split_form columns is-mobile is-centered"
        style={{
          backgroundColor:
            brand && brand.backgroundColor ? brand.backgroundColor : '#fafafa',
          borderTop: `30px solid ${brand && brand.accentColor ? brand.accentColor : '#182838'
            }`
        }}
      >
        <div
          className="column is-half display_form"
          style={{ borderRadius: '0px' }}
        >
          <div className="form-wrapper">
            <div className="form_header">
              <div
                className="title_description"
                style={{ borderRadius: '0px' }}
              >
                {brand && brand.logo && (
                  <img src={brand.logo.base64} className="form_logo" />
                )}
                <h1 className="is-size-3">{title}</h1>
              </div>
              {!success && (
                <div className="html_description">
                  <InnerHtml classes="content" unsanitizedData={description} />
                </div>
              )}
            </div>
            {success
              ? renderSuccess()
              : isLoading
                ? renderLoading()
                : renderForm()}
          </div>
          <p className="docdown_footer_credits is-centered has-text-centered">
            Powered by{' '}
            <a href="https://docdown.io" target="_blank">
              Docdown
            </a>
          </p>
        </div>
        <div className="column is-half display_document_container">
          <Preview
            pdfURL={documentURL}
            fields={fields}
            displayFields={displayFields}
            selectedFields={selectedFields}
            workflowRunId={workflowRunId}
            token={token}
            activeField={activeField}
          />
        </div>
      </div>
    );
  };
  const renderUnavailable = () => {
    return (
      <div className="trigger_document_form form_not_found">
        <div
          className="form_cover"
          style={{
            backgroundColor:
              brand && brand.accentColor ? brand.accentColor : '#182838'
          }}
        ></div>
        <div className=" columns is-mobile is-centered">
          <div className="column is-three-fifths">
            <div className="form-wrapper">
              <div className="title_description">
                <h1 className="is-size-3">Not Found</h1>
              </div>
              <div className="content" style={{ padding: '25px 40px' }}>
                The form you requested is either not available or has expired.
              </div>
            </div>
            <div className="docdown_footer_credits">
              <p className=" is-centered has-text-centered">
                Powered by{' '}
                <a href="https://docdown.io" target="_blank">
                  Docdown
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderMode = () => {
    if (isLoading) {
      renderLoading();
    } else {
      if (mode === 'unavailable' || !active) {
        return renderUnavailable();
      }
  
      if (displayDocument && !isTabletOrMobile) {
        return renderSplitMode();
      } else {
        return renderFormOnlyMode();
      }
    }
  };

  return (
    <div className="trigger_form_wrapper">
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        badge="bottomleft"
        onChange={() => { }}
      />
      {renderMode()}
    </div>
  );
};

export default TriggerDocumentForm;
