import React from 'react';
import Dropdown from './Dropdown';

const FieldTypeDropdown = (props) => {
  return (
    <Dropdown
      label="Field Type"
      input={{
        value: props.type,
        onChange: props.onFieldChange
      }}
      options={[
        { value: 'text', label: 'Text' },
        { value: 'number', label: 'Number' },
        { value: 'checkbox', label: 'Checkbox' },
        { value: 'dateTime', label: 'Date' },
        { value: 'signature', label: 'Signature' },
        { value: 'image', label: 'Image' }
      ]}
    />
  );
};

export default FieldTypeDropdown;
