import React from 'react';

const TextField = (props) => {

  const { input, label, type, step, placeholder, meta: { touched, error, warning } } = props
  let { value, onChange } = input;

  return (
    <div className='field' style={props.hidden ? { display: 'none' } : {}}>
      <label className='label '>{label} {props.required && <span className='has-text-grey'>(required)</span>}</label>
      <div className='control'>
        <input
          className={`input  ${touched && error ? 'is-danger' : ''}`}
          value={props.calculatedValue ? props.calculatedValue : value}
          type={type}
          step={step}
          disabled={props.static ? true : false}
          placeholder={placeholder}
          onChange={e => { type === 'number' ? onChange(parseFloat(e.target.value)) : onChange(e.target.value) }} />
      </div>
      {props.description && <p className="help">{props.description}</p>}
      <p className="help is-danger">
        {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
        {props.warning ? props.warnings.map((warning, i) => (<span>{warning}</span>)) : null}
      </p>
    </div>
  );
}

export default TextField;