export const dropdownDirection = (i, totalRows, rowsPerPage) => {
  const row = totalRows <= rowsPerPage ? i : i % rowsPerPage;
  if (
    row === rowsPerPage - 1 ||
    row === rowsPerPage - 2 ||
    (row === (totalRows % rowsPerPage) - 1 && row !== 0) ||
    (row === (totalRows % rowsPerPage) - 2 && row !== 0)
  ) {
    return 'up';
  } else {
    return 'down';
  }
};
