import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Field, reduxForm, isValid } from 'redux-form';

import EditableLabel from 'react-inline-editing';

import { FieldSelect, ReSelect } from '../components/Select';

import { updateStep, saveStepToServer } from 'redux/actions/workflowActions';
import { fetchDriveApps } from 'redux/actions/appActions';
import { getDriveApps, isActionPending } from 'redux/selectors';

import useExposedFields from './useExposedFields';

import useDimensions from 'hooks/useDimensions';

import { required, url, combine } from 'redux-form-validators';

const SlackSettingsComponent = (props) => {
  const step = props.step;
  const dispatch = useDispatch();
  const valid = useSelector((state) => isValid(props.form)(state));
  const prevData = useExposedFields(step.order);

  const pendingUpdate = useSelector((state) =>
    isActionPending(state, 'UPDATE_STEP')
  );

  const [ref, { x }] = useDimensions(true);

  useEffect(() => {
    props.initialize(props.initialValues);
    // dispatch(fetchDriveApps())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function submit(values) {
    const { text, webhookURL } = values;

    const newStep = {
      ...step,
      name,
      data: {
        ...step.data,
        valid,
        stepData: {
          text,
          webhookURL
        },
        stepSettings: { text, webhookURL },
        exposedFields: []
      }
    };
    dispatch(updateStep(newStep));
    dispatch(saveStepToServer(newStep));
  }

  const [name, setName] = useState(step.name || props.defaultName);
  const Topbar = ({ name, setName }) => {
    return (
      <div className="step_bar">
        <nav className="level">
          <div className="level-left step-editable-name">
            <div className="level-item ">
              <span className="step_name" title="Click to edit name">
                <Field
                  name="stepName"
                  component={(props) => (
                    <EditableLabel
                      onFocusOut={(text) => {
                        setName(text);
                        props.input.onChange(name);
                      }}
                      inputClassName="input"
                      text={name}
                    />
                  )}
                />
              </span>
            </div>
            <div className="level-item">
              <i className="fa fa-edit" />
            </div>
          </div>
          <div className="level-right">
            <button
              type="submit"
              disabled={pristine}
              className={`button is-small is-info ${
                pendingUpdate && pendingUpdate.pending ? 'is-loading' : ''
              }`}
            >
              Save
            </button>
          </div>
        </nav>
      </div>
    );
  };
  const { handleSubmit, pristine, submitting } = props;
  return (
    <div className="step_setting" ref={ref}>
      {/* <h5 className='title is-5'>Configure Email Notification</h5> */}
      <form onSubmit={handleSubmit(submit)} className="email_setting">
        <Topbar name={name} setName={setName} />
        <div className="fields">
          <div className="field">
            <label className="label">
              Incoming Webhook URL <span className="has-text-grey">(required)</span>
            </label>
            <div className="control">
              <Field
                name="webhookURL"
                className="input"
                component="input"
                placeholder="Webhook URL"
                required={[required(), url({ protocols: ['http', 'https'] })]}
              />
            </div>
            <p className="help">
              Incoming Webhook URL to send messages to.{' '}
              <a
                href="https://slack.com/intl/en-np/help/articles/115005265063-Incoming-Webhooks-for-Slack"
                target="_blank"
              >
                Click here
              </a>{' '}
              to learn how to create webhook URL in Slack.
            </p>
          </div>
          <div className="field">
            <label className="label">
              Message <span className="has-text-grey">(required)</span>
            </label>
            <div className="control">
              <Field
                name="text"
                component={FieldSelect}
                multiple={true}
                width={x}
                options={prevData}
                validate={[
                  required({ msg: 'Please enter message to be sent to Slack.' })
                ]}
                openMenuOnClick={false}
                placeholder="Slack Message"
              />
            </div>
            <p className="help">
              Message to be sent to slack. Click on + button to use data from
              previous steps.
            </p>
          </div>
        </div>
      </form>
    </div>
  );
};

const SlackSettings = reduxForm()(SlackSettingsComponent);
export default SlackSettings;
