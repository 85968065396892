/* eslint-disable jsx-a11y/anchor-is-valid */
import FontPicker from 'font-picker-react';
import { startCase } from 'lodash';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import './GoogleFontPicker.scss';

const GoogleFontPicker = (props) => {
  const { value, onChange } = props.input;
  return (
    <div className="field font_select is-horizontal google_font_select">
      <div className="field-label is-small">
        <label htmlFor={props.label} className="label">
          {props.label}
        </label>
      </div>
      <div className="field-body">
        <p className="control is-expanded select_control">
          <FontPicker
            //DOC: https://www.npmjs.com/package/font-picker-react
            apiKey={process.env.REACT_APP_GOOGLE_FONT_API_KEY}
            activeFontFamily={startCase(value)}
            onChange={(nextFont) => onChange(nextFont.family)}
            families={[
              'Alex Brush',
              'Archivo',
              'Dancing Scripts',
              'Heebo',
              'Lato',
              'Lora',
              'Merriweather',
              'Montserrat',
              'Nunito',
              'Open Sans',
              'Pacifico',
              'Playfair Display',
              'Poppins',
              'PT Sans',
              'PT Serif',
              'Raleway',
              'Roboto',
              'Roboto Condensed',
              'Roboto Mono',
              'Source Code Pro',
              'Source Sans Pro',
              'Ubuntu'
            ]}
            limit={200}
          />

          {props.tooltip && (
            <i className="fas fa-question-circle" data-tip={props.tooltip}></i>
          )}
        </p>
      </div>
      <ReactTooltip
        className="field_tooltip"
        place="left"
        effect="solid"
        offset={{ top: 0 }}
        multiline={true}
      />
    </div>
  );
};

export default GoogleFontPicker;
