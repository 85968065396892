import { debounce, merge } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  deleteField,
  setActiveField,
  updateField,
  updateFieldsToServer
} from 'redux/actions';
import { getFields } from 'redux/selectors';
import { fieldDefaults } from 'utils/fieldDefaults';
import './FieldEditorPanel.scss';
import CheckboxFieldForm from './Fields/CheckboxFieldForm';
import FieldTabButton from './Fields/components/FieldTabButton';
import DateTimeFieldForm from './Fields/DateTimeFieldForm';
import GroupFieldForm from './Fields/GroupFieldForm';
import ImageFieldForm from './Fields/ImageFieldForm';
import NumberFieldForm from './Fields/NumberFieldForm';
import SignatureFieldForm from './Fields/SignatureFieldForm';
import TextFieldForm from './Fields/TextFieldForm';

class FieldEditorPanel extends Component {
  // Storing the ephemeral state for General/Advanced
  // view.
  state = {
    currentView: 'data',
    values: fieldDefaults(this.props.field.data.type, this.props.field)
  };

  onDelete = (e) => {
    e.preventDefault();
    this.props.deleteField(this.props.field);
  };

  onChange = (values) => {
    let field = this.props.field;
    let updatedField = merge(field, {
      data: {
        ...field.data,
        ...values
      }
    });
    updatedField = merge(updatedField, {
      geometry: {
        ...field.geometry,
        x: parseFloat(values.x),
        y: parseFloat(values.y),
        width: parseFloat(values.width),
        height: parseFloat(values.height)
      }
    });
    // Assigning manually here as it didn't work above.
    // It seems like merge doesn't merge deeply, only shallow.
    updatedField.data.conditionalFormula = values.conditionalFormula;
    updatedField.data.fieldOptions = values.fieldOptions;
    this.props.updateField(updatedField, this.props.documentId);
    this.setState({ ...this.state, values });
  };

  defaultIfEmpty(data, defaultVal) {
    return data === undefined || data === null ? defaultVal : data;
  }

  onFieldChange = (value) => {
    let field = this.props.field;
    field.data.type = value;
    this.props.updateField(field, this.props.documentId);
  };

  renderForm(Component) {
    const { index, field, fields } = this.props;
    const { type } = this.props.field.data;
    return (
      <React.Fragment>
        <FieldTabButton
          value={this.state.currentView}
          onChange={(value) => this.setState({ currentView: value })}
        />
        <div className="field_editor">
          <Component
            className="field_form"
            field={field}
            fields={fields}
            view={this.state.currentView}
            onChange={debounce(this.onChange, 250)}
            onFieldChange={this.onFieldChange}
            initialValues={fieldDefaults(type, field)}
            onDelete={(e) => {
              e.preventDefault();
              this.props.deleteField(field);
              this.props.updateFieldsToServer(this.props.documentId);
            }}
            key={`${type}_field_${index}`}
            form={`${type}_field_${index}`}
          />
        </div>
      </React.Fragment>
    );
  }
  render() {
    let { type } = this.props.field.data;
    switch (type) {
      case 'text':
        return this.renderForm(TextFieldForm);
      case 'number':
        return this.renderForm(NumberFieldForm);
      case 'signature':
        return this.renderForm(SignatureFieldForm);
      case 'checkbox':
        return this.renderForm(CheckboxFieldForm);
      case 'dateTime':
        return this.renderForm(DateTimeFieldForm);
      case 'image':
        return this.renderForm(ImageFieldForm);
      case 'group':
        return this.renderForm(GroupFieldForm);
      default:
        return null;
    }
  }
}

const mapStateToProps = (state) => ({
  fields: getFields(state)
});

export default connect(mapStateToProps, {
  deleteField,
  setActiveField,
  updateField,
  updateFieldsToServer
})(FieldEditorPanel);
