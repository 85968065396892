import server from 'api/server';
import EllipsisDropdown from 'components/_common/EllipsisDropdown';
import { capitalize } from 'lodash';
import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import Moment from 'react-moment';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchUser } from 'redux/actions';
import { getUser } from 'redux/selectors';
import '../Settings.scss';
import { EditModal, InviteModal } from './modals';
import { SVInviteModal } from './svModals';
import { UserRoles } from 'constants/UserRoles';

const TeamMembers = ({ inviteModal, showInviteModal }) => {
  const [loading, setLoading] = useState(false);
  const [editModal, showEditModal] = useState(false);
  const [userEditing, setUserEditing] = useState(null);
  const [users, setUsers] = useState(null);
  const user = useSelector(getUser);

  const dispatch = useDispatch();

  const getUsers = async () => {
    try {
      setLoading(true);
      const { data } = await server.get('/company/users');
      setUsers(data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  useEffect(() => {
    dispatch(fetchUser());
    getUsers();
  }, []);

  const renderLoading = () => {
    return (
      <div className="billing_loader_container">
        <Loader type="Oval" color="#ccc" height={30} width={30} />
      </div>
    );
  };
  const getTag = (state) => {
    switch (state) {
      case 'Active':
        return <span className="tag is-success is-light">{state}</span>;
      case 'Inactive':
        return <span className="tag is-white">{state}</span>;
      case 'Invited':
        return <span className="tag is-warning is-light">Invited</span>;
      default:
        return <span className="tag is-light">{state}</span>;
    }
  };
  const renderUserTable = () => {
    return (
      users && (
        <table className="members_table table is-hoverable is-fullwidth is-striped">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Access</th>
              <th>Status</th>
              <th>Updated</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => {
              return (
                <tr>
                  <td>
                    {/\S/.test(user.name)
                      ? user.name
                      : capitalize(user.email.split('@')[0])}
                  </td>
                  <td>{user.email}</td>

                  <td>{getTag(capitalize(user.role))}</td>
                  <td>{getTag(capitalize(user.status))}</td>
                  <td>
                    <Moment format="lll">{user.updatedAt}</Moment>
                  </td>
                  <td>
                    <EllipsisDropdown
                      width={100}
                      items={[
                        {
                          title: 'Edit',
                          icon: 'fas fa-user-edit',
                          classes: '',
                          show: true,
                          onClick: () => {
                            setUserEditing(user);
                            showEditModal(true);
                          }
                        },
                        {
                          title: 'Deactivate',
                          icon: 'fas fa-ban',
                          classes: '',
                          show:
                            user.status === 'active' && user.role !== UserRoles.ADMIN,
                          onClick: async () => {
                            try {
                              const { data } = await server.post(
                                `/company/users/${user.id}`,
                                { status: 'inactive' }
                              );

                              toast.info('User succesfully deactivated!');
                              await getUsers();
                            } catch (err) {
                              console.log(err);
                              toast.info('Could not activate the user!');
                            }
                          }
                        },
                        {
                          title: 'Activate',
                          icon: 'far fa-check-circle',
                          classes: '',
                          show: user.status === 'inactive',
                          onClick: async () => {
                            try {
                              const { data } = await server.post(
                                `/company/users/${user.id}`,
                                { status: 'active' }
                              );

                              toast.info('User succesfully activated!');
                              await getUsers();
                            } catch (err) {
                              console.log(err);
                              toast.info('Could not activate the user!');
                            }
                          }
                        },
                        {
                          title: 'Delete',
                          icon: 'far fa-trash-alt',
                          classes: 'delete-item',
                          show: user.role !== UserRoles.ADMIN,
                          onClick: async () => {
                            try {
                              if (
                                window.confirm(
                                  `Are you sure you want to delete this team member?`
                                )
                              ) {
                                await server.delete(
                                  `/company/users/${user.id}`
                                );
                                toast.info('User succesfully deleted!');
                                await getUsers();
                              }
                            } catch (err) {
                              console.log(err);
                              toast.info('Could not delete the user!');
                            }
                          }
                        }
                      ]}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )
    );
  };

  const renderContent = () => {
    return (
      <div>
        <EditModal
          open={editModal}
          onClose={() => showEditModal(false)}
          user={userEditing}
          onSucccess={getUsers}
        />
        {user.svCustomer ? (
          <SVInviteModal
            open={inviteModal}
            onClose={() => showInviteModal(false)}
            inviter={user.email}
            onSucccess={getUsers}
          />
        ) : (
          <InviteModal
            open={inviteModal}
            onClose={() => showInviteModal(false)}
            userQuota={user.usage.usageData.quota.members}
            inviter={user.name}
            onSucccess={getUsers}
          />
        )}

        <div className="members_table">{renderUserTable()}</div>
      </div>
    );
  };
  return (
    <div className="members">
      {user && user.company && !loading ? renderContent() : renderLoading()}
    </div>
  );
};

export default TeamMembers;
