import { snakeCase } from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getFields } from 'redux/selectors';
import styled, { keyframes } from 'styled-components';
import TextEditor from './TextEditor';

const fadeInScale = keyframes`
  from {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const Container = styled.div`
  background: white;
  border-radius: 2px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  margin-top: 16px;
  transform-origin: top left;
  z-index: 9999999999;
  animation: ${fadeInScale} 0.31s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  overflow: hidden;
`;

function Editor(props) {
  const fields = useSelector(getFields);
  const illegalNames = [
    ...fields.map((f) => f.data.label),
    'generatedDocument'
  ];

  const [illegal, setIllegal] = useState(false);
  const [overMax, setOverMax] = useState(false);

  const { geometry } = props.annotation;
  if (!geometry) return null;

  return (
    <Container
      className={props.className}
      style={{
        position: 'absolute',
        left: `${
          geometry.x > 70 ? geometry.x - 35 + geometry.width : geometry.x
        }%`,
        top: `${
          geometry.y > 90
            ? geometry.y - geometry.height - 4
            : geometry.y + geometry.height
        }%`,
        ...props.style
      }}
    >
      <TextEditor
        onChange={(e) => {
          if (illegalNames.includes(e.target.value)) {
            setIllegal(true);
          } else {
            setIllegal(false);
          }

          // Create field name from label, max length 24.
          let fieldName = snakeCase(e.target.value);
          fieldName = fieldName.substring(0, Math.min(fieldName.length, 24));

          // Raise error if field label is longer than 65535 characters.
          if (e.target.value.length > 65535) {
            setOverMax(true);
          } else {
            setOverMax(false);
          }
          props.onChange({
            ...props.annotation,
            data: {
              ...props.annotation.data,
              label: e.target.value,
              name: fieldName
            }
          });
        }}
        errors={[illegal, overMax]}
        valid={!overMax}
        onSubmit={props.onSubmit}
        value={props.annotation.data && props.annotation.data.label}
      />
    </Container>
  );
}

Editor.defaultProps = {
  className: '',
  style: {}
};

export default Editor;
