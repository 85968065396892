import React, { useState } from 'react';
import FolderPickerModal from './FolderPickerModal';
import './style.scss';

const SVFolderPicker = ({
  input: { value, onChange },
  label,
  type,
  step,
  placeholder,
  required,
  warnings,
  description,
  hidden,
  calculatedValue,
  meta: { touched, error, warning }
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <FolderPickerModal
        open={modalOpen}
        onSelect={(path) => onChange(path)}
        onClose={() => {
          setModalOpen(false);
        }}
      />
      <div className="field has-addons">
        <label className="label ">
          {label}{' '}
          {required && <span className="has-text-grey">(required)</span>}
        </label>
        <div className="control is-expanded">
          <input
            className={`input`}
            value={calculatedValue ? calculatedValue : value}
            type={type}
            step={step}
            disabled={true}
            placeholder={placeholder}
            onChange={(e) => {
              type === 'number'
                ? onChange(parseFloat(e.target.value))
                : onChange(e.target.value);
            }}
          />
        </div>
        <div className="control">
          <button
            className="button is-small is-info folder_select_button"
            onClick={() => {
              setModalOpen(true);
            }}
          >
            Select Folder <i className="fas fa-folder-open" />
          </button>
        </div>
        {description && <p className="help">{description}</p>}
      </div>
      <p className="help is-danger">
        {touched &&
          ((error && <span>{error}</span>) ||
            (warning && <span>{warning}</span>))}
        {warning ? warnings.map((warning, i) => <span>{warning}</span>) : null}
      </p>
    </>
  );
};

export default SVFolderPicker;
