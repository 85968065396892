import useOnClickOutside from 'hooks/useOnClickOutside';
import queryString from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { logout } from 'redux/actions';
import { getUser } from 'redux/selectors';
import './Page.scss';
import { UserRoles } from 'constants/UserRoles';

const SidebarComp = (props) => {
  const user = useSelector(getUser);
  const [menuOpen, setMenuOpen] = useState(false);
  const dispatch = useDispatch();
  const ref = useRef();

  useOnClickOutside(ref, () => {
    setMenuOpen(false);
  });
  const { pathname } = useLocation();

  const mainRoutes = () => {
    return (
      <React.Fragment>
        <Link id="sidebar-dashboard-link" to="/">
          <div
            className={`menu-item ${pathname === '/' ? 'active_page' : ''
              }`}
          >
            <span>
              <i className="fas fa-home"></i>Dashboard
            </span>
          </div>
        </Link>
        <Link id="sidebar-documents-link" to="/documents">
          <div
            className={`menu-item ${pathname.includes('/documents') ? 'active_page' : ''
              }`}
          >
            <span>
              <i className="fas fa-file"></i>Documents
            </span>
          </div>
        </Link>
        <Link id="sidebar-workflows-link" to="/workflows">
          <div
            className={`menu-item ${pathname.includes('/workflows') ? 'active_page' : ''
              }`}
          >
            <span>
              <i className="fas fa-stream"></i>Workflows
            </span>
          </div>
        </Link>
        {user.role === UserRoles.ADMIN && (
          <Link id="sidebar-activity-log-link" to="/activity_log">
            <div
              className={`menu-item ${pathname.includes('/activity_log') ? 'active_page' : ''
                }`}
            >
              <span>
                <i className="fas fa-history"></i>Activity Log
              </span>
            </div>
          </Link>
        )}
      </React.Fragment>
    );
  };
  const settingsRoutes = () => {
    return (
      <React.Fragment>                      
        <Link id="settings-routes-settings-link" to="/settings/account">
          <div
            className={`menu-item ${pathname.includes('/settings') ? 'active_page' : ''
              }`}
          >
            <span>
              <i className="fas fa-cog"></i>Settings
            </span>
          </div>
        </Link>
      </React.Fragment>
    );
  };
  return (
    <div className="sidebar_component">
      <div className="sidebar_links">
        <div className="sidebar_logo_container">
          <img src="/assets/docdown.png" className="sidebar_logo" />
          <span className="release_badge">
            {typeof Headway != 'undefined' && (
              <i
                style={{ cursor: 'pointer' }}
                className="fas fa-bell release_trigger"
              ></i>
            )}
          </span>
        </div>
        {mainRoutes()}
        <div className="breaker" />
        {settingsRoutes()}
        <div className="breaker" />
      </div>
      <div className="sidebar_footer">
        <div className={`dropdown is-up ${menuOpen && 'is-active'}`} ref={ref}>
          <div className="dropdown-trigger">
            <a id="sidebar-my-account"  onClick={(e) => setMenuOpen(!menuOpen)}>
              <a onClick={(e) => setMenuOpen(!menuOpen)}>
                <span className="sidebar_footer_text">My Account </span>
                <span className="icon is-small angle_icon">
                  <i className="fas fa-angle-up" aria-hidden="true"></i>
                </span>
                <span className="icon is-small user_icon">
                  <i className="fas fa-user-circle" aria-hidden="true"></i>
                </span>
              </a>
            </a>
            <div className="dropdown-menu" id="dropdown-menu2" role="menu">
              <div className="dropdown-content">
                <div className="dropdown-item">
                  <p>
                    <strong>{user.name}</strong>
                  </p>
                  <p>{user.email}</p>
                </div>
                <Link
                  id="sidebar-my-account-settings-link"
                  to="/settings/account"
                  className="dropdown-item"
                  onClick={(e) => {
                    setMenuOpen(false);
                  }}
                >
                  <span className="icon">
                    <i className="fas fa-cog" aria-hidden="true"></i>
                  </span>
                  <span>Settings</span>
                </Link>
                <Link
                  id="sidebar-my-account-logout-link"
                  to="/logout"
                  className="dropdown-item"
                  onClick={(e) => {
                    dispatch(logout());
                    setMenuOpen(false);
                  }}
                >
                  <span className="icon">
                    <i className="fas fa-sign-out-alt" aria-hidden="true"></i>
                  </span>
                  <span>Logout</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Navbar = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const ref = useRef();

  useOnClickOutside(ref, () => {
    setMenuOpen(false);
  });

  return (
    <>
      <nav
        className={`navbar page_nav ${props.filters ? 'bottom_border' : 'bottom_shadow'
          }`}
      >
        <div className="navbar-menu">
          <div className="navbar-start">
            <div className="navbar-item">
              <h5 className="title is-5">{props.title}</h5>
            </div>
            <div className="navbar-item navbar-search">{props.search}</div>
          </div>
          <div className="navbar-end">
            <div className="navbar-item">
              {props.action}
            </div>
          </div>
        </div>
      </nav>
      {props.filters ? (
        <nav
          className={`navbar filter_nav ${props.filters ? 'bottom_shadow' : 'bottom_border'
            }`}
        >
          <div className="navbar-start">
            {props.filters.map((Comp) => (
              <div className="navbar-item">{Comp}</div>
            ))}
          </div>
        </nav>
      ) : (
        <nav className="navbar filter_nav bottom_shadow top_border"></nav>
      )}
    </>
  );
};

const Page = (props) => {
  useEffect(() => {
    document.title = props.title ? `${props.title} | Docdown` : 'Docdown';
  }, []);

  const { search } = useLocation();
  let history = useHistory();

  useEffect(() => {
    let queries = queryString.parse(search, { ignoreQueryPrefix: true });

    if (Object.keys(queries).includes('redirect')) {
      history.push(queries['redirect']);
    }
  }, [search, history]);

  return (
    <div className="page_container columns is-gapless is-marginless is-paddingless is-mobile">
      <div className="nav-bar-column sidebar-column">
        <SidebarComp />
      </div>
      <div className="page_content is-10">
        <Navbar
          title={props.title}
          action={props.action}
          filters={props.filters}
          search={props.search}
        />
        <div className="page_scrollable">{props.children}</div>
      </div>
    </div>
  );
};
export default Page;
