import React, { Component, forwardRef } from 'react';
import { connect } from 'react-redux';
import { FixedSizeList as List } from "react-window"

import Thumbnail from './Thumbnail';
import { setCurrentPage } from 'redux/actions';
import { getNumPages, getCurrentPage } from 'redux/selectors';


import {
  thumbnailHeight, thumbnailPadding
} from 'constants/js/documentEditor'
import './ThumbnailsPanel.scss';

const innerElementType = forwardRef(({ style, ...rest }, ref) => (
  <div
    ref={ref}
    style={{
      ...style,
      height: `${parseFloat(style.height) + thumbnailPadding * 2}px`
    }}
    {...rest}
  />
));

class ThumbnailsPanel extends Component {

  constructor(props) {
    super(props);
    this.container = React.createRef();
  }
  render() {

    return (<div className='thumbnails_container is-centered' ref={this.container}>
      <List
        height={1000}
        innerElementType={innerElementType}
        itemCount={this.props.numPages}
        itemSize={thumbnailHeight}
      // width={thumbnailContainerWidth}s
      >
        {Thumbnail}
      </List>
    </div >);
  }
}

const mapStateToProps = state => ({
  currentPage: getCurrentPage(state),
  numPages: getNumPages(state)
});

export default connect(mapStateToProps, {
  setCurrentPage
})(ThumbnailsPanel);