import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Modal from 'react-modal';

import store from './redux/store';
import App from './components/App';


Modal.setAppElement('#root');

ReactDOM.render(
  <StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
  </StrictMode>,
  document.querySelector('#root')
);
