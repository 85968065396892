import Page from 'components/Page';
import { getIcon } from 'components/WorkflowEditor/steps/availableSteps';
import { GlobalSearch } from 'components/_common/TableElements';
import React, { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import Loader from 'react-loader-spinner';
import Moment from 'react-moment';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createWorkflow, fetchWorkflows } from 'redux/actions/workflowActions';
import { getWorkflows, isActionPending } from 'redux/selectors';
import './Workflow.scss';
import DeleteWorkflowModal from './WorkflowBox/DeleteWorkflowModal';
import DropdownButton from './WorkflowBox/DropdownButton';
import WorkflowToggle from './WorkflowBox/WorkflowToggle';
import CreateWorkflowModal from './components/CreateWorkflowModal';

const Workflows = ({}) => {
  const [searchText, setSearchText] = useState('');
  const [selectedWorkflows, setSelectedWorkflows] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const dispatch = useDispatch();
  const workflows = useSelector(getWorkflows);
  const pending = useSelector((state) =>
    isActionPending(state, 'GET_WORKFLOWS')
  );

  let showLoading =
    (!workflows || !workflows.length) && pending && pending.pending;

  useEffect(() => {
    dispatch(fetchWorkflows());
  }, []);

  const columns = useMemo(
    () => [
      {
        name: 'Workflow',
        selector: 'name',
        sortable: true,
        width: '30rem',
        cell: (row) => (
          <Link
            to={`/workflows/editor/${row.id}`}
            className="level-left icons step_row"
            title={row.name}
          >
            <div className="level-item">
              {row.steps &&
                row.steps
                  .sort((a, b) => a.order - b.order)
                  .map((s, i) => {
                    return i + 1 === row.steps.length ? (
                      getIcon(s.type)
                    ) : (
                      <span>
                        {getIcon(s.type)} <i className="fa fa-caret-right"></i>
                      </span>
                    );
                  })}
            </div>
            <span className="step_row_name">{row.name || 'Draft'}</span>
          </Link>
        )
      },
      {
        name: 'Owner',
        selector: 'owner', // Specify the selector to enable sorting.
        sortable: true,
        sortFunction: (a, b) => a.owner.localeCompare(b.owner),
        cell: (row) => <span>{row.owner}</span>
      },
      {
        name: 'Created',
        selector: 'createdAt',
        sortable: true,
        cell: (row) => <Moment format="ll">{row.createdAt}</Moment>
      },
      {
        name: 'Modified',
        selector: 'updatedAt',
        sortable: true,
        cell: (row) => <Moment format="ll">{row.updatedAt}</Moment>
      },
      {
        name: 'Active',
        selector: 'active',
        sortable: true,
        sortType: 'basic',
        cell: (row) => <WorkflowToggle data={row} />
      },
      {
        name: 'Form Link',
        cell: (row) => {
          if (row.steps && row.steps[0]) {
            const isForm = row.steps[0].type == 'document';
            const link = `${process.env.REACT_APP_BASE_URL}/trigger/form/${row.steps[0].id}`;
            return (
              <div className="level link_buttons_table">
                <div className="level-left">
                  <div className="level-item">
                    {isForm && (
                      <a
                        className="button copy_button"
                        onClick={() => {
                          navigator.clipboard.writeText(link);
                          toast.info('Link copied to clipboard');
                        }}
                      >
                        <i className="far fa-copy"></i>
                      </a>
                    )}
                  </div>
                </div>

                {isForm && (
                  <div className="level-item">
                    <a className="button link_button" href={link} target="_blank">
                      <i className="fas fa-external-link-alt"></i>
                    </a>{' '}
                  </div>
                )}
              </div>
            );
          }
        }
      },
      {
        cell: (row) => <DropdownButton data={row} />
      }
    ],
    [workflows]
  );

  const data = useMemo(() => {
    if (!workflows) return [];
    let workflowObjects = workflows
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      .map((f) => {
        if (!f.user) return f;
        const owner =
          f.user.status === 'active'
            ? f.user.name
            : `${f.user.name} (inactive)`;
        return { ...f, owner };
      });
    if (searchText)
      return workflowObjects.filter((item) =>
        item.name.toLowerCase().includes(searchText.toLowerCase())
      );
    else return workflowObjects;
  }, [workflows, searchText]);

  const handleWorkflowSelected = ({
    allSelected,
    selectedCount,
    selectedRows
  }) => {
    const selected = selectedRows.map(({ id, active }) => ({ id, active }));

    setSelectedWorkflows(selected);
  };

  const renderLoading = () => {
    return (
      <div className="fullwidth_loader_container">
        <Loader type="Oval" color="#ccc" height={30} width={30} />
      </div>
    );
  };
  const EmptyWorkflows = () => {
    return (
      <div className="empty">
        <div className="content">
          <p>
            <i className="fas fa-stream"></i>
          </p>
          <p>You don't have any workflows.</p>
          <p>
            <button
              onClick={(e) => {
                dispatch(createWorkflow());
                dispatch(fetchWorkflows());
              }}
              className="button is-small"
            >
              <span className="icon">
                <i className="fas fa-plus"></i>
              </span>
              <span>Create your first workflow</span>
            </button>
          </p>
        </div>
      </div>
    );
  };
  const renderTable = () => {
    return (
      <div className="box workflow_table">
        <DataTable
          title=""
          columns={columns}
          data={data}
          progressComponent={renderLoading}
          selectableRows
          pagination
          onSelectedRowsChange={handleWorkflowSelected}
          noDataComponent={searchText ? null : <EmptyWorkflows />}
        />
      </div>
    );
  };

  const createNewWorkflow = (name) => {
    dispatch(createWorkflow(name));
    toast.info('New workflow created successfully.');
    dispatch(fetchWorkflows());
  };

  return (
    <Page
      title="Workflows"
      action={
        <button
          onClick={() => setCreateModalOpen(true)}
          className="button upload is-small is-info"
        >
          <span className="icon">
            <i className="fas fa-plus"></i>
          </span>
          <span>New Workflow</span>
        </button>
      }
      filters={[
        <GlobalSearch
          globalFilter={searchText}
          setGlobalFilter={setSearchText}
        />,
        <div>
          <button
            onClick={() => {
              setDeleteModalOpen(true);
            }}
            className="button delete-button is-small"
            disabled={selectedWorkflows.length === 0}
          >
            <span className="icon">
              <i className="far fa-trash-alt"></i>
            </span>
            <span>Delete</span>
          </button>
        </div>
      ]}
    >
      <CreateWorkflowModal
        open={createModalOpen}
        onSubmission={(name) => createNewWorkflow(name)}
        onClose={() => setCreateModalOpen(false)}
        workflows={workflows}
      />
      <div className="page workflows">
        <div className="container">
          <DeleteWorkflowModal
            open={deleteModalOpen}
            onClose={() => {
              setDeleteModalOpen(false);
              dispatch(fetchWorkflows());
              setSelectedWorkflows([]);
            }}
            workflows={selectedWorkflows}
          />
          {showLoading ? renderLoading() : renderTable()}
        </div>
      </div>
    </Page>
  );
};

export default Workflows;
