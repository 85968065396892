import { useFormik } from 'formik';
import { snakeCase } from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-responsive-modal';
import { addField } from 'redux/actions';
import { getFields } from 'redux/selectors';
import uniqid from 'uniqid';
import * as Yup from 'yup';

const CreateGroupModal = ({ open, onClose, id = "create-group-modal" }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const fields = useSelector((state) => getFields(state));
  const formik = useFormik({
    initialValues: {
      name: ''
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .max(256, 'Must be 256 characters or less')
        .required('Required')
    }),
    onSubmit: (values) => {
      const field = {
        geometry: { page: 1 },
        data: {
          type: 'group',
          name: snakeCase(values.name),
          label: values.name,
          description: '',
          id: uniqid(),
          fields: []
        }
      };
      dispatch(addField(field));
      onClose();
    }
  });
  return (
    <Modal id={id} open={open} onClose={onClose} closeOnOverlayClick={false}>
      <div class="modal_top">
        <h3 class="title is-h3">Add a Field Group</h3>
        <form onSubmit={formik.handleSubmit}>
          <div className="field">
            <div className="control">
              <input
                id={`${id}-group-name-input`}
                className="input is-small"
                type="text"
                name="name"
                placeholder="Field group name..."
                value={formik.values.name}
                onChange={formik.handleChange}
              />
            </div>
            {formik.errors.name && (
              <p className="help is-danger">{formik.errors.name}</p>
            )}
          </div>
          <hr />
          <div className="field is-grouped">
            <div className="control">
              <button
                id={`${id}-add-group-button`}
                type="submit"
                className={`button is-info is-small ${loading ? 'is-loading' : ''}`}
              >
                Add Group
              </button>
            </div>
            <div className="control">
              <button
                id={`${id}-cancel-button`}
                type="button"
                onClick={onClose}
                className="button is-info is-small is-light"
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export const GroupField = (props) => {
  const [modalOpen, setModalOpen] = useState(false);

  const id = props.id ?? "group-fields"

  return (
    <>
      <CreateGroupModal
        id={`${id}-group-create-modal`}
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
      />

      <p className="control">
        <button
          id={`${id}-group-button`}
          type="button"
          className={`button is-small`}
          onClick={(e) => {
            setModalOpen(true);
          }}
        >
          <span className="icon is-small">
            <i className="fa fa-link"></i>
          </span>
          <span>Group</span>
        </button>
      </p>
    </>
  );
};
