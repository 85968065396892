import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import './SignatureField.scss';

const maxSize = 5242880;

const translate = (text, lang) => {
  const map = {
    'file-drop': {
      en: 'Click here or drop your signature image here',
      es: 'Haga clic aquí o suelte su imagen de firma aquí',
      fr: 'Cliquez ici ou déposez votre image de signature ici',
      de: 'Klicken Sie hier oder hinterlassen Sie Ihr Signaturbild hier',
      pt: 'Clique aqui ou solte sua imagem de assinatura aqui'
    },
    'file-error-type': {
      en: 'File type not accepted, please use a PNG or JPG file!',
      es: 'Tipo de archivo no aceptado, utilice un archivo PNG o JPG.',
      fr:
        'Type de fichier non accepté, veuillez utiliser un fichier PNG ou JPG !',
      de:
        'Dateityp wird nicht akzeptiert, bitte verwenden Sie eine PNG- oder JPG-Datei!',
      pt: 'Tipo de arquivo não aceito, use um arquivo PNG ou JPG!'
    },
    'file-error-size': {
      en: 'File is too large, please use a file smaller than 5MB!',
      es:
        'El archivo es demasiado grande, utilice un archivo de menos de 5 MB.',
      fr:
        'Le fichier est trop volumineux, veuillez utiliser un fichier de moins de 5 Mo !',
      de: 'Datei ist zu groß, bitte verwenden Sie eine Datei kleiner als 5 MB!',
      pt: 'O arquivo é muito grande, use um arquivo menor que 5 MB!'
    }
  };
  if (lang) return map[text][lang];
  else return map[text]['en'];
};

const UplaodField = (props) => {
  const { value, onUpload, onClear } = props;

  const [image, setImage] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [key, setKey] = useState(0);

  if (value && !image) {
    setImage({ base64: value, type: 'image/png' });
    onUpload(image);
  }

  useEffect(() => {
    onUpload(image);
  }, [image]);

  const onDrop = async (files) => {
    const { name, type } = files[0];
    // Base64
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      setImage({ base64: reader.result, type });
      onUpload(image);
    };
    reader.onerror = function (error) {
      console.log(error);
    };
  };

  const {
    isDragActive,
    getRootProps,
    getInputProps,
    isDragReject,
    acceptedFiles,
    rejectedFiles,
    inputRef
  } = useDropzone({
    onDrop,
    minSize: 0,
    maxSize,
    canRemove: true,
    accept: 'image/png, image/jpeg'
  });

  const isFileTooLarge =
    rejectedFiles &&
    rejectedFiles.length > 0 &&
    rejectedFiles[0].size > maxSize;

  return (
    <div className="field" style={props.hidden ? { display: 'none' } : {}}>
      <label className="label ">
        {props.label}{' '}
        {props.required && (
          <span className="has-text-grey">
            ({props.requiredLabel || 'required'})
          </span>
        )}
      </label>
      <div className="control image-uploader-signature">
        {!image ? (
          <div className="image-uploader-drag">
            <div
              key={key}
              {...getRootProps()}
              className={`uploader ${
                isDragActive && !isDragReject ? 'active' : 'not-active'
              }`}
            >
              <input {...getInputProps()} />
              {!isDragActive && <p>{translate('file-drop', props.language)}</p>}
              {isDragReject && (
                <p className="help is-danger">
                  {translate('file-error-type', props.language)}
                </p>
              )}
              {isFileTooLarge && (
                <p className="help is-danger">
                  {translate('file-error-size', props.language)}
                </p>
              )}
            </div>
          </div>
        ) : (
          <div className="image-uploader-file">
            {uploading ? (
              <p>Uploading...</p>
            ) : (
              <div>
                <img src={image.base64} className="signature-preview" />
                <button
                  type="button"
                  className="signature_upload_clear button  is-fullwidth"
                  onClick={() => {
                    setImage(null);
                    onClear(null);
                  }}
                >
                  <i className="far fa-trash-alt" />
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default UplaodField;
