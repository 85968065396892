/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { GithubPicker } from 'react-color';

class ColorPicker extends Component {
  state = { active: false, color: '#000000' }
  onClick = (e) => {
    this.setState({ active: !this.state.active })
  }
  componentDidMount() {
    this.setState({ color: this.props.input.value })
  }
  render() {
    let { onChange } = this.props.input;
    return (
      <div className='field is-horizontal'>
        <div className="field-label is-small">
          <label className="label">Color</label>
        </div>
        <div className="field-body">
          <div className="field has-addons is-fullwidth">
            <div className={`dropdown color is-small ${this.state.active ? 'is-active' : ''}`} onClick={this.onClick}>
              <div className="dropdown-trigger">
                <button type="button" className="button is-small" aria-haspopup="true" aria-controls="dropdown-menu">
                  <div className='color-box' style={{
                    backgroundColor: this.state.color,
                    height: '18px',
                    width: '25px',
                    marginLeft: '-6px'
                  }}></div>
                  <span className="icon is-small">
                    <i className="fas fa-angle-down" aria-hidden="true"></i>
                  </span>
                </button>
              </div>
              <div className="dropdown-menu" id="dropdown-menu" role="menu">
                <a href="#" className="dropdown-item">
                  <GithubPicker
                    width='190px'
                    color={this.state.color}
                    onChangeComplete={(color, e) => { this.setState({ color: color.hex }); onChange(color.hex) }}
                    colors={['#000000', '#DB3E00', '#FCCB00', '#008B02', '#006B76', '#1273DE', '#004DCF']}
                    triangle='hide' />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ColorPicker;