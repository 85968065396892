/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-props-no-spreading */
import Navbar from 'components/Page/Navbar';
import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { fetchApps, fetchEmailApps } from 'redux/actions/appActions';
import {
  deleteStep,
  fetchWorkflows,
  fetchWorkflowSteps,
  setCurrentStep
} from 'redux/actions/workflowActions';
import {
  getCurrentStep,
  getUser,
  getWorkflow,
  getWorkflows,
  getWorkflowSteps,
  isActionPending
} from 'redux/selectors';
import Drawer from '../_common/Drawer';
import Toolbar from './components/Toolbar';
import availableSteps, { getIcon } from './steps/availableSteps';
import DocumentSettings from './steps/DocumentForm';
import DriveSettings from './steps/Drive';
import EmailSettings from './steps/Email';
import IncomingWebhook from './steps/IncomingWebhook';
import IntermediateForm from './steps/IntermediateForm';
import New from './steps/NewStep';
import OutgoingWebhook from './steps/OutgoingWebhook';
import SlackSettings from './steps/Slack';
import SmartVaultClientSettings from './steps/SmartVaultClient';
import SmartVaultCustomLocation from './steps/SmartVaultCustomLocation';
import SmartVaultProspectiveSettings from './steps/SmartVaultProspective';
import './WorkflowEditor.scss';
import WorkflowSettings from './WorkflowSettings';
import WorkflowStep from './WorkflowStep';

const WorkflowEditor = (props) => {
  const workflowId = props.match.params.id;

  // eslint-disable-next-line no-unused-vars
  const [openTab, changeOpenTab] = useState('');
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const user = useSelector(getUser);
  const workflows = useSelector(getWorkflows);
  const workflow = useSelector((state) => getWorkflow(state, workflowId));
  const workflowSteps = useSelector(getWorkflowSteps);
  const currentStepId = useSelector(getCurrentStep);
  const currentStep = workflowSteps.find((s) => s.id === currentStepId);

  const pendingWorkflow = useSelector((state) =>
    isActionPending(state, 'GET_WORKFLOWS')
  );
  const pendingSteps = useSelector((state) =>
    isActionPending(state, 'GET_WORKFLOWSTEPS')
  );

  useEffect(() => {
    // eslint-disable-next-line no-undef
    window.document.title = 'Workflow Editor | Docdown';
    dispatch(showLoading());
    if (workflows.find((wf) => wf.id === workflowId)) {
      dispatch(fetchWorkflowSteps(workflowId));
    } else {
      dispatch(fetchWorkflows());
      dispatch(fetchWorkflowSteps(workflowId));
    }
    dispatch(fetchApps());
    dispatch(fetchEmailApps());
  }, []);

  useEffect(() => {
    async function loaded() {
      function sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
      }
      if (pendingSteps && pendingWorkflow) {
        if (!pendingSteps.pending && !pendingWorkflow.pending) {
          if (workflow && workflowSteps && workflowSteps.length >= 0) {
            await sleep(1000);
            setLoading(false);
            dispatch(hideLoading());
          }
        }
      }
    }
    loaded();
  }, [workflowSteps, workflow, dispatch]);

  useEffect(() => {
    if (workflowSteps[0]) dispatch(setCurrentStep(workflowSteps[0].id));
  }, [workflowSteps[0]]);

  const renderLoading = () => {
    return (
      <div className="loader_container">
        <Loader type="Oval" color="#ccc" height={30} width={30} />
        <span>Loading Workflow Editor...</span>
      </div>
    );
  };

  const renderStep = (step, index) => {
    let isLast = true;
    if (index) {
      isLast = index + 1 === workflowSteps.length;
    }
    if (index === 0) {
      isLast = false;
    }
    if (workflowSteps.length === 1) {
      isLast = true;
    }
    if (!step) return null;
    const stepDetail = availableSteps.find((a) => a.type === step.type);
    const stepProps = {
      isLast,
      name: step.name,
      defaultName: stepDetail.name,
      description: stepDetail.description,
      category: stepDetail.category,
      key: step.id,
      id: step.id,
      order: step.order,
      active: step.id === currentStepId,
      onClick: () => dispatch(setCurrentStep(step.id)),
      onDelete: () => dispatch(deleteStep(step.id))
    };
    switch (step.type) {
      case 'document':
        return (
          <WorkflowStep
            type="Document Generation"
            icon={getIcon(step.type)}
            {...stepProps}
          />
        );
      case 'intermediateForm':
        return (
          <WorkflowStep
            type="Online Form"
            icon={getIcon(step.type)}
            {...stepProps}
          />
        );
      case 'incomingWebhook':
        return (
          <WorkflowStep
            type="Outgoing Webhook"
            icon={getIcon(step.type)}
            {...stepProps}
          />
        );
      case 'email':
        return (
          <WorkflowStep
            type="Email Notification"
            icon={getIcon(step.type)}
            {...stepProps}
          />
        );
      case 'smartvault':
        return (
          <WorkflowStep
            type="SmartVault"
            icon={getIcon(step.type)}
            {...stepProps}
          />
        );
      case 'smartvaultClientVault':
        return (
          <WorkflowStep
            type="SmartVault"
            icon={getIcon(step.type)}
            {...stepProps}
          />
        );
      case 'smartvaultCustomLocation':
        return (
          <WorkflowStep
            type="SmartVault"
            icon={getIcon(step.type)}
            {...stepProps}
          />
        );

      case 'drive':
        return (
          <WorkflowStep
            type="Google Drive"
            icon={getIcon(step.type)}
            {...stepProps}
          />
        );
      case 'slack':
        return (
          <WorkflowStep
            type="Slack Notification"
            icon={getIcon(step.type)}
            {...stepProps}
          />
        );
      case 'outgoingWebhook':
        return (
          <WorkflowStep
            type="Outgoing Webhook"
            icon={getIcon(step.type)}
            {...stepProps}
          />
        );

      default:
        return null;
    }
  };
  const renderSettings = () => {
    if (!currentStep) {
      return <New workflowId={workflowId} availableSteps={availableSteps} />;
    }
    const stepDetail = availableSteps.find((a) => a.type === currentStep.type);
    const formProps = {
      key: currentStep.id,
      step: currentStep,
      defaultName: stepDetail.name,
      form: `${currentStep.type}_${currentStep.id}`,
      initialValues: currentStep?.data?.stepSettings,
      enableReinitialize: true
    };
    switch (currentStep.type) {
      case 'document': {
        return <DocumentSettings {...formProps} />;
      }
      case 'intermediateForm': {
        return <IntermediateForm {...formProps} />;
      }
      case 'email': {
        return <EmailSettings {...formProps} />;
      }
      case 'smartvault': {
        return <SmartVaultProspectiveSettings {...formProps} />;
      }
      case 'smartvaultClientVault': {
        return <SmartVaultClientSettings {...formProps} />;
      }
      case 'drive': {
        return <DriveSettings {...formProps} />;
      }
      case 'slack': {
        return <SlackSettings {...formProps} />;
      }
      case 'outgoingWebhook': {
        return <OutgoingWebhook {...formProps} />;
      }
      case 'incomingWebhook': {
        return <IncomingWebhook {...formProps} />;
      }
      case 'smartvaultCustomLocation': {
        return <SmartVaultCustomLocation {...formProps} />;
      }
      default:
        return null;
    }
  };

  function render() {
    return (
      <>
        <Navbar />
        <div className="workflow_editor_container">
          {loading ? (
            renderLoading()
          ) : (
            <>
              <Toolbar
                workflow={workflow}
                onTitleClick={() => {
                  changeOpenTab('Workflow Settings');
                }}
              />

              <div className="workflow_editor columns is-gapless is-marginless is-paddingless">
                <div className="column is-one-third workflow_steps_column">
                  <div className="container ">
                    <div className="workflow_steps">
                      {workflowSteps.map((step, i) => renderStep(step, i))}
                      {workflowSteps && workflowSteps.length > 0 && (
                        <button
                          id="workflow-editor-add-step-button"
                          className="new_button button is-rounded is-small"
                          onClick={() => dispatch(setCurrentStep(''))}
                          disabled={!workflowSteps.reduce((prev, curr) => prev && curr.data?.valid, true)}
                        >
                          <span className="icon is-small">
                            <i className="fa fa-plus" />
                          </span>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="column is-two-thrids">{renderSettings()}</div>

                <Drawer
                  id="workflow_editor"
                  tabs={[
                    {
                      name: 'Workflow Settings',
                      icon: 'fas fa-cog',
                      props: { workflow },
                      component: WorkflowSettings
                    }
                  ]}
                />
              </div>
            </>
          )}
        </div>
      </>
    );
  }
  return render();
};

export default WorkflowEditor;
