import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
class Checkbox extends Component {
  render() {
    let { value, onChange } = this.props.input;
    value = value === true || value === 'true';

    return (
      <div className="field is-horizontal">
        <div className="field-label is-small">
          <label htmlFor={this.props.label} className="label">
            {this.props.label}
          </label>
        </div>
        <div className="field-body">
          <div className="control">
            <input
              id={this.props.label}
              className="checkbox-input"
              type="checkbox"
              checked={value ? 'checked' : ''}
              onChange={(e) => {
                value = !value;
                onChange(value);
              }}
            />
            {this.props.tooltip && (
              <i
                className="fas fa-question-circle"
                data-tip={this.props.tooltip}
              ></i>
            )}
          </div>
        </div>
        <ReactTooltip
          className="field_tooltip"
          place="left"
          effect="solid"
          offset={{ top: 0 }}
          multiline={true}
        />
      </div>
    );
  }
}
export default Checkbox;
