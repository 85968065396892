/* eslint-disable array-callback-return */
import Form from 'components/Trigger/components/Form';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setRenderedPdf } from 'redux/actions';
import { getFields, getRenderedData } from 'redux/selectors';
import './DocumentForm.scss';

const DocumentForm = (props) => {
  const fields = useSelector(getFields);
  const dispatch = useDispatch();
  const renderedData = useSelector(getRenderedData);
  const initialValues =
    renderedData && renderedData.values
      ? Object.keys(renderedData.values)
          .map((key) => ({
            key,
            value:
              typeof renderedData.values[key] === 'object'
                ? null
                : renderedData.values[key]
          }))
          .filter((f) => f.value !== null)
          .reduce(
            (obj, item) => Object.assign(obj, { [item.key]: item.value }),
            {}
          )
      : {};
  const onSubmit = (values) => {
    try {
      dispatch(setRenderedPdf(props.documentId, fields, values));
    } catch (err) {
      console.log(err);
    }
  };

  function render() {
    return (
      <div className="document_form column">
        <div className="container">
          <Form
            initialValues={initialValues}
            fields={fields}
            onSubmit={onSubmit}
            mode="preview"
            labels={{ submitLabel: 'Preview Document' }}
            setActiveField={() => {}}
          />
        </div>
      </div>
    );
  }
  return render();
};

export default DocumentForm;
