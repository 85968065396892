export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_NUM_PAGES = 'SET_NUM_PAGES';
export const STORE_RENDERED_PAGES = 'STORE_RENDERED_PAGES';
export const SET_FIELDS = 'SET_FIELDS';
export const SET_ANNOTATION = 'SET_ANNOTATION';
export const ADD_FIELD = 'ADD_FIELD';
export const DELETE_FIELD = 'DELETE_FIELD';
export const SET_ACTIVE_FIELD = 'SET_ACTIVE_FIELD';
export const UPDATE_FIELD = 'UPDATE_FIELD';
export const SET_LEFT_PANEL = 'SET_LEFT_PANEL';
export const SET_CURRENT_SELECTOR = 'SET_CURRENT_SELECTOR';
export const SET_EDITOR_MODE = 'SET_EDITOR_MODE';
export const CLEAR_RENDERED_PDF = 'CLEAR_RENDERED_PDF';
export const CLEAR_RENDERED_PDF_DATA = 'CLEAR_RENDERED_PDF_DATA';
export const CLEAR_PDF = 'CLEAR_PDF';

export const GET_RENDEREDPDF_REQUEST = 'GET_RENDEREDPDF_REQUEST';
export const GET_RENDEREDPDF_SUCCESS = 'GET_RENDEREDPDF_SUCCESS';
export const GET_RENDEREDPDF_FAILURE = 'GET_RENDEREDPDF_FAILURE';

export const GET_PDF_REQUEST = 'GET_PDF_REQUEST';
export const GET_PDF_SUCCESS = 'GET_PDF_SUCCESS';
export const GET_PDF_FAILURE = 'GET_PDF_FAILURE';
export const UPDATE_FIELD_NAME = 'UPDATE_FIELD_NAME';

// Auth types
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const REFRESH_TOKEN_REQUEST = 'REFRESH_TOKEN_REQUEST';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';

export const UPDATE_ACCESS_TOKEN = 'UPDATE_ACCESS_TOKEN';

//Documents types
export const GET_DOCUMENTS_REQUEST = 'GET_DOCUMENTS_REQUEST';
export const GET_DOCUMENTS_SUCCESS = 'GET_DOCUMENTS_SUCCESS';
export const GET_DOCUMENTS_FAILURE = 'GET_DOCUMENTS_FAILURE';

export const CREATE_DOCUMENT_REQUEST = 'CREATE_DOCUMENT_REQUEST';
export const CREATE_DOCUMENT_SUCCESS = 'CREATE_DOCUMENT_SUCCESS';
export const CREATE_DOCUMENT_FAILURE = 'CREATE_DOCUMENT_FAILURE';

export const GET_DOCUMENT_REQUEST = 'GET_DOCUMENT_REQUEST';
export const GET_DOCUMENT_SUCCESS = 'GET_DOCUMENT_SUCCESS';
export const GET_DOCUMENT_FAILURE = 'GET_DOCUMENT_FAILURE';

export const UPDATE_DOCUMENT_REQUEST = 'UPDATE_DOCUMENT_REQUEST';
export const UPDATE_DOCUMENT_SUCCESS = 'UPDATE_DOCUMENT_SUCCESS';
export const UPDATE_DOCUMENT_FAILURE = 'UPDATE_DOCUMENT_FAILURE';

export const DELETE_DOCUMENT_REQUEST = 'DELETE_DOCUMENT_REQUEST';
export const DELETE_DOCUMENT_SUCCESS = 'DELETE_DOCUMENT_SUCCESS';
export const DELETE_DOCUMENT_FAILURE = 'DELETE_DOCUMENT_FAILURE';

export const GET_DOCUMENT_SCHEMAS_REQUEST = 'GET_DOCUMENT_SCHEMA_REQUEST';
export const GET_DOCUMENT_SCHEMAS_SUCCESS = 'GET_DOCUMENT_SCHEMA_SUCCESS';
export const GET_DOCUMENT_SCHEMAS_FAILURE = 'GET_DOCUMENTS_SCHEMA_FAILURE';

// Workflow types
export const SET_CURRENT_STEP = 'SET_CURRENT_STEP';
export const UPDATE_WORKFLOW_DATA = 'UPDATE_WORKFLOW_DATA';

export const UPDATE_STEP = 'UPDATE_STEP';
export const UPDATE_STEP_REQUEST = 'UPDATE_STEP_REQUEST';
export const UPDATE_STEP_SUCCESS = 'UPDATE_STEP_SUCCESS';
export const UPDATE_STEP_FAILURE = 'UPDATE_STEP_FAILURE';

export const CREATE_WORKFLOW_REQUEST = 'CREATE_WORKFLOW_REQUEST';
export const CREATE_WORKFLOW_SUCCESS = 'CREATE_WORKFLOW_SUCCESS';
export const CREATE_WORKFLOW_FAILURE = 'CREATE_WORKFLOW_FAILURE';

export const DELETE_WORKFLOW_REQUEST = 'DELETE_WORKFLOW_REQUEST';
export const DELETE_WORKFLOW_SUCCESS = 'DELETE_WORKFLOW_SUCCESS';
export const DELETE_WORKFLOW_FAILURE = 'DELETE_WORKFLOW_FAILURE';

export const UPDATE_WORKFLOW_REQUEST = 'UPDATE_WORKFLOW_REQUEST';
export const UPDATE_WORKFLOW_SUCCESS = 'UPDATE_WORKFLOW_SUCCESS';
export const UPDATE_WORKFLOW_FAILURE = 'UPDATE_WORKFLOW_FAILURE';

export const GET_WORKFLOWS_REQUEST = 'GET_WORKFLOWS_REQUEST';
export const GET_WORKFLOWS_SUCCESS = 'GET_WORKFLOWS_SUCCESS';
export const GET_WORKFLOWS_FAILURE = 'GET_WORKFLOWS_FAILURE';

export const ACTIVATE_WORKFLOW_REQUEST = 'ACTIVATE_WORKFLOW_REQUEST';
export const ACTIVATE_WORKFLOW_SUCCESS = 'ACTIVATE_WORKFLOW_SUCCESS';
export const ACTIVATE_WORKFLOW_FAILURE = 'ACTIVATE_WORKFLOW_FAILURE';

export const DEACTIVATE_WORKFLOW_REQUEST = 'DEACTIVATE_WORKFLOW_REQUEST';
export const DEACTIVATE_WORKFLOW_SUCCESS = 'DEACTIVATE_WORKFLOW_SUCCESS';
export const DEACTIVATE_WORKFLOW_FAILURE = 'DEACTIVATE_WORKFLOW_FAILURE';

export const GET_WORKFLOWSTEPS_REQUEST = 'GET_WORKFLOWSTEPS_REQUEST';
export const GET_WORKFLOWSTEPS_SUCCESS = 'GET_WORKFLOWSTEPS_SUCCESS';
export const GET_WORKFLOWSTEPS_FAILURE = 'GET_WORKFLOWSTEPS_FAILURE';

export const ADD_STEP_REQUEST = 'ADD_STEP_REQUEST';
export const ADD_STEP_SUCCESS = 'ADD_STEP_SUCCESS';
export const ADD_STEP_FAILURE = 'ADD_STEP_FAILURE';

export const DELETE_STEP_REQUEST = 'DELETE_STEP_REQUEST';
export const DELETE_STEP_SUCCESS = 'DELETE_STEP_SUCCESS';
export const DELETE_STEP_FAILURE = 'DELETE_STEP_FAILURE';

// History types
export const GET_WORKFLOWRUNS_REQUEST = 'GET_WORKFLOWRUNS_REQUEST';
export const GET_WORKFLOWRUNS_SUCCESS = 'GET_WORKFLOWRUNS_SUCCESS';
export const GET_WORKFLOWRUNS_FAILURE = 'GET_WORKFLOWRUNS_FAILURE';

// App types
export const FETCH_EMAIL_APPS = 'FETCH_EMAIL_APPS';
export const FETCH_DRIVE_APPS = 'FETCH_DRIVE_APPS';

export const GET_APPS_REQUEST = 'GET_APPS_REQUEST';
export const GET_APPS_SUCCESS = 'GET_APPS_SUCCESS';
export const GET_APPS_FAILURE = 'GET_APPS_FAILURE';

export const SAVE_DOCUMENT = 'SAVE_DOCUMENT';

export const CLONE_WORKFLOW_REQUEST = 'CLONE_WORKFLOW_REQUEST';
export const CLONE_WORKFLOW_SUCCESS = 'CLONE_WORKFLOW_SUCCESS';
export const CLONE_WORKFLOW_FAILURE = 'CLONE_WORKFLOW_FAILURE';

export const CLONE_DOCUMENT_REQUEST = 'CLONE_DOCUMENT_REQUEST';
export const CLONE_DOCUMENT_SUCCESS = 'CLONE_DOCUMENT_SUCCESS';
export const CLONE_DOCUMENT_FAILURE = 'CLONE_DOCUMENT_FAILURE';

// Folder types
export const SET_CURRENT_FOLDER = 'SET_CURRENT_FOLDER';
export const SET_FOLDER_NAVIGATION_HISTORY = 'SET_FOLDER_NAVIGATION_HISTORY';
