// Action types
const types = {
  REGISTER_DRAWER: 'REGISTER_DRAWER',
  OPEN_DRAWER_TAB: 'OPEN_DRAWER_TAB',
  CLOSE_DRAWER: 'CLOSE_DRAWER'
}
// Action creators
export const registerDrawer = (drawerId) => {
  return {
    type: types.REGISTER_DRAWER,
    payload: { drawerId }
  }
}

export const openDrawerTab = (drawerId, tabName) => {
  return {
    type: types.OPEN_DRAWER_TAB,
    payload: { drawerId, tabName }
  }
}

export const closeDrawer = (drawerId) => {
  return {
    type: types.CLOSE_DRAWER,
    payload: drawerId
  }
}

// Reducer
export const drawerReducer = (state = {}, action) => {
  switch (action.type) {
    case types.REGISTER_DRAWER: {
      const { drawerId } = action.payload
      return { ...state, [drawerId]: { id: drawerId, open: false, activeTab: '' } }
    }
    case types.OPEN_DRAWER_TAB: {
      const { drawerId, tabName } = action.payload
      return { ...state, [drawerId]: { ...state[drawerId], open: true, activeTab: tabName } }
    }
    case types.CLOSE_DRAWER: {
      const drawerId = action.payload
      if (!state[drawerId].open) { return state }
      return { ...state, [drawerId]: { ...state[drawerId], open: false, activeTab: '' } }
    }
    default: return state
  }
}