import server from 'api/server';
import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { getEmailApps, getWorkflow, getWorkflows } from 'redux/selectors';
import { updateWorkflow } from '../../redux/actions/workflowActions';
import { ReSelect } from './components/Select';

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const WorkflowSettings = (props) => {
  const [loading, setLoading] = useState(false);
  const [brands, setBrands] = useState([]);
  const dispatch = useDispatch();
  const workflow = useSelector((state) =>
    getWorkflow(state, props.workflow.id)
  );
  const workflows = useSelector(getWorkflows);
  const emails = useSelector(getEmailApps);
  const fetchBrands = async () => {
    try {
      setLoading(true);
      const { data } = await server.get('brands/');
      setLoading(false);
      setBrands(data);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  useEffect(() => {
    const fromEmail = workflow.settings.fromEmail;
    const brand = workflow.settings.brand;
    props.initialize({ ...workflow, fromEmail, brand });
    fetchBrands();
  }, []);

  const emailOptions = [];
  emails.forEach((e) => {
    if (e.status === 'active') {
      emailOptions.push({ label: e.name, value: e.id, type: e.type });
    }
  });
  emailOptions.push({
    label: 'notifications@docdown.io',
    value: 'notifications@docdown.io',
    type: 'default'
  });
  const brandOptions = brands.map((e) => ({ label: e.name, value: e.id }));
  brandOptions.push({ label: 'Default', value: 'default' });

  const workflowNameValid = (value) => {
    const workflowNames = workflows.map((w) => w.name.toLowerCase()) || [];
    const lowerName = value ? value.toLowerCase() : '';
    if (workflowNames.includes(lowerName)) {
      return false;
    }
    return true;
  };

  const { handleSubmit, pristine, submitting, error } = props;
  return (
    <>
      {loading ? (
        <div className="billing_loader_container workflowsetting_loader">
          <Loader type="Oval" color="#ccc" height={30} width={30} />
        </div>
      ) : (
        <form
          onSubmit={handleSubmit(async (values) => {
            const { name, fromEmail, brand } = values;
            if (!workflowNameValid(name)) {
              throw new SubmissionError({
                _error: 'Workflow with this name already exists!'
              });
            }
            dispatch(
              updateWorkflow({
                ...workflow,
                name,
                settings: { ...workflow.settings, fromEmail, brand }
              })
            );
            await sleep(500);
          })}
        >
          <div className="workflow_settings">
            <div className="field">
              <label className="label">Workflow Name</label>
              <div className="control">
                <Field
                  name="name"
                  component="input"
                  className="input is-small"
                />
              </div>
            </div>
            <label className="label">Notification From Email</label>
            <div className="field is-grouped">
              <div className="control is-expanded">
                <Field
                  name="fromEmail"
                  className="from is-fullwidth"
                  component={ReSelect}
                  options={emailOptions}
                  placeholder="Email"
                />
              </div>
              {/* <div className="control">{gmailAuth()}</div> */}
            </div>
            <p className="help pull-up">
              Select the email address to use to send notifications for this
              workflow. This includes signed document and audit trail email.
            </p>
            <label className="label">Brand Identity</label>
            <div className="field is-grouped">
              <div className="control is-expanded">
                <Field
                  name="brand"
                  className="from is-fullwidth"
                  component={ReSelect}
                  options={brandOptions}
                  placeholder="Brand"
                />
              </div>
              {/* <div className="control">{gmailAuth()}</div> */}
            </div>
            <p className="help pull-up">
              Select the brand identity to customize form and email appearances.
            </p>
            {error && <p className="help is-danger">{error}</p>}
            <button
              disabled={pristine}
              className={`button font-14px ${submitting && 'is-loading'}`}
              type="submit"
            >
              Save
            </button>
          </div>
        </form>
      )}
    </>
  );
};
export default reduxForm({ form: 'workflow_settings' })(WorkflowSettings);
