import validFilename from 'valid-filename';

export const validateSignature = (value) => {
  const errorMsg = 'Please provide your signature details!';
  if (value) {
    if (value.authFields && value.authFields === 'none') {
      if (!value.signature) return 'Please provide your signature!';
    } else {
      if (!value.signerEmail) return errorMsg;
      if (!value.signerName) return errorMsg;
      if (!value.signature) return errorMsg;
    }
    return undefined;
  } else return errorMsg;
};

export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const commaSeparatedEmails = (value) => {
  if (!value) return undefined;
  const emails = value.split(',');
  let errors = '';
  emails.map(function (email) {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(email)) {
      if (email.includes('{{') && email.includes('}}')) return;
      errors = `${errors}\nEmail ${email} is invalid`;
    }
  });
  return errors ? errors : undefined;
};

export const validMergeTags = (prevData, msg) => {
  const validFields = [
    ...Object.keys(prevData).map((key) => prevData[key].map((i) => i.name))
  ].flat();

  return function (value) {
    if (!value) return undefined;
    //SOURCE: https://stackoverflow.com/a/15502875
    const matches = value.match(/{{\s*[\w\.]+\s*}}/g);

    // If no merge tags present its valid.
    if (matches === null) {
      return undefined;
    }

    const tags = matches.map((x) => x.match(/[\w\.]+/)[0]);
    const tagFields = [...tags.map((t) => t.split('.')[1])];
    return tagFields.every((tag) => validFields.includes(tag))
      ? undefined
      : msg;
  };
};

export const alphaNumeric = (value) =>
  value && /[^a-zA-Z0-9 ]/i.test(value)
    ? 'Please use only alphanumeric characters'
    : undefined;

export const validFileName = (value) =>
  value && validFilename(value) ? undefined : 'Please use a valid file name';
