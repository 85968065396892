import server from 'api/server';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import Modal from 'react-responsive-modal';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { UserRoles } from 'constants/UserRoles';

export const InviteModal = ({
  open,
  onClose,
  userQuota,
  inviter,
  onSucccess
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  return (
    <Modal open={open} onClose={onClose} closeOnOverlayClick={false}>
      <div className="invite_member_modal">
        <h3 className="title is-h3">Invite team member</h3>
        <p>
          You are about to invite a colleague to join your Docdown account.
          Please fill in their contact details below and choose an appropriate
          access role for them.
        </p>
        <p>
          There can be a maximum of {userQuota || 1} members in this account.
        </p>
        <Formik
          initialValues={{ name: '', email: '', role: UserRoles.MEMBER }}
          validationSchema={Yup.object().shape({
            name: Yup.string()
              .min(2, 'Too Short!')
              .max(100, 'Too Long!')
              .required('Required'),
            email: Yup.string().email('Invalid email').required('Required')
          })}
          onSubmit={async ({ name, email, role }) => {
            try {
              setError(null);
              setLoading(true);
              await server.post('/company/invite', {
                name,
                email,
                role,
                inviter
              });
              setLoading(false);
              toast.info('Member has been invited to join!');
              onClose();
              setLoading(false);
              await onSucccess();
            } catch (err) {
              setLoading(false);
              if (
                err.response &&
                err.response.data &&
                err.response.data.message
              )
                setError(err.response.data.message);
              else {
                console.log(err);
                onClose();
                toast.info('Could not invite team member!');
              }
            }
          }}
        >
          {({ errors, touched }) => (
            <Form className="">
              <div className="field">
                <label className="label">Full Name</label>
                <div className="control">
                  <Field name="name">
                    {({ field: { value }, form: { setFieldValue } }) => (
                      <input
                        id="invite-modal-full-name-input"
                        className="input"
                        value={value}
                        onChange={(e) => setFieldValue('name', e.target.value)}
                      />
                    )}
                  </Field>
                  {errors.name && touched.name ? (
                    <p className="help is-danger">{errors.name}</p>
                  ) : null}
                </div>
              </div>
              <div className="field">
                <label className="label">Email</label>
                <div className="control">
                  <Field name="email">
                    {({ field: { value }, form: { setFieldValue } }) => (
                      <input
                        id="invite-modal-email-input"
                        className="input"
                        value={value}
                        onChange={(e) => setFieldValue('email', e.target.value)}
                      />
                    )}
                  </Field>
                  {errors.email && touched.email ? (
                    <p className="help is-danger">{errors.email}</p>
                  ) : null}
                </div>
              </div>
              <div className="field">
                <label className="label">Access</label>
                <div className="control">
                  <Field name="role">
                    {({ field: { value }, form: { setFieldValue } }) => (
                      <>
                        <div className="select">
                          <select
                            id="invite-modal-access-select"
                            value={value}
                            onChange={(e) =>
                              setFieldValue('role', e.target.value)
                            }
                          >
                            <option value="member">Member</option>
                            <option value="admin">Admin</option>
                          </select>
                        </div>
                      </>
                    )}
                  </Field>
                </div>
              </div>
              {error && <p className="help is-danger">{error}</p>}
              <hr />
              <div className="field is-grouped">
                <div className="control">
                  <button
                    id="invite-modal-submit-button"
                    type="submit"
                    className={`button is-info ${loading ? 'is-loading' : ''}`}
                  >
                    Send Invitation
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export const EditModal = ({ open, onClose, user, onSucccess }) => {
  const [loading, setLoading] = useState(false);

  return (
    <>
      {user && (
        <Modal open={open} onClose={onClose} closeOnOverlayClick={false}>
          <div className="edit_member_modal">
            <h3 className="title is-h3">Edit member details</h3>
            <p>Edit team member's account details and access role.</p>
            <Formik
              initialValues={user}
              validationSchema={Yup.object().shape({
                name: Yup.string()
                  .min(2, 'Too Short!')
                  .max(100, 'Too Long!')
                  .required('Required'),
                email: Yup.string().email('Invalid email').required('Required')
              })}
              onSubmit={async ({ name, email, role }) => {
                try {
                  setLoading(true);
                  const { data } = await server.post(
                    `/company/users/${user.id}`,
                    {
                      email,
                      name,
                      role
                    }
                  );
                  setLoading(false);
                  toast.info('Team member edited successfully!');
                  onClose();
                  await onSucccess();
                } catch (err) {
                  setLoading(false);
                  onClose();
                  console.log(err);
                  toast.info('Could not edit team member!');
                }
              }}
            >
              {({ errors, touched }) => (
                <Form className="">
                  <div className="field">
                    <label className="label">Full Name</label>
                    <div className="control">
                      <Field name="name">
                        {({ field: { value }, form: { setFieldValue } }) => (
                          <input
                            id="edit-modal-name-input"
                            className="input"
                            value={value}
                            onChange={(e) =>
                              setFieldValue('name', e.target.value)
                            }
                          />
                        )}
                      </Field>
                      {errors.name && touched.name ? (
                        <p className="help is-danger">{errors.name}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Email</label>
                    <div className="control">
                      <Field name="email">
                        {({ field: { value } }) => (
                          <input
                            id="edit-modal-email-input"
                            className="input"
                            value={value}
                            disabled
                          />
                        )}
                      </Field>
                      {errors.email && touched.email ? (
                        <p className="help is-danger">{errors.email}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Access</label>
                    <div className="control">
                      <Field name="role">
                        {({ field: { value }, form: { setFieldValue } }) => (
                          <>
                            <div className="select">
                              <select
                                id="edit-modal-access-select"
                                value={value}
                                onChange={(e) =>
                                  setFieldValue('role', e.target.value)
                                }
                                disabled={value === UserRoles.ADMIN}
                              >
                                <option value="admin">Admin</option>
                                <option value="member">Member</option>
                              </select>
                            </div>
                            {value === UserRoles.ADMIN && (
                              <p className="help">
                                Admin's access rights cannot be changed.
                              </p>
                            )}
                          </>
                        )}
                      </Field>
                    </div>
                  </div>
                  <hr />
                  <div className="field is-grouped">
                    <div className="control">
                      <button
                        id="edit-modal-submit-button"
                        type="submit"
                        className={`button is-info is-small ${
                          loading ? 'is-loading' : ''
                        }`}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal>
      )}
    </>
  );
};
