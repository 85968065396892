import uniqid from 'uniqid';
import * as types from '../actions/types';

const editorInitialState = {
  currentPage: 1,
  numPages: 1,
  activeField: null,
  renderedPages: {},
  annotation: {},
  fields: [],
  leftPanel: 'fields',
  currentSelector: 'text',
  editorMode: 'editor',
  renderedPDF: '',
  changesSynced: true
};

export function documentEditorReducer(state = editorInitialState, action) {
  switch (action.type) {
    case types.SET_CURRENT_PAGE:
      return { ...state, activeField: null, currentPage: action.payload };

    case types.SET_NUM_PAGES:
      return { ...state, numPages: action.payload };

    case types.STORE_RENDERED_PAGES:
      return { ...state, renderedPages: action.payload };

    case types.SET_ANNOTATION:
      return { ...state, annotation: action.payload };

    case types.ADD_FIELD:
      const { geometry, data } = action.payload;
      const field = {
        geometry,
        data: {
          ...data,
          id: uniqid()
        }
      };
      return {
        ...state,
        activeField: field.data.id,
        annotation: {},
        fields: [...state.fields, field]
      };

    case types.DELETE_FIELD:
      return {
        ...state,
        fields: [
          ...state.fields.filter((field) => field.data.id !== action.payload)
        ]
      };

    case types.SET_ACTIVE_FIELD:
      return { ...state, activeField: action.payload };

    case types.UPDATE_FIELD:
      let index = state.fields.findIndex(
        (field) => field.data.id === action.payload.data.id
      );
      let fields = state.fields;
      if (index === -1) {
        return {
          ...state,
          fields: [...fields, action.payload],
          changesSynced: false
        };
      } else {
        fields[index] = action.payload;
        return { ...state, fields: [...fields], changesSynced: false };
      }

    case types.SET_LEFT_PANEL:
      return { ...state, leftPanel: action.payload };

    case types.SET_CURRENT_SELECTOR: {
      return { ...state, currentSelector: action.payload };
    }

    case types.SET_FIELDS:
      return {
        ...state,
        fields: action.payload
      };

    case types.UPDATE_DOCUMENT_SUCCESS:
      return {
        ...state,
        changesSynced: true
      };

    case types.SET_EDITOR_MODE: {
      return { ...state, editorMode: action.payload };
    }
    case types.GET_RENDEREDPDF_SUCCESS: {
      const { renderedPDF, documentId, values } = action.payload;
      return {
        ...state,
        renderedPDF,
        renderedData: { documentId, values }
      };
    }
    case types.CLEAR_RENDERED_PDF: {
      return { ...state, renderedPDF: '', renderedData: {} };
    }
    case types.CLEAR_RENDERED_PDF_DATA: {
      return { ...state, renderedData: {} };
    }
    case types.GET_PDF_SUCCESS: {
      return { ...state, pdf: action.payload };
    }
    case types.CLEAR_PDF: {
      return { ...state, pdf: '' };
    }
    case types.UPDATE_FIELD_NAME: {
      const nameMap = action.payload;
      let fields = [];
      state.fields.map((field) => {
        if (!!field.data.conditionalFormula?.length) {
          field.data.conditionalFormula.forEach((formula) => {
            const { expression, query } = formula;
            const key = Object.keys(nameMap)[0];
            // Update field name in expression.
            let newExpression = expression.replace(
              new RegExp(key, 'g'),
              nameMap[key]
            );
            formula.expression = newExpression;
            // Update field name in query.
            let newQuery = JSON.stringify(query).replace(key, nameMap[key]);
            formula.query = JSON.parse(newQuery);
          });
          fields.push(field);
        } else {
          fields.push(field);
        }
      });
      return { ...state, fields };
    }
    default:
      return state;
  }
}

const authInitialState = {
  accessToken: '',
  error: '',
  user: {},
  isLoggedIn: false
};
export function authReducer(state = authInitialState, action) {
  switch (action.type) {
    case types.LOGIN_SUCCESS: {
      return { ...state, ...action.payload };
    }
    case types.LOGIN_FAILURE: {
      return { ...authInitialState, error: action.payload };
    }
    case types.LOGOUT_SUCCESS: {
      return authInitialState;
    }
    case types.REFRESH_TOKEN_SUCCESS: {
      return { ...state, ...action.payload };
    }
    case types.GET_USER_SUCCESS: {
      return { ...state, user: action.payload };
    }
    case types.UPDATE_ACCESS_TOKEN: {
      return { ...state, accessToken: action.payload };
    }
    default:
      return state;
  }
}

const documentsInitialState = { documents: [] };
export function documentsReducer(state = documentsInitialState, action) {
  switch (action.type) {
    case types.GET_DOCUMENTS_SUCCESS: {
      return { ...state, documents: action.payload };
    }
    case types.GET_DOCUMENT_SUCCESS: {
      return { ...state, currentDocumentForm: action.payload };
    }
    case types.GET_DOCUMENT_SCHEMAS_SUCCESS: {
      return { ...state, schemas: action.payload };
    }
    case types.SAVE_DOCUMENT: {
      return {
        ...state,
        documents: state.documents.map((document) => {
          if (document.id === action.payload.id) {
            return action.payload;
          }
          return document;
        })
      };
    }

    case types.CLONE_DOCUMENT_SUCCESS: {
      return { ...state, documents: [...state.documents, action.payload] };
    }

    default:
      return state;
  }
}

const workflowEditorInitialState = {
  currentStep: '',
  workflowData: {},
  workflowSteps: []
};
export function workflowEditorReducer(
  state = workflowEditorInitialState,
  action
) {
  switch (action.type) {
    case types.SET_CURRENT_STEP: {
      return { ...state, currentStep: action.payload };
    }
    case types.UPDATE_STEP: {
      let index = state.workflowSteps.findIndex(
        (step) => step.id === action.payload.id
      );
      let steps = state.workflowSteps;
      if (index === -1) {
        return { ...state, workflowSteps: [...steps, action.payload] };
      } else {
        steps[index] = action.payload;
        return { ...state, workflowSteps: [...steps] };
      }
    }
    case types.GET_WORKFLOWSTEPS_SUCCESS: {
      return {
        ...state,
        workflowSteps: [
          ...action.payload.sort((a, b) => {
            return a.order - b.order;
          })
        ]
      };
    }
    case types.ADD_STEP_SUCCESS: {
      return {
        ...state,
        workflowSteps: [...state.workflowSteps, action.payload]
      };
    }
    case types.DELETE_STEP_SUCCESS: {
      return {
        ...state,
        workflowSteps: [
          ...state.workflowSteps.filter((step) => step.id !== action.payload)
        ]
      };
    }
    case types.UPDATE_WORKFLOW_DATA: {
      const newData = {
        ...state.workflowData,
        [action.payload.order]: action.payload.data
      };
      return { ...state, workflowData: newData };
    }
    default:
      return state;
  }
}
const workflowsInitialState = { workflows: [], history: [] };
export function workflowsReducer(state = workflowsInitialState, action) {
  switch (action.type) {
    case types.GET_WORKFLOWS_SUCCESS: {
      return { ...state, workflows: action.payload };
    }
    case types.CREATE_WORKFLOW_SUCCESS: {
      return { ...state, workflows: [...state.workflows, action.payload] };
    }
    case types.DELETE_WORKFLOW_SUCCESS: {
      return {
        ...state,
        workflows: [
          ...state.workflows.filter((wflow) => wflow.id !== action.payload)
        ]
      };
    }
    case types.UPDATE_WORKFLOW_SUCCESS: {
      let index = state.workflows.findIndex((w) => w.id === action.payload.id);
      if (index === -1) {
        return state;
      } else {
        const workflows = state.workflows;
        workflows[index] = action.payload;
        return { ...state, workflows };
      }
    }
    case types.ACTIVATE_WORKFLOW_SUCCESS: {
      let index = state.workflows.findIndex((w) => w.id === action.payload);
      if (index === -1) {
        return state;
      } else {
        const updated = state.workflows[index];
        updated.active = true;
        const workflows = [
          ...state.workflows.filter((wflow) => wflow.id !== action.payload)
        ];
        return { ...state, workflows: [...workflows, updated] };
      }
    }
    case types.DEACTIVATE_WORKFLOW_SUCCESS: {
      let index = state.workflows.findIndex((w) => w.id === action.payload);
      if (index === -1) {
        return state;
      } else {
        const updated = state.workflows[index];
        updated.active = false;
        const workflows = [
          ...state.workflows.filter((wflow) => wflow.id !== action.payload)
        ];
        return { ...state, workflows: [...workflows, updated] };
      }
    }
    case types.GET_WORKFLOWRUNS_SUCCESS: {
      return { ...state, history: action.payload };
    }
    case types.CLONE_WORKFLOW_SUCCESS: {
      return { ...state, workflows: [...state.workflows, action.payload] };
    }
    default:
      return state;
  }
}

const appsInitialState = { emails: [] };
export function appsReducer(state = appsInitialState, action) {
  switch (action.type) {
    case types.GET_APPS_SUCCESS: {
      return { ...state, all: [...action.payload] };
    }
    case types.FETCH_EMAIL_APPS: {
      return { ...state, emails: [...action.payload] };
    }
    case types.FETCH_DRIVE_APPS: {
      return { ...state, drives: [...action.payload] };
    }
    default:
      return state;
  }
}

const filesInitialState = {
  currentFolder: { id: null, name: 'Home' },
  folderNavigationHistory: [{ id: null, name: 'Home' }]
};
export function filesReducer(state = filesInitialState, action) {
  switch (action.type) {
    case types.SET_CURRENT_FOLDER: {
      return { ...state, currentFolder: action.payload };
    }
    case types.SET_FOLDER_NAVIGATION_HISTORY: {
      return { ...state, folderNavigationHistory: action.payload };
    }
    default:
      return state;
  }
}

export const pendingReducer = (state = {}, action) => {
  //INSPIRATION: https://blog.logrocket.com/tracking-action-status-in-redux/
  function getActionName(actionType) {
    if (typeof actionType !== 'string') {
      return null;
    }

    return actionType.split('_').slice(0, -1).join('_');
  }

  const { type, payload } = action;
  const actionName = getActionName(type);

  if (!actionName) {
    return {
      ...state
    };
  }

  if (type.endsWith('_REQUEST')) {
    return {
      ...state,
      [actionName]: {
        pending: true,
        error: ''
      }
    };
  }

  if (type.endsWith('_SUCCESS')) {
    return {
      ...state,
      [actionName]: {
        pending: false,
        error: ''
      }
    };
  }
  if (type.endsWith('_FAILURE')) {
    return {
      ...state,
      [actionName]: {
        pending: false,
        error: payload
      }
    };
  }

  return {
    ...state
  };
};
