import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Uploader from 'components/Documents/Uploader';
import { useHistory } from 'react-router-dom'

import {
  getDocuments,
  getWorkflows,
  getWorkflowHistory,
  isActionPending
} from 'redux/selectors';
import { createWorkflow, fetchWorkflows } from 'redux/actions/workflowActions';

import './OnboardingWidget.scss';

const OnboardingWidget = (props) => {
  const dispatch = useDispatch();

  const [view, setView] = useState(1);
  const [oneComplete, setOneComplete] = useState(false);
  const [twoComplete, setTwoComplete] = useState(false);
  const [threeComplete, setThreeComplete] = useState(false);

  const [uploading, setUploading] = useState(false);

  const documents = useSelector(getDocuments);
  const workflows = useSelector(getWorkflows);
  const whistory = useSelector(getWorkflowHistory);

  const history = useHistory();

  useEffect(() => {
    if (documents && documents.length > 0) {
      setOneComplete(true);
      setView(2);
    }
    if (workflows && workflows.length > 0) {
      setTwoComplete(true);
      setView(3);
    }
    if (whistory && whistory.length > 0) {
      setThreeComplete(true);
    }
  }, [workflows, documents, whistory]);

  useEffect(() => {
    setUploading(false);
  }, [view]);

  return (
    <section className="section onboarding">
      <div className="container">
        <div className="box">
          <div className="columns is-gapless is-marginless is-paddingless">
            <div className="column tasks is-one-quarter">
              <ul>
                <li
                  onClick={(e) => setView(1)}
                  className={`task ${view === 1 && 'is-active'}`}
                >
                  <span>
                    <i
                      className={`fas fa-check-circle ${
                        oneComplete && 'complete'
                      }`}
                    ></i>
                    Create a document
                  </span>
                </li>
                <li
                  onClick={(e) => setView(2)}
                  className={`task  ${view === 2 && 'is-active'}`}
                >
                  <span>
                    <i
                      className={`fas fa-check-circle ${
                        twoComplete && 'complete'
                      }`}
                    ></i>
                    Create a workflow
                  </span>
                </li>
                <li
                  onClick={(e) => setView(3)}
                  className={`task ${view === 3 && 'is-active'}`}
                >
                  <span>
                    <i
                      className={`fas fa-check-circle ${
                        threeComplete && 'complete'
                      }`}
                    ></i>
                    Trigger a workflow
                  </span>
                </li>
              </ul>
            </div>
            <div className="column description is-three-quarter">
              <div className={`instruction columns ${view === 1 && 'is-active'}`}>
                {uploading ? (
                  <Uploader
                    onSuccess={() => {
                      history.push('/documents');
                    }}
                  />
                ) : (
                  <React.Fragment>
                    <div className="column is-two-third">
                      <p>
                        Create a new document by uploading a PDF file. If the
                        PDF has fillable fields, they will imported
                        automatically.
                      </p>
                      {!oneComplete && (
                        <button
                          onClick={(e) => setUploading(true)}
                          className="button is-small is-info"
                        >
                          <span className="icon">
                            <i className="fas fa-plus"></i>
                          </span>
                          <span>Create your first document</span>
                        </button>
                      )}
                    </div>
                    <div className="column is-one-third">
                      <i className="fas fa-file big-icon"></i>
                    </div>
                  </React.Fragment>
                )}
              </div>
              <div className={`instruction columns ${view === 2 && 'is-active'}`}>
                <div className="column is-two-third">
                  <p>
                    Create a workflow to define your document automation
                    process. Create online forms, send notifications and
                    integrate with other apps.
                  </p>
                  {!twoComplete && (
                    <button
                      onClick={(e) => {
                        dispatch(createWorkflow());
                        dispatch(fetchWorkflows());
                      }}
                      className="button is-small is-info"
                    >
                      <span className="icon">
                        <i className="fas fa-plus"></i>
                      </span>
                      <span>Create your first workflow</span>
                    </button>
                  )}
                </div>
                <div className="column is-one-third">
                  <i className="fas fa-stream big-icon"></i>
                </div>
              </div>
              <div className={`instruction columns ${view === 3 && 'is-active'}`}>
                <div className="column is-two-third">
                  Execute a workflow by triggering it. You can trigger execution
                  by filling out the online form or via an API call.
                </div>
                <div className="column is-one-third">
                  <i className="fas fa-history big-icon"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OnboardingWidget;
