import React, { useState } from 'react';

import { connect } from 'react-redux';
import { getCurrentSelector } from 'redux/selectors';

const getSelectorIcon = (selector) => {
  switch (selector) {
    case 'text':
      return <i className="fas fas fa-font"></i>;
    case 'number':
      return <i className="fas fa-hashtag"></i>;
    case 'checkbox':
      return <i className="fas fa-check"></i>;
    case 'dateTime':
      return <i className="far fa-calendar-alt"></i>;
    case 'signature':
      return <i className="fas fa-signature"></i>;
    case 'image':
      return <i className="far fa-image"></i>;
    default:
      return null;
  }
};

function TextEditor(props) {
  const [value, setValue] = useState('');
  return (
    <div className="field_creation" style={{ padding: '8px' }}>
      <div className="field has-addons">
        <div className="control">
          <input
            style={{ width: '150px' }}
            className={`input is-small ${
              !value || props.valid ? '' : 'is-danger'
            }`}
            placeholder={`Enter ${props.currentSelector} field name`}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            onChange={(e) => {
              props.onChange(e);
              setValue(e.target.value);
            }}
            value={props.value}
          />
        </div>
        <div className="control">
          <a
            className="button is-small is-info"
            disabled={!value || !props.valid}
            onClick={(e) => {
              props.valid && props.onSubmit(e);
            }}
          >
            <span style={{ marginRight: '10px' }}>
              {getSelectorIcon(props.currentSelector)}
            </span>{' '}
            <span>Create Field</span>
          </a>
        </div>
      </div>
      {props.errors[0] && (
        <p className="help is-danger">
          This value is already used as a field name!
        </p>
      )}
      {props.errors[1] && (
        <p className="help is-danger">
          Field names can only be up to 64 characters!
        </p>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  currentSelector: getCurrentSelector(state)
});

export default connect(mapStateToProps, {})(TextEditor);
