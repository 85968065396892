import server from 'api/server';
import * as types from './types';

export const fetchApps = () => async (dispatch) => {
  try {
    dispatch({ type: types.GET_APPS_REQUEST });
    const { data } = await server.get(`/apps`);
    dispatch({
      type: types.GET_APPS_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({ type: types.GET_APPS_FAILURE, payload: error.message });
    console.log(error);
  }
};

export const fetchEmailApps = () => async (dispatch) => {
  try {
    // const { data } = await server.get(`/apps/gmail`);
    const { data } = await server.get(`/apps/email`);
    dispatch({
      type: types.FETCH_EMAIL_APPS,
      payload: data
    });
  } catch (error) {
    console.log(error);
  }
};

export const fetchDriveApps = () => async (dispatch) => {
  try {
    const { data } = await server.get(`/apps/drive`);
    dispatch({
      type: types.FETCH_DRIVE_APPS,
      payload: data
    });
  } catch (error) {
    console.log(error);
  }
};
