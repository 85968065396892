import React from 'react';
import T from 'prop-types';
import Point from './components/Point';
import Editor from './components/Editor';
import FancyRectangle from './components/FancyRectangle';
import Rectangle from './components/Rectangle';
import Content from './components/Content';
import Overlay from './components/Overlay';

import { RectangleSelector, PointSelector } from './selectors/index';

export const defaultProps = {
  innerRef: () => {},
  onChange: () => {},
  onSubmit: () => {},
  type: RectangleSelector.TYPE,
  selectors: [RectangleSelector, PointSelector],
  disableAnnotation: false,
  disableSelector: false,
  disableEditor: false,
  disableOverlay: false,
  activeAnnotationComparator: (a, b) => a === b,
  renderSelector: ({ annotation }) => {
    switch (annotation.geometry.type) {
      case RectangleSelector.TYPE:
        return <FancyRectangle annotation={annotation} />;
      case PointSelector.TYPE:
        return <Point annotation={annotation} />;
      default:
        return null;
    }
  },
  renderEditor: ({ annotation, onChange, onSubmit }) => (
    <Editor annotation={annotation} onChange={onChange} onSubmit={onSubmit} />
  ),
  // renderHighlight: ({ key, annotation, active, currentPage, onClick }) => {
  //   switch (annotation.geometry.type) {
  //     case RectangleSelector.TYPE:
  //       if (annotation.geometry.page === currentPage) {
  //         return (
  //           <Rectangle
  //             key={key}
  //             annotation={annotation}
  //             active={active}
  //             onClick={onClick}
  //           />
  //         );
  //       } else {
  //         return null;
  //       }
  //     case PointSelector.TYPE:
  //       return <Point key={key} annotation={annotation} active={active} />;
  //     default:
  //       return null;
  //   }
  // },
  renderContent: ({ key, annotation }) => null,
  renderOverlay: ({ type, annotation }) => {
    switch (type) {
      case PointSelector.TYPE:
        return <Overlay>Click to Annotate</Overlay>;
      default:
        return <Overlay>Click and Drag to Add Fields</Overlay>;
    }
  }
};

export const propTypes = {
  innerRef: T.func,
  onMouseUp: T.func,
  onMouseDown: T.func,
  onMouseMove: T.func,
  onClick: T.func,
  children: T.object,

  annotations: T.arrayOf(
    T.shape({
      type: T.string
    })
  ).isRequired,
  type: T.string,
  selectors: T.arrayOf(
    T.shape({
      TYPE: T.string,
      intersects: T.func.isRequired,
      area: T.func.isRequired,
      methods: T.object.isRequired
    })
  ).isRequired,

  value: T.shape({
    selection: T.object,
    geometry: T.shape({
      type: T.string.isRequired
    }),
    data: T.object
  }),
  onChange: T.func,
  onSubmit: T.func,

  activeAnnotationComparator: T.func,
  activeAnnotations: T.arrayOf(T.any),

  disableAnnotation: T.bool,
  disableSelector: T.bool,
  renderSelector: T.func,
  disableEditor: T.bool,
  renderEditor: T.func,

  renderHighlight: T.func.isRequired,
  renderContent: T.func.isRequired,

  disableOverlay: T.bool,
  renderOverlay: T.func.isRequired,
  allowTouch: T.bool
};
