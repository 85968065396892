import server from 'api/server';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'react-responsive-modal';
import { toast } from 'react-toastify';
import { deleteWorkflow } from 'redux/actions/workflowActions';
import './WorkflowBox.scss';

const DeleteWorkflowModal = ({ workflows, open, onClose }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const onDelete = async () => {
    // check if workflow are active
    const valid = workflows
      ? workflows
          .map(({ active }) => active)
          .every((active) => active === false)
      : false;
    if (!valid) {
      setError('Cannot delete active workflow!');
      return;
    }
    if (workflows.length > 1) {
      const ids = workflows.map((w) => w.id);
      try {
        {
          setLoading(true);
          const response = await server.delete(`/workflows/delete`, {
            data: { workflowIds: ids }
          });

          toast.info(
            `Workflow${ids.length > 1 ? 's' : ''} deleted successfully!`
          );
        }
        onClose();
        setLoading(false);
        setError('');
      } catch (error) {
        setError('');
        onClose();
        setLoading(false);
        toast.info(`Error Deleting Workflow${workflows.length > 1 ? 's' : ''}`);
      }
    } else {
      dispatch(deleteWorkflow(workflows[0].id));
      toast.info('Workflow deleted successfully');
      setError('');
      onClose();
    }
  };
  return (
    <Modal open={open} onClose={onClose} closeOnOverlayClick={false}>
      <div className="modal_top">
        <h3 className="title is-h3">Delete Workflow</h3>
        <p>
          This will permanently remove the Workflow(s), the history and all the
          data collected and generated during executions. This action cannot be
          undone.
        </p>
        {error && <p className="help is-danger">{error}</p>}
        <hr />
        <div className="field is-grouped">
          <div className="control">
            <button
              className={`button is-small is-danger ${loading ? 'is-loading' : ''}`}
              onClick={onDelete}
            >
              Delete
            </button>
          </div>
          <div className="control">
            <button
              className="button is-small"
              onClick={(e) => {
                setError('');
                onClose();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default DeleteWorkflowModal;
