/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';

const Dropdown = (props) => {
  const {
    input,
    label,
    track,
    meta: { touched, error, warning }
  } = props;
  const { value, onChange } = input;
  useEffect(() => {
    props.input.onChange(props.evaluatedValue);
  }, [props.evaluatedValue]);

  return (
    <div className="field" style={props.hidden ? { display: 'none' } : {}}>
      <label className="label ">
        {label}{' '}
        {props.required && (
          <span className="has-text-grey">
            ({props.requiredLabel || 'required'})
          </span>
        )}
      </label>
      <p className="control is-expanded">
        <span className="select  is-fullwidth">
          <select
            onFocus={props.focus}
            value={props.evaluatedValue || value}
            onChange={(e) => {
              onChange(e.target.value);
              if (track) track(e.target.value);
            }}
            disabled={props.static ? true : false}
          >
            <option value=""></option>
            {props.options.map((o) => (
              <option value={o.text}>{o.text}</option>
            ))}
          </select>
        </span>
      </p>
      {props.description && <p className="help">{props.description}</p>}
      <p className="help is-danger">
        {touched &&
          ((error && <span>{error}</span>) ||
            (warning && <span>{warning}</span>))}
        {props.warning
          ? props.warnings.map((warning, i) => <span>{warning}</span>)
          : null}
      </p>
    </div>
  );
};

export default Dropdown;
