/* eslint-disable jsx-a11y/anchor-is-valid */
import useOnClickOutside from 'hooks/useOnClickOutside';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { getCurrentStep, getWorkflowSteps } from 'redux/selectors';
import './Select.scss';

export const ReSelect = (props) => {
  const options = props.options;
  const { value, onChange } = props.input;
  const { touched, error, warning } = props.meta;
  useEffect(() => {
    if (!value && options.length > 0 && !props.multi) {
      onChange(options[0]);
    }
  }, [options, value, onChange]);
  return (
    <div className="reselect">
      <Select
        arrowrenderer={() => <i className="fa fa-trash"></i>}
        isMulti={props.multi ? true : false}
        value={value}
        onChange={(val) => {
          onChange(val);
        }}
        options={options}
        closeMenuOnSelect={props.multi ? false : true}
        {...props}
      />
      <p className="help is-danger">
        {touched &&
          ((error && <span>{error}</span>) ||
            (warning && <span>{warning}</span>))}
        {props.warning
          ? props.warnings.map((warning, i) => <span>{warning}</span>)
          : null}
      </p>
    </div>
  );
};

export const FieldSelect = ({
  input,
  meta,
  options,
  multiple,
  exclusive,
  typeFilter,
  showMenuOnTouch,
  placeholder,
  warnings,
  width,
  enableCurrentStep
}) => {
  const { value, onChange } = input;
  const { touched, error, warning } = meta;

  const steps = useSelector(getWorkflowSteps).sort((a, b) => a.order - b.order);
  const currentStepId = useSelector(getCurrentStep);
  const currentStep = steps.find((step) => step.id === currentStepId);

  const stepOptions = Object.keys(options);

  const [inputValue, updateInputValue] = useState(value);
  const [showMenu, updateShowMenu] = useState(false);
  const [selectedStep, updateSelectedStep] = useState(
    enableCurrentStep ? currentStep.order : currentStep.order - 1
  );

  useEffect(() => {
    onChange(value);
    updateInputValue(value);
  }, [value, onChange, updateInputValue]);

  function onKeyUp(e) {
    if (exclusive) {
      if (e.keyCode === 8) {
        updateInputValue('');
        onChange('');
      }
    }
  }
  function handleInputChange(e) {
    e.preventDefault();
    if (exclusive) {
      if (e.target.value === '') {
        onChange(e.target.value);
        updateInputValue(e.target.value);
      }
    } else {
      onChange(e.target.value);
      updateInputValue(e.target.value);
    }
  }
  function handleOptionClick(value, order) {
    let orderValue = order;
    if (currentStep.order === 1 && order === 1) orderValue = 0;
    const newValue = multiple
      ? `${inputValue}${inputValue ? multiple : ''}{{${orderValue}.${value}}}`
      : `{{${orderValue}.${value}}}`;
    onChange(newValue);
    updateInputValue(newValue);
    updateShowMenu(!showMenu);
  }
  const ref = useRef();
  useOnClickOutside(ref, () => {
    updateShowMenu(false);
  });

  const renderSingleOption = (step, showStepLabel) => {
    if (options[step]) {
      const stepData = typeFilter
        ? options[step].filter((s) => s && s.type === typeFilter)
        : options[step];
      return (
        <>
          {showStepLabel && (
            <div className="dropdown-item">
              <small>
                {step == 0 || step == currentStep.order
                  ? 'CURRENT STEP'
                  : `STEP ${step}`}
              </small>
            </div>
          )}
          {stepData.length == 0 ? (
            <div className="dropdown-item">
              <small>No valid fields</small>
            </div>
          ) : (
            stepData.map(
              (opt) =>
                opt && (
                  <a
                    className="dropdown-item"
                    onClick={() => handleOptionClick(opt.name, step)}
                  >
                    {opt.label ? opt.label : opt.name}
                  </a>
                )
            )
          )}
        </>
      );
    } else
      return (
        <div className="dropdown-item is-center">
          <hr className="dropdown-divider" />
          No valid options
        </div>
      );
  };

  const renderAllOptions = () => {
    return stepOptions.length == 0 ? (
      <div className="dropdown-item is-center">
        <small>No Options</small>
      </div>
    ) : (
      stepOptions.map((step) => renderSingleOption(step))
    );
  };

  const renderStepSelector = () => {
    const renderStepButton = (step, label) => {
      return (
        <li
          className={`${selectedStep == step ? 'is-active' : ''}`}
          onClick={() => updateSelectedStep(step)}
        >
          <a>
            <span>{label || `Step ${step}`}</span>
          </a>
        </li>
      );
    };
    return (
      <div className="dropdown-item">
        <div className="tabs is-toggle is-toggle-rounded">
          <ul>
            {stepOptions.map(
              (step) =>
                !(step == 0 || step == currentStep.order) &&
                renderStepButton(step)
            )}
            {enableCurrentStep &&
              renderStepButton(currentStep.order, 'Current Step')}
          </ul>
        </div>
      </div>
    );
  };
  return (
    <div className="field has-addons field_select" ref={ref}>
      <p className="control is-expanded">
        <input
          value={inputValue}
          onChange={handleInputChange}
          className="input"
          onKeyUp={onKeyUp}
          onFocus={(e) => {
            showMenuOnTouch && updateShowMenu(true);
          }}
          type="text"
          placeholder={placeholder}
        />
        <p className="help is-danger">
          {touched &&
            ((error && <span>{error}</span>) ||
              (warning && <span>{warning}</span>))}
          {warning
            ? warnings.map((warning, i) => <span>{warning}</span>)
            : null}
        </p>
      </p>
      <p className="control field_button">
        <span className={`dropdown is-right ${showMenu ? 'is-active' : ''}`}>
          <div className="dropdown-trigger">
            <button
              onClick={(e) => {
                e.preventDefault();
                updateShowMenu(!showMenu);
              }}
              className="button"
              aria-haspopup="true"
              aria-controls="dropdown-menu3"
            >
              <span className="icon is-small">
                <i className="fas fa-plus-circle"></i>
              </span>
            </button>
          </div>
          <div
            className="dropdown-menu"
            style={{ width: `${1.9 * width}px` }}
            id="dropdown-menu3"
            role="menu"
          >
            <div className="dropdown-content">
              {renderStepSelector()}
              {renderSingleOption(selectedStep)}
            </div>
          </div>
        </span>
      </p>
    </div>
  );
};
