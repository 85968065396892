import CollapsibleCard from 'components/_common/CollapsibleCard';
import TextField from 'components/_common/FormFields/TextField';
import useDimensions from 'hooks/useDimensions';
import React, { useEffect, useState } from 'react';
import EditableLabel from 'react-inline-editing';
import PasswordStrengthBar from 'react-password-strength-bar';
import { useDispatch, useSelector } from 'react-redux';
import { Field, getFormValues, isValid, reduxForm } from 'redux-form';
import { required, url } from 'redux-form-validators';
import { fetchEmailApps } from 'redux/actions/appActions';
import { saveStepToServer, updateStep } from 'redux/actions/workflowActions';
import {
  getDocuments,
  getEmailApps,
  getWorkflowSteps,
  isActionPending
} from 'redux/selectors';
import { commaSeparatedEmails, validFileName } from 'utils/validators';
import { FieldSelect, ReSelect } from '../components/Select';
import TinyEditor from '../components/TinyEditor';
import useExposedFields from './useExposedFields';
/**
 * Settings component for DocumentForm workflow step type.
 * 1. Defines local state and reference gloabal state using hooks.
 * 2. Defines other hooks.
 * 3. Define side effects using useEffect hooks. Initializes from
 * `props.initialValues` and fetches documents to display in the settings.
 * 4. Define submit function that saves stepData to server.
 * 5. Define Topbar component of the settings pane.
 * 6. Finally Render the settings form.
 */
const IntermediateFormSettingsComponent = (props) => {
  const step = props.step;
  // ##########################################################################
  // 1. Define local state and reference gloabal state using hooks.
  // ##########################################################################

  const [name, setName] = useState(step.name || props.defaultName);
  const [currentTab, setTab] = useState('settings');

  const valid = useSelector((state) => isValid(props.form)(state));

  const emails = useSelector(getEmailApps);
  // We need parentDocument to create exposed fields for this step.
  const documents = useSelector(getDocuments);
  const steps = useSelector(getWorkflowSteps).sort((a, b) => a.order - b.order);
  let parentDocument = { fields: [] };
  if (documents && steps) {
    const docId = steps[0]?.data?.stepData?.documentId;
    if (docId) {
      parentDocument =
        documents.find((doc) => doc.id === steps[0].data.stepData.documentId) ||
        parentDocument;
    }
  }

  const emailOptions = [];
  emails.forEach((e) => {
    if (e.status === 'active') {
      emailOptions.push({ label: e.name, value: e.id, type: e.type });
    }
  });
  emailOptions.push({
    label: 'notifications@docdown.io',
    value: 'notifications@docdown.io',
    type: 'default'
  });
  const pendingUpdate = useSelector((state) =>
    isActionPending(state, 'UPDATE_STEP')
  );

  const currentValues = useSelector((state) =>
    getFormValues(props.form)(state)
  );

  // ##########################################################################
  // 2. Define other hooks.
  // ##########################################################################

  const dispatch = useDispatch();
  const prevData = useExposedFields(step.order, parentDocument, currentValues);
  const [ref, { x }] = useDimensions(true);

  // ##########################################################################
  // 3. Define side effects using useEffect hooks.
  // ##########################################################################

  useEffect(() => {
    props.initialize(props.initialValues);
    dispatch(fetchEmailApps());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Autofill generate filename field from previous document step.
  useEffect(() => {
    const curFilename = currentValues['fileName'];
    if (!curFilename || curFilename.length === 0) {
      const prevStep = steps
        .sort((a, b) => b.order - a.order)
        .filter((s) => s.order < step.order)
        .filter((s) => ['document', 'intermediateForm'].includes(s.type))[0];

      let fileName = prevStep?.data?.stepData?.fileName;
      // Need to do this for backwards compatibility.
      if (fileName) fileName = fileName.replaceAll('{{0.', '{{1.');
      props.change('fileName', fileName);
    }
  }, [steps, currentValues]);

  // ##########################################################################
  // 4. Define submit function that saves stepData to server.
  // ##########################################################################

  function submit(values) {
    function createUpdatedStep(values) {
      const {
        fromEmail,
        toEmail,
        subject,
        message,
        fileName,
        download,
        access,
        formPassword,
        title,
        description,
        selectedFields,
        displayFields,
        displayDocument,
        submissionMessage,
        submitLabel,
        requiredLabel,
        submissionRedirect
      } = values;

      const newStep = {
        ...step,
        name,
        data: {
          ...step.data,
          valid,
          stepData: {
            fileName,
            downloadFile: download.value,
            title,
            description,
            access,
            fromEmail,
            toEmail,
            subject,
            message,
            selectedFields,
            displayFields,
            displayDocument,
            submissionMessage,
            submitLabel,
            requiredLabel,
            submissionRedirect
          },
          stepSettings: {
            fromEmail,
            toEmail,
            fileName,
            access,
            download,
            title,
            subject,
            message,
            description,
            formPassword,
            selectedFields,
            displayFields,
            displayDocument,
            submissionMessage,
            submitLabel,
            requiredLabel,
            submissionRedirect
          },
          exposedFields: [
            ...prevData[step.order],
            { type: 'attachment', name: 'generatedDocument' }
          ]
        }
      };
      return newStep;
    }

    const newStep = createUpdatedStep(values);
    dispatch(updateStep(newStep));
    dispatch(saveStepToServer(newStep));
  }

  // ##########################################################################
  // 5. Define Topbar component of the settings pane.
  // ##########################################################################
  const Topbar = ({ name, setName }) => {
    return (
      <div className="step_bar">
        <nav className="level">
          <div className="level-left step-editable-name">
            <div className="level-item">
              <span className="step_name" title="Click to edit name">
                <Field
                  name="stepName"
                  value={name}
                  component={(props) => (
                    <>
                      <EditableLabel
                        onFocusOut={(text) => {
                          setName(text);
                          props.input.onChange(name);
                        }}
                        inputClassName="input"
                        text={name}
                      />
                    </>
                  )}
                />
              </span>
            </div>
            <div className="level-item">
              <i className="fa fa-edit" />
            </div>
          </div>
          <div className="level-right">
            <button
              type="submit"
              disabled={pristine}
              className={`button is-small is-info ${
                pendingUpdate && pendingUpdate.pending ? 'is-loading' : ''
              }`}
            >
              Save
            </button>
          </div>
        </nav>
      </div>
    );
  };

  // ##########################################################################
  // 6. Render the settings form.
  // ##########################################################################
  const { handleSubmit, pristine } = props;
  let formFields = [];
  if (parentDocument) {
    parentDocument.fields.forEach((f) => {
      if (f) {
        formFields.push({
          value: f.data.name,
          label: f.data.label || f.data.name
        });
      }
    });
  }

  return (
    <div className="step_setting" ref={ref}>
      <form onSubmit={handleSubmit(submit)}>
        <Topbar name={name} setName={setName} />

        <div className="fields">
          <div className="tabs is-fullwidth">
            <ul>
              <li className={currentTab === 'settings' ? 'is-active' : ''}>
                <a onClick={() => setTab('settings')}>
                  <span className="icon is-small">
                    <i className="fas fa-cog" aria-hidden="true"></i>
                  </span>
                  <span>Recepients and Fields</span>
                </a>
              </li>
              <li className={currentTab === 'appearance' ? 'is-active' : ''}>
                <a onClick={() => setTab('appearance')}>
                  <span className="icon is-small">
                    <i className="fas fa-image" aria-hidden="true"></i>
                  </span>
                  <span>Access and Appearance</span>
                </a>
              </li>
              <li className={currentTab === 'submission' ? 'is-active' : ''}>
                <a onClick={() => setTab('submission')}>
                  <span className="icon is-small">
                    <i className="fas fa-save" aria-hidden="true"></i>
                  </span>
                  <span>Form Submission</span>
                </a>
              </li>
            </ul>
          </div>
          <div style={currentTab !== 'settings' ? { display: 'none' } : {}}>
            <label className="label">Form Link</label>
            <p className="help">
              {' '}
              The link to this form will be automatically generated and sent to
              the following email recipient/s after the previous step is
              completed.
            </p>
            <br />
            <label className="label">
              From Email <span className="has-text-grey">(required)</span>
            </label>
            <div className="field is-grouped">
              <div className="control is-expanded">
                <Field
                  name="fromEmail"
                  className="from is-fullwidth"
                  component={ReSelect}
                  validate={[
                    required({ msg: 'Please select an email account to use.' })
                  ]}
                  options={emailOptions}
                  placeholder="Email"
                />
              </div>
              {/* <div className="control">{gmailAuth()}</div> */}
            </div>
            <p className="help pull-up">Which address to use to send the email?</p>

            <label className="label">
              Form Link Recipients{' '}
              <span className="has-text-grey">(required)</span>
            </label>
            <div className="field">
              <Field
                name="toEmail"
                component={FieldSelect}
                width={x}
                multiple=","
                options={prevData}
                typeFilter="field"
                validate={[
                  required({
                    msg: 'Please provide a single or multiple comma separated recipient email address.'
                  }),
                  commaSeparatedEmails
                  //validateMerge
                ]}
                placeholder="Email"
              />
            </div>
            <p className="help pull-up">
              Enter the email address (comma separated if multiple) who should
              recieve the link to this form. You can also use variables from
              previous steps by clicking the "+" button.
            </p>
            <div className="field">
              <label className="label">Email Subject</label>
              <div className="control">
                <Field
                  name="subject"
                  component={FieldSelect}
                  width={x}
                  multiple=" "
                  validate={[required({ msg: 'Please enter email subject' })]}
                  options={prevData}
                  typeFilter="field"
                  placeholder="Subject"
                />
              </div>
            </div>

            <div className="field">
              <label className="label">Email Message</label>
              <div className="control">
                <Field
                  name="message"
                  component={TinyEditor}
                  prevData={prevData}
                />
              </div>
            </div>
            <hr />

            {formFields && (
              <div className="field">
                <label className="label">Fields</label>
                <div className="control">
                  <Field
                    name="displayFields"
                    component={ReSelect}
                    options={[
                      { value: 'all', label: 'Display all fields' },
                      { value: 'selected', label: 'Display selected fields' }
                    ]}
                    validate={[
                      required({
                        msg: 'Please specify if the generated document should be downloaded.'
                      })
                    ]}
                  />
                  {currentValues &&
                    currentValues.displayFields &&
                    currentValues.displayFields.value === 'selected' && (
                      <>
                        <label className="label">Select fields</label>
                        <Field
                          name="selectedFields"
                          component={ReSelect}
                          options={formFields}
                          isMulti
                        />
                      </>
                    )}
                </div>
                <p className="help">Select which fields to show in this form.</p>
              </div>
            )}
          </div>
          <div style={currentTab !== 'appearance' ? { display: 'none' } : {}}>
            <div className="field">
              <div className="control">
                <label className="label">Who can access the form? </label>
                <Field
                  name="access"
                  component={ReSelect}
                  options={[
                    { value: 'public', label: 'Anyone with the form link' },
                    {
                      value: 'auth',
                      label: 'Use a password based authentication'
                    }
                  ]}
                  validate={[]}
                />
              </div>
            </div>

            {currentValues &&
              currentValues.access &&
              currentValues.access.value === 'auth' && (
                <div className="field">
                  <div className="control">
                    <label className="label">
                      Form Password{' '}
                      <span className="has-text-grey">(required)</span>
                    </label>

                    <Field
                      name="formPassword"
                      component={TextField}
                      validate={[
                        required({
                          msg: 'Please specify a password for this form.'
                        })
                      ]}
                    />
                    <PasswordStrengthBar
                      password={currentValues.formPassword}
                    />
                  </div>
                </div>
              )}
            <div className="field">
              <div className="control">
                <label className="label">
                  Display the document next to the form?
                </label>
                <Field
                  name="displayDocument"
                  component={ReSelect}
                  options={[
                    { value: true, label: 'Yes' },
                    {
                      value: false,
                      label: 'No'
                    }
                  ]}
                  validate={[]}
                />
              </div>
              <p className="help">
                If yes, the PDF and the selected fields will be displayed beside
                the form in a split-view mode.
              </p>
            </div>

            <label className="label">Form Title</label>
            <div className="field">
              <div className="control">
                <Field name="title" className="input" component="input" />
              </div>
            </div>

            <label className="label">Form Description</label>
            <div className="field">
              <div className="control">
                <Field name="description" component={TinyEditor} />
              </div>
              <p className="help ">
                This description is shown to the user on the top of the form. It
                can be plain text or HTML.
              </p>
            </div>
            <CollapsibleCard title="Customize Labels" id="additional-options">
              <div className="field">
                <label className="label">Submit Button Label</label>
                <div className="control">
                  <Field
                    name="submitLabel"
                    component={TextField}
                    defaultValue="Submit"
                  />
                </div>
                <p className="help ">
                  Text to be displayed on the submit button. Default is
                  "Submit".
                </p>
              </div>
              <div className="field">
                <label className="label">Required Field Label</label>
                <div className="control">
                  <Field
                    name="requiredLabel"
                    component={TextField}
                    defaultValue="required"
                  />
                </div>
                <p className="help ">
                  Text to be displayed after the label of required fields.
                  Default is "required".
                </p>
              </div>
            </CollapsibleCard>
          </div>
          <div style={currentTab === 'submission' ? {} : { display: 'none' }}>
            <div className="field">
              <label className="label">Message on Submission</label>

              <div className="control">
                <Field name="submissionMessage" component={TinyEditor} />
              </div>
              <p className="help ">
                This message is shown to the user after sumission instead of the
                default message.
              </p>
            </div>
            <div className="field">
              <label className="label">
                Generated File Name{' '}
                <span className="has-text-grey">(required)</span>
              </label>
              <p className="control">
                <Field
                  name="fileName"
                  component={FieldSelect}
                  enableCurrentStep={true}
                  width={x}
                  multiple=" "
                  options={prevData}
                  typeFilter="field"
                  validate={[validFileName]}
                />
              </p>
              <p className="help">
                Alpha numeric characters with hyphens and underscores only. File
                extension ".pdf" will be added automatically.
              </p>
            </div>
            <div className="field">
              <div className="control">
                <label className="label">
                  Show download button after submission?{' '}
                </label>
                <Field
                  name="download"
                  component={ReSelect}
                  options={[
                    { value: true, label: 'Yes' },
                    { value: false, label: 'No' }
                  ]}
                  validate={[]}
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Redirect on Submission</label>
              <div className="control">
                <Field
                  name="submissionRedirect"
                  component={TextField}
                  validate={[
                    url({
                      msg: 'Please provide a valid URL (starting from http or https).',
                      allowBlank: true
                    })
                  ]}
                />
              </div>
              <p className="help ">
                Provide a URL to redirect the user to external page after
                submission instead of displaying a message.
              </p>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

const IFSettings = reduxForm({
  initialValues: {
    displayFields: { value: 'all', label: 'Display all fields' },
    access: {
      value: 'public',
      label: 'Anyone with the form link'
    },
    displayDocument: {
      value: false,
      label: 'No'
    },
    download: { value: false, label: 'No' }
  }
})(IntermediateFormSettingsComponent);
export default IFSettings;
