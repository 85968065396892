import { Annotation } from 'components/DocumentEditor/Annotation/Annotation';
import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import UseLogic from './Preview/Preview.logic';

const Preview = (props) => {
  const [loading, isLoading] = useState(true);
  const [pages, setPages] = useState([]);

  const { LoadPdfAsync } = UseLogic();

  useEffect(() => {
    async function getPDF() {
      let pdfUrl;

      if (props.workflowRunId) {
        pdfUrl = `${process.env.REACT_APP_SERVER_BASE_URL}/resource/file/${props.workflowRunId}?mode=blob`;

      } else {
        pdfUrl = props.pdfURL;
      }

      var pages = await LoadPdfAsync(pdfUrl);
      setPages(pages);
    }
    getPDF();
  }, []);

  useEffect(() => {
    if (pages?.length > 0) {
      isLoading(false);
    }
  }, [pages?.length]);


  const Loading = () => (
    <div className="document_preview_loading is-centered">
      <Loader type="Oval" color="#ccc" height={30} width={30} />
    </div>
  );

  let showFields = [];
  if (props.selectedFields) {
    showFields = props.selectedFields.map((f) => f.value);
  }

  const renderDocument = () => {
    return (
      <>
        {pages?.map((page, i) => {
          let pageFields = props.fields.filter(
            (f) => f.geometry.page === i + 1 && !f.data.hidden
          );

          return (
            <Annotation
              documentId={123}
              annotations={pageFields.filter((f) => {
                if (
                  props.displayFields &&
                  props.displayFields.value === 'selected'
                ) {
                  return showFields.includes(f.data.name);
                } else return true;
              })}
              src={page}
              displayMode={true}
              currentPage={i + 1}
              // These are dummy props to make Annotation work here.
              isMouseHovering={{ innerRef: () => { } }}
              relativeMousePos={{ x: 0, y: 0, innerRef: () => { } }}
            />
          );
        })}
      </>
    );
  };

  return (
    <div className="public_document_preview  is-centered">
      {loading ? <Loading /> : renderDocument()}
    </div>
  );
};

export default Preview;
