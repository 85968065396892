import React from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  activateWorkflow,
  deactivateWorkflow
} from 'redux/actions/workflowActions';


const WorkflowToggle = ({ data }) => {
  const dispatch = useDispatch();

  let valid;
  if (data?.steps?.length >= 1) {
    valid = data.steps.every(step => !!step.data?.valid);
  }
  else {
    valid = false;
  }

  function toggleActivation(e) {
    e.preventDefault();
    if (!valid) return;
    if (data.active) {
      dispatch(deactivateWorkflow({ id: data.id, name: data.name }));
      toast.info('Workflow deactivated');
    } else {
      dispatch(activateWorkflow({ id: data.id, name: data.name }));
      toast.info('Workflow activated');
    }
  }

  return (
    <div>
      <input
        id="activateWorkflow"
        type="checkbox"
        name="activateWorkflow"
        className="switch is-rounded is-success"
        checked={data.active}
        disabled={!valid}
      />
      <label
        onClick={toggleActivation}
        className="switch_label"
        htmlFor="activateWorkflow"
      ></label>
    </div>
  );
};

export default WorkflowToggle;
