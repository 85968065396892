import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { getDocument, getDocuments } from 'redux/selectors';
import { fetchDocuments, saveDocumentToServer } from '../../redux/actions';
import ReplaceDocUploader from './ReplaceDocUploader';

const DocumentSettings = (props) => {
  const dispatch = useDispatch();
  const [showReplace, setShowReplace] = useState(true);

  const document = useSelector((state) =>
    getDocument(state, props.document.id)
  );
  const documents = useSelector(getDocuments);
  useEffect(() => {
    dispatch(fetchDocuments());
    props.initialize(document);
  }, []);

  const documentNameValid = (value) => {
    const documentNames = documents.map((d) => d.name.toLowerCase()) || [];
    const lowerName = value ? value.toLowerCase() : '';
    if (documentNames.includes(lowerName)) {
      return false;
    }
    return true;
  };

  const { handleSubmit, pristine, submitting, error } = props;
  return (
    <>
      <form
        onSubmit={handleSubmit(async (values) => {
          function sleep(ms) {
            return new Promise((resolve) => setTimeout(resolve, ms));
          }
          if (!documentNameValid(values.name)) {
            throw new SubmissionError({
              _error: 'Document with this name already exists!'
            });
          }
          dispatch(saveDocumentToServer({ ...document, name: values.name }));
          await sleep(500);
          dispatch(fetchDocuments());
        })}
      >
        <div className="workflow_settings">
          <div className="field">
            <label className="label">Name</label>
            <div className="control">
              <Field name="name" component="input" className="input is-small" />
            </div>
          </div>
          {error && <p className="help is-danger">{error}</p>}
          <button
            disabled={pristine}
            className={`button font-14px ${submitting && 'is-loading'}`}
            type="submit"
          >
            Save
          </button>
          <hr />
          <div className="field">
            <label className="label">Replace Document</label>
            <div className="control">
              {showReplace && (
                <ReplaceDocUploader
                  documentId={props.document.id}
                  onSuccess={props.onDocumentReplace}
                />
              )}
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
export default reduxForm({ form: 'document_settings' })(DocumentSettings);
