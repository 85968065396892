import React, { Component } from 'react';

class BIUButtons extends Component {

  state = { toggle: '000' }
  componentDidMount() {
    this.setState({ toggle: this.props.input.value })
  }
  changeValue(value, index) {
    const replace = (string, letter, i) => string.substring(0, i) + letter + string.substring(i + 1);
    let newstring = replace(value, value[index] === '1' ? '0' : '1', index);
    return newstring;
  }
  render() {
    let { value, onChange } = this.props.input;
    return (
      <div className='field is-horizontal'>
        <div className="field-label is-small">
          <label className="label">Style</label>
        </div>
        <div className="field-body">
          <div className="field has-addons is-fullwidth">
            <p className="control">
              <button
                type="button"
                className={`button is-small ${this.state.toggle[0] === '1' ? 'is-active' : ''}`}
                onClick={e => { let toggle = this.changeValue(value, 0); this.setState({ toggle }); onChange(toggle) }}>
                <span className="icon is-small">
                  <i className="fas fa-bold"></i>
                </span>
              </button>
            </p>
            <p className="control">
              <button
                type="button"
                className={`button is-small ${this.state.toggle[1] === '1' ? 'is-active' : ''}`}
                onClick={e => { let toggle = this.changeValue(value, 1); this.setState({ toggle }); onChange(toggle) }}>
                <span className="icon is-small">
                  <i className="fas fa-italic"></i>
                </span>
              </button>
            </p>
            <p className="control">
              <button
                type="button"
                className={`button is-small ${this.state.toggle[2] === '1' ? 'is-active' : ''}`}
                onClick={e => { let toggle = this.changeValue(value, 2); this.setState({ toggle }); onChange(toggle) }}>
                <span className="icon is-small">
                  <i className="fas fa-text-height"></i>
                </span>
              </button>
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default BIUButtons;