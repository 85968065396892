import PinchZoomPan from '@docdown/react-responsive-pinch-zoom-pan';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from 'redux/actions';
import {
  getActiveField,
  getCurrentPage,
  getCurrentSelector
} from 'redux/selectors';
import { actionLog } from 'redux/store';
import { fieldDefaults } from 'utils/fieldDefaults.js';
import Rectangle from './components/Rectangle';
import { Container, Img, Items, Target } from './components/styledComponents';
import { defaultProps, propTypes } from './defaultProps';
import { PointSelector, RectangleSelector } from './selectors/index';
import compose from './utils/compose';
import isMouseHovering from './utils/isMouseHovering';
import withRelativeMousePos from './utils/withRelativeMousePos';

class Annotation extends Component {
  state = { currentSelection: {}, scale: 1, loaded: false };

  static propTypes = propTypes;
  static defaultProps = defaultProps;

  targetRef = React.createRef();

  containerRef = React.createRef();

  setInnerRef = (el) => {
    this.container = el;
    this.props.relativeMousePos.innerRef(el);
    this.props.innerRef(el);
    // Set isMouseHovering ref
    this.props.isMouseHovering.innerRef(el);
  };

  getSelectorByType = (type) => {
    return this.props.selectors.find((s) => s.TYPE === type);
  };

  getTopAnnotationAt = (x, y) => {
    const { annotations } = this.props;
    if (!this.container) return;

    const intersections = annotations
      .filter(
        (annotation) => annotation.geometry.page === this.props.currentPage
      )
      .filter((annotation) => annotation.data.type !== 'group')
      .map((annotation) => {
        return RectangleSelector.intersects({ x, y }, annotation.geometry)
          ? annotation
          : false;
      })
      .filter((a) => !!a)
      .sort((a, b) => {
        return (
          RectangleSelector.area(a.geometry) -
          RectangleSelector.area(b.geometry)
        );
      });
    return intersections[0];
  };

  onTargetMouseMove = (e) => {
    this.props.relativeMousePos.onMouseMove(e);
    this.onMouseMove(e);
  };

  onTargetMouseLeave = (e) => {
    this.props.relativeMousePos.onMouseLeave(e);
  };

  onMouseUp = (e) => this.callSelectorMethod('onMouseUp', e);
  onMouseDown = (e) => this.callSelectorMethod('onMouseDown', e);
  onMouseMove = (e) => this.callSelectorMethod('onMouseMove', e);
  onClick = (e) => this.callSelectorMethod('onClick', e);

  onSubmit = () => {
    const annotation = this.state.currentSelection;
    let field = {
      ...annotation,
      geometry: { ...annotation.geometry, page: this.props.currentPage },
      data: { ...annotation.data, type: this.props.currentSelector }
    };
    field = {
      ...field,
      data: { ...field.data, ...fieldDefaults(field.data.type, field) }
    };
    this.props.addField(field);
    this.setState({ currentSelection: {} });
  };
  onChange = (annotation) => {
    this.setState({ currentSelection: annotation });
  };

  callSelectorMethod = (methodName, e) => {
    if (this.props.disableAnnotation) {
      return;
    }

    if (!!this.props[methodName]) {
      this.props[methodName](e);
    } else {
      const selector = this.getSelectorByType(this.props.type);
      if (selector && selector.methods[methodName]) {
        const value = selector.methods[methodName](
          this.state.currentSelection,
          e
        );

        if (typeof value === 'undefined') {
          if (process.env.NODE_ENV !== 'production') {
            console.error(`
              ${methodName} of selector type ${this.props.type} returned undefined.
              Make sure to explicitly return the previous state
            `);
          }
        } else {
          this.onChange(value);
        }
      }
    }
  };

  shouldAnnotationBeActive = (annotation, top) => {
    if (this.props.activeField === annotation.data.id) {
      return true;
    } else if (this.props.currentPage === annotation.geometry.page) {
      if (this.props.activeAnnotations) {
        const isActive = !!this.props.activeAnnotations.find((active) =>
          this.props.activeAnnotationComparator(annotation, active)
        );

        return isActive || top === annotation;
      } else {
        return top === annotation;
      }
    } else {
      return false;
    }
  };
  clickCallback = (topAnnotation) => {
    if (topAnnotation !== undefined) {
      this.props.setActiveField(topAnnotation);
    } else {
      // Dont set activeField as null if the last action was ADD_FIELD
      const actions = actionLog.getLog().actions;
      if (
        !(actions[actions.length - 1].action.type === 'ADD_FIELD') &&
        this.props.activeField
      ) {
        this.props.setActiveField(null);
      }
    }
  };

  renderHighlight = ({
    key,
    annotation,
    active,
    currentPage,
    onClick,
    containerRef,
    disabled
  }) => {
    const cW = containerRef.current ? containerRef.current.clientWidth : null;
    const cH = containerRef.current ? containerRef.current.clientHeight : null;
    switch (annotation.geometry.type) {
      case RectangleSelector.TYPE:
        if (annotation.geometry.page === currentPage) {
          return (
            <Rectangle
              key={key}
              annotation={annotation}
              active={active}
              onClick={onClick}
              containerHeight={cH}
              containerWidth={cW}
              containerRef={containerRef}
              documentId={this.props.documentId}
              disabled={disabled}
              disableDragging={this.props.isImageMoveable}
              disableResizing={this.props.isImageMoveable}
              scale={this.state.scale}
            />
          );
        } else {
          return null;
        }
      case PointSelector.TYPE:
        return null; //<Point key={key} annotation={annotation} active={active} />;
      default:
        return null;
    }
  };

  render() {
    const { props } = this;
    const {
      renderContent,
      renderSelector,
      renderEditor,
      renderOverlay,
      allowTouch
    } = props;

    const topAnnotationAtMouse = this.getTopAnnotationAt(
      this.props.relativeMousePos.x,
      this.props.relativeMousePos.y
    );

    return (
      <Container
        ref={this.containerRef}
        style={props.style}
        onClick={
          props.displayMode || props.isImageMoveable
            ? null
            : (e) => {
                this.clickCallback(topAnnotationAtMouse);
              }
        }
        // innerRef={isMouseHovering.innerRef}
        onMouseLeave={
          props.displayMode || props.isImageMoveable
            ? null
            : this.onTargetMouseLeave
        }
        allowTouch={allowTouch}
      >
        <PinchZoomPan
          onScaleChange={(scale) => {
            this.setState({ scale });
            if (props.onScaleChange) {
              this.props.onScaleChange(scale);
            }
          }}
          ref={props.getPinchZoomRef}
          isImageMoveable={props.isImageMoveable}
          zoomOnScroll={false}
          minScale={1}
          maxScale={6}
          zoomButtons={false}
          initialScale={1}
        >
          <div>
            <Img
              className={props.className}
              style={props.style}
              alt={props.alt}
              src={props.src}
              draggable={false}
              onLoad={() => {
                this.setState({ loaded: true });
              }}
              // Add ref here instead.
              ref={this.setInnerRef}
            />

            <Items>
              {this.state.loaded &&
                props.annotations.map((annotation) =>
                  this.renderHighlight({
                    key: annotation.data.id,
                    annotation,
                    active: this.shouldAnnotationBeActive(
                      annotation,
                      topAnnotationAtMouse
                    ),
                    currentPage: props.currentPage,
                    containerRef: this.containerRef,
                    disabled: props.displayMode
                  })
                )}
              {!props.disableSelector &&
                this.state.currentSelection &&
                this.state.currentSelection.geometry &&
                renderSelector({
                  annotation: this.state.currentSelection,
                  currentPage: props.currentPage
                })}
            </Items>
            {!this.props.isImageMoveable && (
              <Target
                innerRef={this.targetRef}
                onClick={
                  props.displayMode || props.isImageMoveable
                    ? null
                    : this.onClick
                }
                onMouseUp={
                  props.displayMode || props.isImageMoveable
                    ? null
                    : this.onMouseUp
                }
                onMouseDown={
                  props.displayMode || props.isImageMoveable
                    ? null
                    : this.onMouseDown
                }
                onMouseMove={
                  props.displayMode || props.isImageMoveable
                    ? null
                    : this.onTargetMouseMove
                }
              />
            )}
          </div>
        </PinchZoomPan>

        {!props.disableOverlay &&
          renderOverlay({
            type: props.type,
            annotation: this.state.currentSelection
          })}
        {props.annotations.map(
          (annotation) =>
            this.shouldAnnotationBeActive(annotation, topAnnotationAtMouse) &&
            renderContent({
              key: annotation.data.id,
              annotation: annotation
            })
        )}
        {!props.disableEditor &&
          this.state.currentSelection &&
          this.state.currentSelection.selection &&
          this.state.currentSelection.selection.showEditor &&
          renderEditor({
            annotation: this.state.currentSelection,
            onChange: this.onChange,
            onSubmit: this.onSubmit
          })}
        <div>{props.children}</div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  currentPage: getCurrentPage(state),
  activeField: getActiveField(state),
  currentSelector: getCurrentSelector(state)
});

const mapActionToProps = {
  setActiveField: actions.setActiveField,
  addField: actions.addField,
  updateFieldsToServer: actions.updateFieldsToServer
};

export default compose(
  isMouseHovering(),
  withRelativeMousePos()
)(connect(mapStateToProps, mapActionToProps)(Annotation));
export { Annotation };
