import React, { useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import ReactTooltip from 'react-tooltip';

const TextField = (props) => {
  const { value, onChange } = props.input;
  const [valid, setValid] = useState(true);
  const [empty, setEmpty] = useState(value ? false : true);
  const [illegal, setIllegal] = useState(false);
  const [max, setMax] = useState(false);
  const handleChange = (e) => {
    if (props.max) {
      if (e.target.value.length > props.max) {
        setMax(true);
        return;
      } else setMax(false);
    }
    if (props.required) {
      if (e.target.value === '') {
        setEmpty(true);
        return;
      } else {
        setEmpty(false);
      }
    }
    if (props.illegal) {
      if (props.illegal.includes(e.target.value)) {
        setIllegal(true);
        return;
      } else {
        setIllegal(false);
      }
    }
    if (props.alphaNumeric) {
      if (e.target.value && !e.target.value.match(/^\w+$/)) {
        setValid(false);
        return;
        // console.log('Inavlid field name')
      } else {
        setValid(true);
      }
    }
    onChange(e.target.value);
  };

  let className = `input is-small ${valid ? '' : 'is-danger'}`;
  if (props.required) {
    if (empty) className = `${className} is-danger`;
  }

  return (
    <>
      <div className="field is-horizontal">
        <div className="field-label is-small">
          <label className="label">{props.label}</label>
        </div>
        <div className="field-body">
          <div className="field">
            <p className="control">
              <DebounceInput
                debounceTimeout={500}
                className={className}
                value={value}
                type={props.type}
                data-validation={props.type}
                step={props.step}
                placeholder={props.placeholder}
                onBlur={(e) => {
                  setValid(true);
                }}
                onChange={handleChange}
              />
              {props.doc && (
                <p className="help doc">
                  <a href={props.doc.url} target="_blank">
                    <i className="fas fa-info-circle" />
                    {props.doc.label}
                  </a>
                </p>
              )}
              {props.tooltip && (
                <i className="fas fa-question-circle" data-tip={props.tooltip}></i>
              )}
            </p>
            {props.required && empty && (
              <p className="help is-danger">{props.label} cannot be empty!</p>
            )}
            {props.alphaNumeric && !valid && (
              <p className="help is-danger">
                {props.label} can have only alphabets and numbers!
              </p>
            )}
            {props.illegal && illegal && (
              <p className="help is-danger">
                This value is already used as {props.label}
              </p>
            )}
            {props.max && max && (
              <p className="help is-danger">
                {props.label} cannot have length longer than {props.max}!
              </p>
            )}
          </div>
        </div>
      </div>

      <ReactTooltip
        className="field_tooltip"
        place="left"
        effect="solid"
        offset={{ top: 0 }}
        multiline={true}
      />
    </>
  );
};

export default TextField;
