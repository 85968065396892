import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'
import Loader from 'react-loader-spinner'
import server from 'api/server'
import { fetchUser } from 'redux/actions'

export default (props) => {
  const dispatch = useDispatch()
  const history = useHistory();
  const token = props.match.params.token
  if (!token) {
    return <Redirect to='/login' />
  }
  const [msg, setMsg] = useState('Confirming your email...')
  useEffect(() => {
    function sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
    async function confirm() {
      try {
        await server.post('/auth/confirm', { token })
        dispatch(fetchUser())
        setMsg('Email confirmed, redirecting...')
        await sleep(1500)
        history.push('/login?confirmation=true')
      } catch (error) {
        await sleep(500)
        history.push('/login?confirmation=false')
      }
    }
    confirm()
  })
  return (
    <div className='auth_loader_container'>
      <Loader
        type="Oval"
        color="#ccc"
        height={30}
        width={30} />
      <span>{msg}</span>
    </div>
  )
}