import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  getCurrentStep,
  getWorkflowSteps,
  getWorkflows
} from '../../../redux/selectors';
import {
  setCurrentStep,
  addStep,
  fetchWorkflowSteps
} from '../../../redux/actions/workflowActions';
import { getIcon } from 'components/WorkflowEditor/steps/availableSteps';

const NewStep = (props) => {
  const dispatch = useDispatch();
  const workflowSteps = useSelector(getWorkflowSteps);

  const onNewStep = (type) => {
    const step = {
      workflowId: props.workflowId,
      name: '',
      type,
      data: {},
      order: workflowSteps.length + 1
    };
    dispatch(addStep(step));
  };

  const renderStepCard = (step) => {
    return (
      <div className="column">
        <div
          onClick={(e) => {
            onNewStep(step.type);
          }}
          className="card"
        >
          <div className="card-content">
            <div className="content has-text-centered">{getIcon(step.type)}</div>
            <div className="content has-text-centered card_step_name">
              {step.name}
            </div>
            <div className="content has-text-centered">
              <span className="tag is-light">{step.category}</span>
            </div>
            <div className="content has-text-centered">{step.description}</div>
          </div>
        </div>
      </div>
    );
  };

  const Topbar = ({ name, setName }) => {
    return (
      <div className="step_bar">
        <nav className="level">
          <div className="level-left">
            <div className="level-item">
              <span className="step_name">
                New Workflow{' '}
                {workflowSteps && workflowSteps.length === 0
                  ? 'Trigger'
                  : 'Action'}
              </span>
            </div>
          </div>
          <div className="level-right"></div>
        </nav>
      </div>
    );
  };
  return (
    <div className="step_setting new_step">
      <Topbar />
      <div className="columns is-multiline">
        {props.availableSteps.map((step) => {
          if (step.enabled) {
            if (workflowSteps.length === 0) {
              if (step.category === 'Trigger') return renderStepCard(step);
            } else {
              if (step.category === 'Action') return renderStepCard(step);
            }
          }
        })}
      </div>
      {workflowSteps.length > 0 && (
        <div className="column full-width-card">
          <div className="card long-card">
            <a
              href="https://zapier.com/apps/docdown/integrations"
              target="_blank"
            >
              <div className="level">
                <div className="level-left">
                  <div className="level-item">{getIcon('zapier')}</div>
                </div>
                <div className="level-right">
                  <div className="level-item">
                    <strong>Zapier + Docdown</strong>
                  </div>
                  <div className="level-item">
                    <span>
                      Use our public beta Zapier integration to connect this
                      workflow to <strong>2000+ apps!</strong>
                    </span>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewStep;
