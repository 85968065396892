/* eslint-disable array-callback-return */
import { Parser } from 'expr-eval';
import * as moment from 'moment';

export const parseQuery = (query, context) => {
  // For each field's query, pass it the context i.e. the values
  // of the current fields. Display/Hide the field according to returned value.

  // There's two types of operators:
  // unary: operator.apply(field) returns boolean
  // binary: operator.apply(field, value) returns boolean

  const operators = {
    null: { apply: (field) => (field ? false : true), type: 'unary' },
    notNull: { apply: (field) => (field ? true : false), type: 'unary' },
    // Disable is because the user may input both text or number for "=" operator.
    // eslint-disable-next-line eqeqeq
    '=': { apply: (field, value) => field == value, type: 'binary' },
    '!=': { apply: (field, value) => field !== value, type: 'binary' },
    '<': {
      apply: (field, value) => parseFloat(field) < parseFloat(value),
      type: 'binary'
    },
    '>': {
      apply: (field, value) => parseFloat(field) > parseFloat(value),
      type: 'binary'
    },
    '<=': {
      apply: (field, value) => parseFloat(field) <= parseFloat(value),
      type: 'binary'
    },
    '>=': {
      apply: (field, value) => parseFloat(field) >= parseFloat(value),
      type: 'binary'
    },
    contains: {
      apply: (field, value) => field.includes(value),
      type: 'binary'
    },
    beginsWith: {
      apply: (field, value) => field.startsWith(value),
      type: 'binary'
    },
    endsWith: {
      apply: (field, value) => field.endsWith(value),
      type: 'binary'
    },
    doesNotContain: {
      apply: (field, value) => !field.includes(value),
      type: 'binary'
    },
    doesNotBeginWith: {
      apply: (field, value) => !field.startsWith(value),
      type: 'binary'
    },
    doesNotEndWith: {
      apply: (field, value) => !field.endsWith(value),
      type: 'binary'
    },
    isSame: {
      type: 'binary',
      apply: (field, value) => {
        const parsedField = moment(field);
        const parsedValue = moment(value);
        if (!parsedField.isValid() || !parsedValue.isValid()) return false;
        else return parsedField.isSame(parsedValue, 'day');
      }
    },
    isAfter: {
      type: 'binary',
      apply: (field, value) => {
        const parsedField = moment(field);
        const parsedValue = moment(value);
        if (!parsedField.isValid() || !parsedValue.isValid()) return false;
        else return parsedField.isAfter(parsedValue, 'day');
      }
    },
    isBefore: {
      type: 'binary',
      apply: (field, value) => {
        const parsedField = moment(field);
        const parsedValue = moment(value);
        if (!parsedField.isValid() || !parsedValue.isValid()) return false;
        else return parsedField.isBefore(parsedValue, 'day');
      }
    }
  };

  // Each rule has an operator, field and a value.
  // Collect result of each of the rule in the current context.
  let results = [true];
  query.rules.map((rule) => {
    let operator = operators[rule.operator];
    let field = context[rule.field] ? context[rule.field] : '';
    let value;
    try {
      const parser = new Parser();
      const expr = parser.parse(rule.value);
      value = moment(rule.value).isValid()
        ? rule.value
        : expr.evaluate(context);
    } catch {
      value = rule.value;
    }

    let result =
      operator.type === 'unary'
        ? operator.apply(field)
        : operator.apply(field, value);
    results.push(result);
  });
  // Combine results with query.combinator
  return query.combinator === 'and'
    ? results.reduce((a, b) => a && b)
    : results.reduce((a, b) => a || b);
};

export const evaluateExpression = (formula, context) => {
  if (!formula) {
    return undefined;
  }
  let warning = null;
  let finalResult = null;
  try {
    const parser = new Parser();
    const expr = parser.parse(formula);
    const valid = expr.variables().every((variable) => variable in context);
    if (!valid) {
      warning = 'Field not found or expression invalid!';
    }
    const result = expr.evaluate(context);
    if (isNaN(result)) {
      warning = 'Invalid value!';
    } else {
      finalResult = result;
    }
    return finalResult;
  } catch (e) {
    warning = 'Invalid expression!';
    return null;
  }
};

const evaluateLogic = (formula, context) => {
  let visible = true;
  let expressionValue;
  let warning;

  context.dateOfSubmission = moment().format('YYYY-MM-DD');
  context['true'] = true;
  context['false'] = false;
  const queryMatch = parseQuery(formula.query, context);

  if (formula.type === 'expression') {
    if (queryMatch) {
      const parser = new Parser();
      let expr, valid;
      try {
        expr = parser.parse(formula.expression);
        valid = expr.variables().every((variable) => variable in context);
      } catch (err) {
        console.log('Error parsing expression: ', formula);
        valid = false;
      }
      if (valid) {
        try {
          expressionValue = expr.evaluate(context);
          if (isNaN(expressionValue)) {
            warning = 'Invalid value!';
          }
        } catch (e) {
          warning = 'Invalid expression!';
        }
      } else {
        expressionValue = formula.expression;
        warning = 'Field not found!';
      }
    }
  } else {
    if (queryMatch) visible = formula.type === 'show' ? true : false;
    else visible = formula.type === 'show' ? false : true;
  }
  return { visible, expressionValue, warning };
};

export const evaluateConditionalLogic = (formulas, context) => {
  //API: https://github.com/silentmatt/expr-eval#readme
  if (!formulas) {
    return undefined;
  }
  const results = [];
  formulas.forEach((formula, index) => {
    results.push(evaluateLogic(formula, context));
  });

  // How to pick result if many condition applies?
  // const result = results[0] ? results[0] : undefined;
  // Strategy 1: Combine visible with OR
  let visible = true;
  let expressionValue = null;
  results.forEach((result, index) => {
    if (formulas[index].type === 'show' || formulas[index].type === 'hide')
      visible = visible && result.visible;
    expressionValue = expressionValue
      ? expressionValue
      : result.expressionValue;
  });

  return [visible, expressionValue];
};
