export const authFreeRoutes = [
  '/trigger/',
  '/login',
  '/signup',
  '/reset',
  '/confirm',
  '/logout',
  '/appsumo',
  '/lifetime'
];

export function shouldRedirectLogin() {
  let redirect = true;
  authFreeRoutes.forEach((route) => {
    if (window.location.pathname.includes(route)) {
      redirect = false;
    }
  });
  if (redirect) {
    window.location.href = `${process.env.REACT_APP_BASE_URL}/login`;
  }
}
