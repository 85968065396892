import React from 'react';
import { connect } from 'react-redux';
import { setEditorMode } from 'redux/actions';
import { getEditorMode } from 'redux/selectors';

const EditorOptions = (props) => {
  const value = props.editorMode;
  const onChange = props.setEditorMode;
  const id = props.id ?? "editor-options";
  
  return (
    <div id={id} className="field has-addons is-fullwidth">
      <p className="control">
        <button
          id={`${id}-editor-button`}
          type="button"
          className={`button is-small ${value === 'editor' ? 'is-active' : ''}`}
          onClick={(e) => {
            onChange('editor');
          }}
        >
          <span className="icon is-small">
            <i className="far fa-edit"></i>
          </span>
          <span>Editor</span>
        </button>
      </p>

      <p className="control">
        <button
          id={`${id}-form-button`}
          type="button"
          className={`button is-small ${value === 'form' ? 'is-active' : ''}`}
          onClick={(e) => {
            onChange('form');
          }}
        >
          <span className="icon is-small">
            <i className="far fa-file-pdf"></i>
          </span>
          <span>Form</span>
        </button>
      </p>
    </div>
  );
};

const mapStateToProps = (state) => ({
  editorMode: getEditorMode(state)
});

export default connect(mapStateToProps, { setEditorMode })(EditorOptions);
