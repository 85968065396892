import tooltips from 'components/DocumentEditor/tooltips';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import {
  Checkbox,
  Conditional,
  DeleteField,
  Dropdown,
  Slider,
  TextField
} from './components';
import FieldTypeDropdown from './components/FieldTypeDropdown';

const ImageFieldForm = (props) => {
  const values = useSelector((state) => getFormValues(props.form)(state));
  useEffect(() => {
    props.onChange(values);
  }, [values]);
  const fieldNames = [
    ...props.fields.map((f) => f.data.name),
    'generatedDocument'
  ];
  function renderData() {
    return (
      <React.Fragment>
        <FieldTypeDropdown
          type={props.field.data.type}
          onFieldChange={props.onFieldChange}
        />
        <Field
          component={TextField}
          alphaNumeric
          tooltip={tooltips.name}
          required
          // illegal={fieldNames}
          max={64}
          label="Name"
          name="name"
          type="text"
          placeholder="Field Name"
        />
        <Field
          label="Label"
          component={TextField}
          name="label"
          tooltip={tooltips.label}
          type="text"
          placeholder="Form Label"
        />
        <Field
          label="Description"
          component={TextField}
          tooltip={tooltips.description}
          name="description"
          type="text"
          placeholder="Form Description"
        />
        <Field
          label="Fill Type"
          tooltip={tooltips['fit-mode']}
          options={[
            { value: 'fit', label: 'Fit' },
            { value: 'cover', label: 'Cover' }
          ]}
          component={Dropdown}
          name="fitMode"
        />
        <Field
          label="Required"
          component={Checkbox}
          name="required"
          tooltip={tooltips.required}
        />
        <Field
          label="Hide in Document"
          component={Checkbox}
          name="hidden"
          tooltip={tooltips.hidden}
        />
        <Field
          label="Hide in Form"
          component={Checkbox}
          name="hiddenForm"
          tooltip={tooltips.hiddenForm}
        />

        <div className="is-divider" data-content="POSITION"></div>
        <Field
          component={Slider}
          step={0.1}
          min={0}
          max={100}
          name="x"
          label="X"
        />
        <Field
          component={Slider}
          step={0.1}
          min={0}
          max={100}
          name="y"
          label="Y"
        />
        <Field
          component={Slider}
          step={0.1}
          min={0}
          max={100}
          name="width"
          label="Width"
        />
        <Field
          component={Slider}
          step={0.1}
          min={0}
          max={100}
          name="height"
          label="Height"
        />
        <div className="is-divider"></div>
        <DeleteField onDelete={props.onDelete} />
      </React.Fragment>
    );
  }
  const renderAppearance = () => {
    return (
      <React.Fragment>
        <Field
          component={Conditional}
          tooltip={tooltips['condition-formula']}
          field={props.field}
          fields={props.fields}
          name="conditionalFormula"
        />
      </React.Fragment>
    );
  };
  return (
    <div className="text_field_editor" onClick={props.onSelect}>
      <form
        onKeyPress={(e) => {
          e.key === 'Enter' && e.preventDefault();
        }}
      >
        {props.view === 'data' ? renderData() : renderAppearance()}
      </form>
    </div>
  );
};

export default reduxForm()(ImageFieldForm);
