import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getLeftPanel } from '../../../redux/selectors';
import PanelSelector from './PanelSelector';
import ThumbnailsPanel from './ThumbnailsPanel';
import FieldsListPanel from './FieldsListPanel';
import './LeftSidebar.scss';


class LeftSidebar extends Component {

  renderPanel() {
    if (this.props.leftPanel === 'pages') {
      return <ThumbnailsPanel />
    } else if (this.props.leftPanel === 'fields') {
      return <FieldsListPanel documentId={this.props.documentId} />
    }
  }
  render() {
    return (
      <div className='left_sidebar column has-background-white-ter'>
        <div className='container'>
          <PanelSelector />
          {this.renderPanel()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  leftPanel: getLeftPanel(state)
})
export default connect(mapStateToProps)(LeftSidebar);