import { throttle } from 'lodash';
import React, { Component } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import Sticky from 'react-sticky-el';
import { documentContainerHeight } from '../../../constants/js/documentEditor';
import * as actions from '../../../redux/actions';
import * as selectors from '../../../redux/selectors';
import Annotation from '../Annotation';
import './Document.scss';

const DocumentFooter = (props) => {
  const dispatch = useDispatch();
  const page = useSelector((state) => selectors.getCurrentPage(state));
  const pages = useSelector((state) => selectors.getRenderedPages(state));

  const numPages = pages.length;

  return (
    <nav className="level document_footer">
      <div
        className="level-left"
        onClick={(e) => {
          dispatch(actions.setCurrentPage(page - 1 <= 1 ? 1 : page - 1));
        }}
      >
        <div className="level-item">
          <span className="icon is-medium" style={{ cursor: 'pointer' }}>
            <i className="far fa-lg fa-arrow-alt-circle-left"></i>
          </span>
        </div>
      </div>
      <div className="level-center">
        <div className="level-item">
          {page} / {numPages}
        </div>
      </div>

      <div
        className="level-right"
        onClick={(e) => {
          dispatch(
            actions.setCurrentPage(page + 1 >= numPages ? numPages : page + 1)
          );
        }}
      >
        <div className="level-item">
          <span className="icon is-medium" style={{ cursor: 'pointer' }}>
            <i className="far fa-lg fa-arrow-alt-circle-right"></i>
          </span>
        </div>
      </div>
    </nav>
  );
};

class Document extends Component {
  constructor(props) {
    super(props);
    this.container = React.createRef();
    this.documentContainer = React.createRef();

    this.wheelNavigate = throttle(this.wheelNavigate.bind(this), 600, {
      trailing: false
    });
  }

  componentDidMount() {
    // this.container.current.addEventListener('wheel', this.wheelNavigate, { passive: false });
  }

  wheelNavigate = (e) => {
    const currentPage = this.props.currentPage;
    const totalPages = this.props.numPages;
    const { scrollTop, scrollHeight, clientHeight } = this.container.current;
    const reachedTop = scrollTop === 0;
    const reachedBottom =
      Math.abs(scrollTop + clientHeight - scrollHeight) <= 1;
    // console.log(e.deltaY, reachedTop, reachedBottom, currentPage, totalPages);

    if (e.deltaY < 0 && reachedTop && currentPage > 1) {
      this.props.setCurrentPage(currentPage - 1);
    } else if (e.deltaY > 0 && reachedBottom && currentPage < totalPages) {
      this.props.setCurrentPage(currentPage + 1);
    }
  };

  renderPages() {
    const pageNum = this.props.pages[this.props.currentPage - 1];
    if (pageNum) {
      return (
        <img
          alt={pageNum}
          className="page"
          height={documentContainerHeight}
          src={pageNum}
        />
      );
    } else {
      return (
        <progress className="progress is-medium is-dark" max="100">
          45%
        </progress>
      );
    }
  }

  render() {
    return (
      <>
        <div
          ref={this.container}
          className="column is-three-fifths document_container"
        >
          <Sticky scrollElement=".document_container">
            {this.props.actionBar}
          </Sticky>
          <div className="document container" ref={this.documentContainer}>
            <Annotation
              getPinchZoomRef={this.props.getPinchZoomRef}
              isImageMoveable={this.props.isImageMoveable}
              documentId={this.props.documentId}
              annotations={this.props.fields}
              src={this.props.pages[this.props.currentPage - 1]}
              currentPage={this.props.currentPage}
              documentRef={this.documentContainer}
              onScaleChange={this.props.handleScaleChange}
            ></Annotation>
            <span className="pagenumber">{`${this.props.currentPage}/${this.props.numPages}`}</span>
          </div>
          {/* <Sticky mode="bottom" posi scrollElement=".document_container"> */}
          {this.props.displayFooter && <DocumentFooter />}
          {/* </Sticky> */}
        </div>
      </>
    );
  }
}

const mapSelectorsToProps = (state) => ({
  currentPage: selectors.getCurrentPage(state),
  numPages: selectors.getNumPages(state),
  pages: selectors.getRenderedPages(state),
  annotation: selectors.getAnnotation(state),
  fields: selectors.getFields(state)
});

const mapActionsToProps = {
  setCurrentPage: actions.setCurrentPage,
  setNumPages: actions.setNumPages,
  setAnnotation: actions.setAnnotation,
  addField: actions.addField,
  setActiveField: actions.setActiveField
};

export default connect(mapSelectorsToProps, mapActionsToProps)(Document);
