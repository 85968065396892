import React, { useRef, useState } from 'react';
import useOnClickOutside from '../../hooks/useOnClickOutside';

const WorkflowStep = (props) => {
  const [isActive, updateIsActive] = useState(false);

  const ref = useRef();
  useOnClickOutside(ref, () => {
    updateIsActive(false);
  });

  return (
    <React.Fragment>
      <div
        className={`box ${props.active ? 'active' : ''}`}
        onClick={props.onClick}
      >
        <article className="media">
          <figure className="media-left">
            <span>{props.icon}</span>
          </figure>
          <div className="media-content">
            <div className="content">
              <span className="step_name">
                {`${props.order}. ` || ''}
                {props.name || props.defaultName}{' '}
                <span className="tag is-light">{props.category}</span> <br />
                {/* <small className='has-text-grey-light'>{props.description}</small> */}
              </span>
            </div>
          </div>
          <div className="media-right">
            <div className={`dropdown is-right ${isActive ? 'is-active' : ''}`}>
              <div className="dropdown-trigger">
                <button
                  aria-haspopup="true"
                  aria-controls="dropdown-menu"
                  className="button is-small"
                  onClick={(e) => {
                    updateIsActive(!isActive);
                  }}
                >
                  <i className="menu fas fa-ellipsis-h"></i>
                </button>
              </div>

              {
                <div
                  className="dropdown-menu"
                  style={{ width: `${props.width}px` }}
                  id="dropdown-menu"
                  role="menu"
                  ref={ref}
                >
                  <div
                    className={`dropdown-content ${
                      !props.isLast && 'not-last-step'
                    }`}
                  >
                    <a
                      className={`dropdown-item delete-item`}
                      onClick={(e) => {
                        if (props.isLast) {
                          updateIsActive(false);
                          props.onDelete();
                        } else e.preventDefault();
                      }}
                    >
                      <i className="far fa-trash-alt"></i> Delete
                    </a>
                  </div>
                </div>
              }
            </div>
          </div>
        </article>
      </div>
      <div className="hline"></div>
      <div className="step_arrow">▼</div>
    </React.Fragment>
  );
};
export default WorkflowStep;
