import server from 'api/server';
import Navbar from 'components/Page/Navbar';
import React, { useEffect, useRef, useState } from 'react';
import Loader from 'react-loader-spinner';
import { pdfjs } from 'react-pdf';
import { connect, useDispatch, useSelector } from 'react-redux';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { fetchDocuments } from 'redux/actions';
import { saveDocument } from 'redux/actions/documentActions';
import {
  getCurrentPage,
  getDocument,
  getDocuments,
  getEditorMode,
  getNumPages,
  getPdf,
  getRenderedPages,
  getUser,
  isActionPending
} from 'redux/selectors';
import {
  clearPdf,
  clearRenderedPdf,
  fetchPdf,
  setCurrentPage,
  setEditorMode,
  setFields,
  setNumPages,
  storeRenderedPages
} from '../../redux/actions';
import Drawer from '../_common/Drawer';
import ActionBar from './ActionBar/ActionBar';
import Document from './Document';
import './DocumentEditor.scss';
import DocumentForm from './DocumentForm';
import DocumentPreview from './DocumentPreview';
import DocumentSettings from './DocumentSettings';
import LeftSidebar from './LeftSidebar';
import RightSidebar from './RightSidebar';
import Toolbar from './Toolbar';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const DocumentEditor = (props) => {
  const documentId = props.match.params.id;
  const dispatch = useDispatch();
  const document = useSelector((state) => getDocument(state, documentId));
  const renderedPages = useSelector((state) => getRenderedPages(state));
  const pendingPDF = useSelector((state) => isActionPending(state, 'GET_PDF'));
  const [loading, setLoading] = useState(true);
  const [pages, setPages] = useState(null);
  const [startPolling, setStartPolling] = useState(false);
  const [attempts, setAttempts] = useState(10);
  const [isImageMoveable, setIsImageMoveable] = useState(false);
  const [scale, setScale] = useState(1);

  const pinchZoomPanRef = useRef();
  const documentRef = useRef();

  const user = useSelector(getUser);

  useEffect(() => {
    window.document.title = 'Document Editor | Docdown';
    dispatch(showLoading());
    props.clearPdf();
  }, []);

  useEffect(() => {
    props.setEditorMode('editor');
    props.fetchPdf(documentId);
    if (document) {
      props.setFields(document.fields);
    } else dispatch(fetchDocuments());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document, documentId]);

  useEffect(() => {
    async function loaded() {
      function sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
      }
      if (document && pages && pendingPDF && renderedPages) {
        if (!pendingPDF.pending) {
          props.clearRenderedPdf();
          props.storeRenderedPages(pages);
          props.setCurrentPage(1);
          props.setNumPages(pages.length);
          await sleep(1000);
          dispatch(hideLoading());
          setLoading(false);
        }
      }
    }
    loaded();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pages, pendingPDF]);

  useEffect(() => {
    async function pollDocument() {
      let interval;
      try {
        if (startPolling && attempts) {
          interval = setInterval(async () => {
            const response = await server.get(`/documents/get/${documentId}`);
            if (!response.data.processing) {
              setStartPolling(false);
              setLoading(true);
              dispatch(saveDocument(response.data));
              dispatch(clearRenderedPdf());
              dispatch(clearPdf());
              clearInterval(interval);
            }

            setAttempts(attempts - 1);
          }, 3000);
          return () => clearInterval(interval);
        }
      } catch (err) {
        console.log('Err', err);
        setLoading(false);
        setAttempts(0);
      }
    }

    pollDocument();
  }, [startPolling, attempts]);

  const renderLoading = () => {
    return (
      <div className="document_editor_loader_container">
        <Loader type="Oval" color="#ccc" height={30} width={30} />
        <span>Loading Document Editor...</span>
      </div>
    );
  };

  const handleZoomOut = () => {
    pinchZoomPanRef.current.handleZoomOutClick();
  };

  const handleZoomIn = () => {
    pinchZoomPanRef.current.handleZoomInClick();
  };

  const handleToggleDrag = () => {
    setIsImageMoveable(!isImageMoveable);
  };
  const handleDisableDrag = () => {
    setIsImageMoveable(false);
  };

  const handleLeftIconClick = () => {
    props.setCurrentPage(
      props.currentPage - 1 <= 1 ? 1 : props.currentPage - 1
    );
  };

  const handleRightIconClick = () => {
    const numPages = props.pages.length;
    props.setCurrentPage(
      props.currentPage + 1 >= numPages ? numPages : props.currentPage + 1
    );
  };

  const handleScaleChange = (scale) => {
    setScale(scale);
  };

  const renderEditor = () => {
    return (
      <React.Fragment>
        <LeftSidebar documentId={props.match.params.id} />
        <Document
          actionBar={
            <ActionBar
              onZoomOut={handleZoomOut}
              onZoomIn={handleZoomIn}
              onDisableDrag={handleDisableDrag}
              onToggleDrag={handleToggleDrag}
              onLeftIconClick={handleLeftIconClick}
              onRightIconClick={handleRightIconClick}
              page={props.currentPage}
              pages={props.pages}
              isImageMoveable={isImageMoveable}
              scale={scale}
            />
          }
          displayFooter={scale == 1.0}
          isImageMoveable={isImageMoveable}
          handleScaleChange={handleScaleChange}
          getPinchZoomRef={pinchZoomPanRef}
          documentId={props.match.params.id}
        />
        <RightSidebar documentId={props.match.params.id} />
      </React.Fragment>
    );
  };

  const renderForm = () => {
    return (
      <React.Fragment>
        <DocumentForm documentId={props.match.params.id} />
        <DocumentPreview documentId={props.match.params.id} />
      </React.Fragment>
    );
  };

  const onDocumentReplace = () => {
    setStartPolling(true);
    setLoading(true);
  };

  const renderEditorWindow = () => {
    return loading ? (
      renderLoading()
    ) : (
      <React.Fragment>
        <Toolbar document={document} />
        <div className="columns is-centered document_editor_container is-gapless is-marginless is-paddingless">
          {props.editorMode === 'editor' ? renderEditor() : renderForm()}
          <Drawer
            id="document_editor"
            tabs={[
              {
                name: 'Document Settings',
                icon: 'fas fa-cog',
                props: { document, onDocumentReplace },
                component: DocumentSettings
              }
            ]}
          />
        </div>
      </React.Fragment>
    );
  };

  useEffect(() => {
    const getPage = (num, pdf) => {
      return new Promise((resolve, reject) => {
        pdf.getPage(num).then((page) => {
          const scale = 1.5;
          const viewport = page.getViewport({scale: scale});
          const canvas = window.document.createElement('canvas');
          const canvasContext = canvas.getContext('2d', { alpha: false });
          canvas.height = viewport.height;
          canvas.width = viewport.width;
          page
            .render({
              canvasContext,
              viewport
            })
            .promise.then(() => {
              resolve(canvas.toDataURL('image/png'));
            });
        });
      });
    };
    if (props.pdf) {
      fetch(props.pdf)
        .then((data) => data.blob())
        .then((blob) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            pdfjs.getDocument(reader.result).promise.then((pdf) => {
              const pages = [];
              for (let i = 0; i < pdf.numPages; i++) {
                pages.push(getPage(i + 1, pdf));
              }
              Promise.all(pages).then((pages) => {
                setPages(pages);
              });
            });
          };
        });
    }
  }, [props.pdf]);

  const render = () => {
    return (
      <div className="document_editor is-family-sans-serif">
        {renderEditorWindow()}
      </div>
    );
  };
  return (
    <React.Fragment>
      <Navbar />
      {props.pdf ? render() : renderLoading()}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  editorMode: getEditorMode(state),
  pdf: getPdf(state),
  documents: getDocuments(state),
  currentPage: getCurrentPage(state),
  numPages: getNumPages(state),
  pages: getRenderedPages(state)
});

export default connect(mapStateToProps, {
  setCurrentPage,
  setNumPages,
  storeRenderedPages,
  fetchPdf,
  setFields,
  clearRenderedPdf,
  setEditorMode,
  clearPdf
})(DocumentEditor);
