/* eslint-disable jsx-a11y/anchor-is-valid */
import server from 'api/server';
import Page from 'components/Page';
import { lowerCase, startCase } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import Loader from 'react-loader-spinner';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DropdownFilter } from './filters';
import './History.scss';
import { getStatusIcon } from './utils';

const WorkflowHistory = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [count, setcount] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [meta, setMeta] = useState({});
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [clearRows, setClearRows] = useState(false);
  const [workflowFilter, setWorkflowFilter] = useState(null);
  const fetchData = async (page) => {
    setLoading(true);
    const response = await server.get(
      `/workflows/runs/?page=${page - 1}&size=${pageSize}${
        workflowFilter ? '&workflow=' + workflowFilter : ''
      }`
    );
    setData(response.data.rows);
    setcount(response.data.count);
    setMeta(response.data.meta);
    setLoading(false);
  };

  const handlePageChange = (page) => {
    fetchData(page);
  };

  const handlePerRowsChange = async (newsize, page) => {
    setLoading(true);
    setPageSize(newsize);
    fetchData(page);
  };

  useEffect(() => {
    fetchData(1);
  }, [workflowFilter]);

  useEffect(() => {
    fetchData(1);
  }, []);

  // console.log(data);
  const columns = useMemo(
    () => [
      {
        name: 'Execution ID',
        selector: 'id'
      },
      {
        name: 'Status',
        sortable: true,
        selector: 'status',
        cell: (row) => (
          <span>
            {getStatusIcon(row.status)} {startCase(lowerCase(row.status))}
          </span>
        )
      },
      {
        name: 'Workflow',
        sortable: true,
        selector: 'workflow.name'
      },

      {
        name: 'Last Updated',
        selector: 'updatedAt',
        sortable: true,
        cell: (row) => <Moment format="lll">{row.updatedAt}</Moment>
      },
      {
        cell: (row) => (
          <Link
            to={`/history/workflow/${row.id}`}
            className="button is-small is-uppercase has-text-weight-semibold is-fullwidth log-button"
          >
            View Log
          </Link>
        )
      }
    ],
    []
  );

  const Loading = () => {
    return (
      <div className="loader_container">
        <Loader type="Oval" color="#ccc" height={30} width={30} />
      </div>
    );
  };

  const renderTop = () => {
    return (
      <nav className="level is-mobile">
        <div className="level-left">
          <div className="level-item">
            <h3 className="title is-4">Workflow History</h3>
          </div>
        </div>
        <div className="level-right">
          <div className="level-item search">
            {/* <GlobalSearch
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
            /> */}
          </div>
        </div>
      </nav>
    );
  };

  const handleDeleteWorkflowHistory = async () => {
    try {
      if (
        window.confirm(
          `Are you sure you want to delete the selected workflow histor${
            selectedDocuments.length > 1 ? 'ies' : 'y'
          }?`
        )
      ) {
        setLoading(true);
        const response = await server.delete(`/workflows/runs/delete`, {
          data: { workflowRunIds: selectedDocuments }
        });
        await fetchData(1);
        toast.info(
          `Workflow histor${
            selectedDocuments.length > 1 ? 'ies' : 'y'
          } deleted successfully!`
        );
        setLoading(false);
        setSelectedDocuments([]);
      }
      setClearRows(!clearRows);
    } catch (error) {
      toast.info(
        `Error Deleting Workflow Histor${
          selectedDocuments.length > 1 ? 'ies' : 'y'
        }`
      );
      setLoading(false);
      setSelectedDocuments([]);
    }
  };

  const handleWorkflowHistorySelected = ({
    allSelected,
    selectedCount,
    selectedRows
  }) => {
    console.log('selected', selectedRows, selectedCount);
    const selectedDocuments = selectedRows.reduce((acc, val) => {
      acc.push(val.id);
      return acc;
    }, []);
    setSelectedDocuments(selectedDocuments);
  };

  const renderTable = () => {
    // console.log(meta);
    return (
      <>
        <section className="section documents_table">
          <div className="container">
            {/* <div className="filters filter-box ">
              <DropdownFilter
                options={['All Workflows', ...(meta?.workflows || [])]}
                defaultValueIndex={0}
                onFilter={(value) => {
                  if (value === 'All Workflows') {
                    setWorkflowFilter(null);
                  } else {
                    setWorkflowFilter(value);
                  }
                }}
              />
              <div>
                {selectedDocuments.length > 0 && (
                  <button
                    onClick={handleDeleteWorkflowHistory}
                    className="button delete-button upload is-small"
                  >
                    <span className="icon">
                      <i className="far fa-trash-alt"></i>
                    </span>
                    <span>Delete</span>
                  </button>
                )}
              </div>
            </div> */}
            <div className="box">
              <div className="history_table">
                <div className="filters">
                  <nav className="level is-mobile">
                    <div className="level-left">
                      <div className="level-item"></div>
                      <div className="level-item"></div>
                    </div>
                  </nav>
                </div>

                <DataTable
                  title=""
                  columns={columns}
                  data={data}
                  progressPending={loading}
                  progressComponent={<Loading />}
                  selectableRows
                  pagination
                  paginationServer
                  paginationTotalRows={count}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  paginationPerPage={pageSize}
                  paginationRowsPerPageOptions={[25, 50, 100, 500]}
                  // selectableRowsComponent={Checkbox}
                  // selectableRowsComponentProps={{ inkDisabled: true }}
                  onSelectedRowsChange={handleWorkflowHistorySelected}
                  clearSelectedRows={clearRows}
                />
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };

  return (
    <Page
      title="Workflow History"
      action={
        <Link
          to="/history/document"
          className="button is-small action_button_white"
        >
          <i className="far fa-file" style={{ marginRight: '10px' }} />
          View Document History
        </Link>
      }
      filters={[
        <DropdownFilter
          options={['All Workflows', ...(meta?.workflows || [])]}
          defaultValueIndex={0}
          onFilter={(value) => {
            if (value === 'All Workflows') {
              setWorkflowFilter(null);
            } else {
              setWorkflowFilter(value);
            }
          }}
        />,
        <div>
          <button
            onClick={handleDeleteWorkflowHistory}
            className="button delete-button is-small"
            disabled={selectedDocuments.length === 0}
          >
            <span className="icon">
              <i className="far fa-trash-alt"></i>
            </span>
            <span>Delete</span>
          </button>
        </div>
      ]}
    >
      <div className="page historyPage workflow_history_page">
        {/* <section className="section">
          <div className="container">{renderTop()}</div>
        </section> */}
        {renderTable()}
        {/* {loading ? (
          renderLoading()
        ) : data && data.length > 0 ? (
          renderTable()
        ) : (
          <section className="section document_history">
            <div className="container">
              <div className="box empty">
                <div className="content">
                  <p>
                    <i className="fas fa-history"></i>
                  </p>
                  <p>Could not find any generated documents.</p>
                </div>
              </div>
            </div>
          </section>
        )} */}
      </div>
      {/* <Footer /> */}
    </Page>
  );
};

export default WorkflowHistory;
