import * as types from './types';

export const setCurrentFolder = (folder) => {
  return {
    type: types.SET_CURRENT_FOLDER,
    payload: folder
  };
};
export const setFolderNavigationHistory = (history) => {
  return {
    type: types.SET_FOLDER_NAVIGATION_HISTORY,
    payload: history
  };
};
