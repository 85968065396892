const defaultIfEmpty = (data, defaultVal) => {
  return data === undefined || data === null ? defaultVal : data;
};
const undefinedIfEmpty = (obj, key) => {
  return obj ? obj[key] : undefined;
};
export const fieldDefaults = (type, field) => {
  const { data, geometry } = field
    ? field
    : { data: undefined, geometry: undefined };

  let mainValues = {
    name: defaultIfEmpty(
      undefinedIfEmpty(field ? field.data : undefined, 'name'),
      ''
    ),
    label: defaultIfEmpty(
      undefinedIfEmpty(field ? field.data : undefined, 'label'),
      ''
    ),
    description: defaultIfEmpty(undefinedIfEmpty(data, 'description'), ''),
    hidden: defaultIfEmpty(undefinedIfEmpty(data, 'hidden'), false),
    hiddenForm: defaultIfEmpty(undefinedIfEmpty(data, 'hiddenForm'), false),
    static: defaultIfEmpty(undefinedIfEmpty(data, 'static'), false),
    required: defaultIfEmpty(undefinedIfEmpty(data, 'required'), false),
    x: defaultIfEmpty(undefinedIfEmpty(geometry, 'x'), 0),
    y: defaultIfEmpty(undefinedIfEmpty(geometry, 'y'), 0),
    width: defaultIfEmpty(undefinedIfEmpty(geometry, 'width'), 0),
    height: defaultIfEmpty(undefinedIfEmpty(geometry, 'height'), 0),
    conditionalFormula: defaultIfEmpty(
      undefinedIfEmpty(data, 'conditionalFormula'),
      []
    )
  };
  // mainValues = { ...mainValues, name: mainValues.text }
  let textNumValues = {
    default: defaultIfEmpty(undefinedIfEmpty(data, 'default'), ''),
    comb: defaultIfEmpty(undefinedIfEmpty(data, 'comb'), false),
    combLength: defaultIfEmpty(undefinedIfEmpty(data, 'combLength'), 10),
    boldItalicUnderline: defaultIfEmpty(
      undefinedIfEmpty(data, 'boldItalicUnderline'),
      '000'
    ),
    textAlign: defaultIfEmpty(undefinedIfEmpty(data, 'textAlign'), 'left'),
    verticalAlign: defaultIfEmpty(
      undefinedIfEmpty(data, 'verticalAlign'),
      'center'
    ),
    fontFamily: defaultIfEmpty(undefinedIfEmpty(data, 'fontFamily'), 'Roboto'),
    fontSize: defaultIfEmpty(undefinedIfEmpty(data, 'fontSize'), 14),
    letterSpacing: defaultIfEmpty(undefinedIfEmpty(data, 'letterSpacing'), 0),
    overflow: defaultIfEmpty(undefinedIfEmpty(data, 'overflow'), 'flow'),
    color: defaultIfEmpty(undefinedIfEmpty(data, 'color'), '#000000')
  };

  if (!type) return mainValues;

  switch (type) {
    case 'text':
      return {
        ...mainValues,
        ...textNumValues,
        dataType: defaultIfEmpty(undefinedIfEmpty(data, 'dataType'), 'text'),
        fieldOptions: defaultIfEmpty(
          undefinedIfEmpty(data, 'fieldOptions'),
          []
        ),
        minLength: defaultIfEmpty(undefinedIfEmpty(data, 'minLength'), 0),
        maxLength: defaultIfEmpty(undefinedIfEmpty(data, 'maxLength'), 256)
      };
    case 'number':
      return {
        ...mainValues,
        ...textNumValues,
        dataType: defaultIfEmpty(undefinedIfEmpty(data, 'dataType'), 'decimal'),
        commaSeparated: defaultIfEmpty(
          undefinedIfEmpty(data, 'commaSeparated'),
          false
        ),
        decimalPlaces: defaultIfEmpty(
          undefinedIfEmpty(data, 'decimalPlaces'),
          2
        ),
        currencySymbol: defaultIfEmpty(
          undefinedIfEmpty(data, 'currencySymbol'),
          '$'
        ),
        currencyPosition: defaultIfEmpty(
          undefinedIfEmpty(data, 'currencyPosition'),
          'prefix'
        ),
        min: defaultIfEmpty(undefinedIfEmpty(data, 'min'), ''),
        max: defaultIfEmpty(undefinedIfEmpty(data, 'max'), '')
      };
    case 'signature':
      return {
        ...mainValues,
        source: defaultIfEmpty(undefinedIfEmpty(data, 'source'), 'user'),
        signature: defaultIfEmpty(undefinedIfEmpty(data, 'signature'), ''),
        fitMode: defaultIfEmpty(undefinedIfEmpty(data, 'fitMode'), 'fit'),
        tabs: defaultIfEmpty(undefinedIfEmpty(data, 'tabs'), 'all'),
        authFields: defaultIfEmpty(
          undefinedIfEmpty(data, 'authFields'),
          'nameEmail'
        ),
        language: defaultIfEmpty(undefinedIfEmpty(data, 'language'), 'en')
      };
    case 'image':
      return {
        ...mainValues,
        default: defaultIfEmpty(undefinedIfEmpty(data, 'default'), ''),
        fitMode: defaultIfEmpty(undefinedIfEmpty(data, 'fitMode'), 'cover')
      };
    case 'checkbox':
      return {
        ...mainValues,
        fontSize: defaultIfEmpty(undefinedIfEmpty(data, 'fontSize'), 14),
        color: defaultIfEmpty(undefinedIfEmpty(data, 'color'), '#000000')
      };
    case 'dateTime':
      return {
        ...mainValues,
        ...textNumValues,
        dateTimeFormat: defaultIfEmpty(
          undefinedIfEmpty(data, 'dateTimeFormat'),
          { value: 'MM/DD/YYYY', label: 'MM/DD/YYYY' }
        ),
        customFormat: defaultIfEmpty(undefinedIfEmpty(data, 'customFormat'), '')
      };
    case 'group':
      return {
        name: defaultIfEmpty(
          undefinedIfEmpty(field ? field.data : undefined, 'name'),
          ''
        ),
        label: defaultIfEmpty(
          undefinedIfEmpty(field ? field.data : undefined, 'label'),
          ''
        ),
        description: defaultIfEmpty(undefinedIfEmpty(data, 'description'), ''),
        fields: data.fields ? data.fields : []
      };
    default:
      return {};
  }
};
