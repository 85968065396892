import server from 'api/server';
import axios from 'axios';
import CircularProgress from 'components/_common/CircularProgress';
import { times } from 'lodash';
import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { Document, Page, pdfjs } from 'react-pdf';
import { connect } from 'react-redux';
import { SizeMe } from 'react-sizeme';
import { toast } from 'react-toastify';
import { clearRenderedPdfData, setRenderedPdf } from 'redux/actions';
import {
  getNumPages,
  getRenderedData,
  getRenderedPdf,
  isActionPending
} from 'redux/selectors';
import poll from 'utils/polling';
import './DocumentPreview.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const DownloadPDFButton = ({ documentId, values, disabled, id = "download-pdf-button" }) => {
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (downloading && progress === 0) {
      for (let i = 0; i < 9; i++) {
        setTimeout(() => {
          setProgress(i * 10);
        }, 1000 * (i + 1));
      }
    }
  }, [downloading]);
  const download = async () => {
    try {
      setLoading(true);
      const {
        data: { resourceURL, fileName }
      } = await server.post(
        `${process.env.REACT_APP_SERVER_BASE_URL}/generate/${documentId}`,
        values
      );
      setLoading(false);
      if (resourceURL && fileName) {
        toast.info('Document generated, starting download...');
        // do polling
        setProgress(0);
        setDownloading(true);
        const interval = 3000;
        const timeOut = 90000;
        const task = async () => {
          return await axios.get(resourceURL, { responseType: 'arraybuffer' });
        };
        const validate = (result) => result.status === 200;
        const { data } = await poll(task, validate, interval, timeOut);
        setDownloading(false);

        // download file
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(
          new Blob([data], { type: 'application/octet-stream' })
        );
        link.download = fileName.includes('.pdf')
          ? fileName
          : `${fileName}.pdf`;
        document.body.appendChild(link);
        link.click();
        setTimeout(function () {
          window.URL.revokeObjectURL(link);
        }, 200);
        setLoading(false);
      } else throw Error('No resource URL to begin the downlaod');
    } catch (err) {
      console.log(err);
      toast.info('Could not download the document');
    }
    setDownloading(false);
    setProgress(0);
    setLoading(false);
  };
  return (
    <div className="generate_button">
      {downloading && (
        <CircularProgress
          classes="download_progress"
          value={progress}
          max={100}
        />
      )}
      <button
        id={id}
        onClick={download}
        disabled={disabled || downloading}
        className={`button is-info is-small ${loading ? 'is-loading' : ''}`}
      >
        {downloading ? 'Downloading...' : 'Generate'}
      </button>
    </div>
  );
};
const DocumentPreview = (props) => {
  const id = props.id ?? "document-preview"

  useEffect(() => {
    if (!props.pdf) {
      props.setRenderedPdf(props.documentId);
    }
  }, []);

  const renderDocument = (width) => {
    //API: https://github.com/wojtekmaj/react-pdf
    return (
      <Document file={props.pdf} loading="" noData="" onLoadSuccess={() => {}}>
        {times(props.numPages, (i) => (
          <Page
            className="page"
            pageNumber={i + 1}
            key={i}
            width={width}
            loading=""
          />
        ))}
      </Document>
    );
  };
  const render = () => {
    return (
      <div className="document_preview column is-flex is-centered">
        <nav className="navbar rendered_toolbar">
          <div className="navbar-menu">
            <div className="navbar-start">
              <div className="navbar-item">
                <button
                  id={`${id}-clear-button`}
                  className="button is-small"
                  onClick={() => {
                    props.clearRenderedPdfData();
                    props.setRenderedPdf(props.documentId);
                  }}
                >
                  Clear
                </button>
              </div>
            </div>
            <div className="navbar-end">
              <div className="navbar-item">
                <DownloadPDFButton
                  id={`${id}-download-pdf-button`}
                  documentId={props.documentId}
                  values={props.pdfData.values}
                  disabled={
                    props.pendingPDF &&
                    props.pendingPDF.pending &&
                    !props.pdfData.values
                  }
                />
              </div>
            </div>
          </div>
        </nav>
        {props.pendingPDF && props.pendingPDF.pending && (
          <div className="document_loader_container">
            <Loader type="Oval" color="#ccc" height={30} width={30} />
          </div>
        )}
        <SizeMe>
          {({ size }) => (
            <div className="container">
              {props.pendingPDF &&
                !props.pendingPDF.pending &&
                renderDocument(size.width * 0.8)}
            </div>
          )}
        </SizeMe>
      </div>
    );
  };
  return render();
};

const mapStateToProps = (state) => ({
  pdf: getRenderedPdf(state).data,
  pdfData: getRenderedData(state),
  numPages: getNumPages(state),
  pendingPDF: isActionPending(state, 'GET_RENDEREDPDF')
});

export default connect(mapStateToProps, {
  setRenderedPdf,
  clearRenderedPdfData
})(DocumentPreview);
