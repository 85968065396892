import server from 'api/server';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { required } from 'redux-form-validators';
import { fetchUser } from 'redux/actions';
import { getUser } from 'redux/selectors';
import '../Settings.scss';

let ChangeEmailModal = (props) => {
  const [error, setError] = useState(null);
  const [view, setView] = useState('password');
  const submit = async (values) => {
    if (view === 'password') {
      try {
        await server.post('/auth/login', {
          email: props.email,
          password: values.password
        });
        setView('email');
        setError(null);
      } catch (error) {
        setError(error.response.data);
      }
    }
    if (view === 'email') {
      try {
        await server.post('/auth/update/email/send', {
          email: values.email,
          password: values.password
        });
        setView('code');
        setError(null);
      } catch (error) {
        setError(error.response.data);
      }
    }
    if (view === 'code') {
      try {
        await server.post('/auth/update/email/verify', {
          email: values.email,
          password: values.password,
          code: values.code
        });
        setError(null);
        props.onSuccess();
      } catch (error) {
        setError(error.response.data);
      }
    }
  };
  const { handleSubmit, submitting } = props;
  return (
    <div className="modal change_email is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title is-small">Change Email</p>
          <button
            className="delete"
            aria-label="close"
            onClick={(e) => props.closeModal()}
          ></button>
        </header>
        <form onSubmit={handleSubmit(submit)}>
          <section className="modal-card-body">
            <p>
              Your current email is <strong>{props.email}</strong>
            </p>
            <label className="label">Please enter your password.</label>
            <div className="field is-grouped">
              <div className="control is-expanded">
                <Field
                  name="password"
                  className="input"
                  component="input"
                  type="password"
                  validate={required({ msg: 'Please provide your password' })}
                  placeholder="Enter password..."
                />
              </div>
            </div>
            {(view === 'email' || view === 'code') && (
              <div>
                <label className="label">
                  Please enter a new email and we will send you a verification
                  code.
                </label>
                <div className="field is-grouped">
                  <div className="control is-expanded">
                    <Field
                      name="email"
                      className="input"
                      component="input"
                      type="email"
                      validate={required({
                        msg: 'Please provide your password'
                      })}
                      placeholder="Enter your new email..."
                    />
                  </div>
                </div>
              </div>
            )}

            {view === 'code' && (
              <div>
                <label className="label">
                  We just sent you a temporary verification code to your new
                  email.
                </label>
                <div className="field is-grouped">
                  <div className="control is-expanded">
                    <Field
                      name="code"
                      className="input"
                      component="input"
                      type="text"
                      validate={required({
                        msg: 'Please provide verification code'
                      })}
                      placeholder="Enter verification code..."
                    />
                  </div>
                </div>
              </div>
            )}
            <p className="help is-danger">{error && <span>{error}</span>}</p>
          </section>
          <footer className="modal-card-foot">
            <button
              type="submit"
              className={`button is-small is-info ${
                submitting ? 'is-loading' : ''
              }`}
            >
              Continue
            </button>
          </footer>
        </form>
      </div>
    </div>
  );
};
ChangeEmailModal = reduxForm({ form: 'change_email' })(ChangeEmailModal);

let ChangePasswordModal = (props) => {
  const [error, setError] = useState(null);
  const onSubmit = async (values) => {
    const { password, newPassword, confirmNewPassword } = values;
    try {
      await server.post('/auth/update/password', {
        password,
        newPassword,
        confirmNewPassword
      });
      props.onSuccess();
    } catch (error) {
      setError(error.response.data);
    }
  };
  const { handleSubmit, submitting } = props;
  return (
    <div className="modal change_password is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title is-small">Change Password</p>
          <button
            className="delete"
            aria-label="close"
            onClick={(e) => props.closeModal()}
          ></button>
        </header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <section className="modal-card-body">
            <label className="label">Old Password</label>
            <div className="field is-grouped">
              <div className="control is-expanded">
                <Field
                  name="password"
                  className="input"
                  component="input"
                  type="password"
                  validate={required({
                    msg: 'Please provide your old password'
                  })}
                  placeholder="Enter old password..."
                />
              </div>
            </div>
            <label className="label">New Password</label>
            <div className="field is-grouped">
              <div className="control is-expanded">
                <Field
                  name="newPassword"
                  className="input"
                  type="password"
                  component="input"
                  validate={required({
                    msg: 'Please provide your new password'
                  })}
                  placeholder="Enter new password..."
                />
              </div>
            </div>
            <label className="label">Repeat Password</label>
            <div className="field is-grouped">
              <div className="control is-expanded">
                <Field
                  name="confirmNewPassword"
                  className="input"
                  type="password"
                  component="input"
                  validate={required({
                    msg: 'Please confirm your new password'
                  })}
                  placeholder="Repeat new password..."
                />
              </div>
            </div>

            <p className="help is-danger">{error && <span>{error}</span>}</p>

            <div className="field">
              <div className="control is-expanded"></div>
            </div>
          </section>
          <footer className="modal-card-foot">
            <button
              type="submit"
              className={`button is-small is-info ${
                submitting ? 'is-loading' : ''
              }`}
            >
              Change Password
            </button>
          </footer>
        </form>
      </div>
    </div>
  );
};
ChangePasswordModal = reduxForm({ form: 'change_password' })(
  ChangePasswordModal
);

let UpdateAPIKeyModal = (props) => {
  const [error, setError] = useState(null);
  const onSubmit = async (values) => {
    const { password, newPassword, confirmNewPassword } = values;
    try {
      await server.post('/auth/update/apiSecret', {
        password
      });
      props.onSuccess();
    } catch (error) {
      setError(error.response.data);
    }
  };
  const { handleSubmit, submitting } = props;
  return (
    <div className="modal change_password is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title is-small">
            {props.api ? 'Change API Key' : 'Create API Key'}
          </p>
          <button
            className="delete"
            aria-label="close"
            onClick={(e) => props.closeModal()}
          ></button>
        </header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <section className="modal-card-body">
            <label className="label">Password</label>
            <div className="field is-grouped">
              <div className="control is-expanded">
                <Field
                  name="password"
                  className="input"
                  component="input"
                  type="password"
                  validate={required({
                    msg: 'Please provide your password'
                  })}
                  placeholder="Enter your password..."
                />
              </div>
            </div>

            <p className="help is-danger">{error && <span>{error}</span>}</p>

            <div className="field">
              <div className="control is-expanded"></div>
            </div>
          </section>
          <footer className="modal-card-foot">
            <button
              type="submit"
              className={`button is-small is-info ${
                submitting ? 'is-loading' : ''
              }`}
            >
              {props.api ? 'Change API Key' : 'Create API Key'}
            </button>
          </footer>
        </form>
      </div>
    </div>
  );
};
UpdateAPIKeyModal = reduxForm({ form: 'update_apikey' })(UpdateAPIKeyModal);

const Profile = (props) => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const [userName, setUserName] = useState(user.name);
  const [resettingPassword, setResettingPassword] = useState(false);
  const [passwordChange, setPasswordChange] = useState(false);
  const [resettingEmail, setResettingEmail] = useState(false);
  const [emailChange, setEmailChange] = useState(false);
  const [resettingAPIKey, setResettingAPIKey] = useState(false);
  const [apiKeyChange, setApiKeyChange] = useState(false);
  useEffect(() => {
    props.initialize(props.initialValues);
  }, []);

  async function onSubmit(values) {
    try {
      await server.post('/auth/update', { name: userName });
      dispatch(fetchUser());
    } catch (err) {
      console.log(err.response.data);
    }
  }

  const { handleSubmit, submitting } = props;
  return (
    <React.Fragment>
      {resettingPassword && (
        <ChangePasswordModal
          onSuccess={() => {
            setResettingPassword(false);
            setPasswordChange(true);
          }}
          closeModal={() => setResettingPassword(false)}
        />
      )}

      {resettingEmail && (
        <ChangeEmailModal
          email={user && user.email}
          onSuccess={() => {
            dispatch(fetchUser());
            setEmailChange(true);
          }}
          closeModal={() => setResettingEmail(false)}
        />
      )}
      {resettingAPIKey && (
        <UpdateAPIKeyModal
          email={user && user.email}
          onSuccess={() => {
            dispatch(fetchUser());
            setResettingAPIKey(false);
            setApiKeyChange(true);
          }}
          api={user && user.apiSecret}
          closeModal={() => setResettingAPIKey(false)}
        />
      )}

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="profile_form is-mobile"
      >
        <div className="is-mobile">
          {emailChange && (
            <article className="message is-success">
              <div className="message-body ">
                <span className="is-small">
                  Your email has been successfully changed.
                </span>
              </div>
            </article>
          )}
          {passwordChange && (
            <article className="message is-success">
              <div className="message-body ">
                <span className="is-small">
                  Your password has been successfully changed.
                </span>
              </div>
            </article>
          )}
          {apiKeyChange && (
            <article className="message is-success">
              <div className="message-body ">
                <span className="is-small">Your API key has been changed.</span>
              </div>
            </article>
          )}
          <label className="label">Email</label>
          <div className="field has-addons ">
            <div className="control is-expanded">
              <input
                value={user.email}
                className="input"
                disabled
                placeholder="Email"
              />
            </div>
            {!user.svCustomer && (
              <div className="control">
                <a
                  className="button change_email"
                  id="Profile-button-change-email"
                  onClick={(e) => {
                    setResettingEmail(true);
                  }}
                >
                  Change
                </a>
              </div>
            )}
          </div>
          <br />
          <div>
            <label className="label">Password</label>
            <div className="field has-addons">
              <div className="control is-expanded">
                <input
                  disabled
                  value="password"
                  className="input"
                  placeholder="password"
                  type="password"
                />
              </div>
              <div className="control">
                <a
                  className="button change_password"
                  onClick={(e) => {
                    setResettingPassword(true);
                  }}
                >
                  Change
                </a>
              </div>
            </div>
          </div>
          <br />
          <label className="label">Name</label>
          <div className="field has-addons">
            <div className="control is-expanded">
              <input
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                className="input"
                placeholder="Name"
              />
            </div>
            <div className="control">
              <button
                type="submit"
                className={`button update_name ${
                  submitting ? 'is-loading' : ''
                }`}
              >
                Update
              </button>
            </div>
          </div>
          <hr />
          <label className="label">API Key</label>

          <div className="field has-addons ">
            <div className="control is-expanded">
              <input
                value={user.apiSecret}
                className="input"
                disabled
                placeholder="No API Key generated yet..."
              />
            </div>
            <div className="control">
              <a
                className="button change_password"
                onClick={(e) => {
                  setResettingAPIKey(true);
                }}
              >
                {user.apiSecret ? 'Change' : 'Create'}
              </a>
            </div>
          </div>
          <p className="help">
            Please keep your API Key secure as it can be used to access data in
            your account.
          </p>
        </div>
      </form>
    </React.Fragment>
  );
};
const Account = reduxForm({ form: 'profile' })(Profile);

export default Account;
