import React, { useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import {
  saveDocumentToServer,
  setActiveField,
  setCurrentPage,
  setFields
} from 'redux/actions';
import uniqid from 'uniqid';
import './FieldsListPanel.scss';
import { renderIcon } from './utils';

const FieldItem = ({ field, fields, page, activeField, fieldOnClick }) => {
  const dispatch = useDispatch();
  const [mouseOver, setMouseOver] = useState(false);
  const alertMessage =
    field.data.type === 'group'
      ? `Are you sure you want to delete this group? All fields in this group will not be affected.`
      : `Are you sure you want to delete the field ${
          field.data.label || 'this field'
        }?`;
  const handleDelete = () => {
    if (window.confirm(alertMessage)) {
      const newFields = fields.filter((f) => f.data.id !== field.data.id);
      dispatch(setFields(newFields));
      dispatch(saveDocumentToServer({ ...document, fields: newFields }, false));
    }
  };
  const handleClone = () => {
    let names = fields.map((f) => f.data.name);
    let id = uniqid();
    let count = 0;
    let newName;
    while (true) {
      count = count + 1;
      newName = `${field.data.name}_${count}`;
      if (names.includes(newName)) continue;
      else break;
    }
    let newField = {
      geometry: { ...field.geometry },
      data: {
        ...field.data,
        label: `${field.data.label} ${count}`,
        name: newName,
        id
      }
    };
    let newFields = [...fields, newField];
    dispatch(setFields(newFields));
    dispatch(saveDocumentToServer({ ...document, fields: newFields }, false));
    dispatch(setActiveField(newFields.find((f) => f.data.id === id)));
  };
  return (
    <a
      className={`panel-block ${field.data.id === activeField ? 'is-active' : ''}`}
      onClick={(e) => fieldOnClick(page, field)}
      onMouseEnter={() => {
        setMouseOver(true);
      }}
      onMouseLeave={() => {
        setMouseOver(false);
      }}
    >
      <li>
        <nav className="level">
          <div className="level-left">
            <div className="level-item">
              <span className="panel-icon">{renderIcon(field.data.type)}</span>
              {field.data.label || field.data.name}
            </div>
          </div>
          {(mouseOver || field.data.id === activeField) && (
            <div className={`level-right field_icons `}>
              {field.data.type !== 'group' && (
                <i
                  onClick={() => {
                    handleClone();
                    setMouseOver(false);
                  }}
                  className="far fa-clone field_icon field_icon_duplicate"
                  aria-hidden="true"
                ></i>
              )}
              <i
                onClick={() => {
                  handleDelete();
                  setMouseOver(false);
                }}
                className="far fa-trash-alt field_icon field_icon_delete"
                aria-hidden="true"
              ></i>
            </div>
          )}
        </nav>
      </li>
    </a>
  );
};

const GrouppedFields = ({
  field,
  fields,
  num,
  page,
  activeField,
  fieldOnClick
}) => {
  // Get the fields that belong to this group.
  const fieldIds = field.data.fields
    ? field.data.fields.map((f) => f.value)
    : [];
  const groupFields = fields.filter((f) => fieldIds.includes(f.data.id));

  return (
    <Droppable droppableId={`droppable_${field.data.id}`} type={num}>
      {(provided) => (
        <div ref={provided.innerRef}>
          {groupFields.map((f, index) => {
            return (
              <Draggable
                key={`${num}${index}`}
                draggableId={`${num}${index}`}
                index={index}
              >
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className="innerFields"
                  >
                    <FieldItem
                      field={f}
                      fields={fields}
                      page={page}
                      activeField={activeField}
                      fieldOnClick={fieldOnClick}
                    />
                  </div>
                )}
              </Draggable>
            );
          })}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export const DraggableGroup = ({ page, field, fields, index, activeField }) => {
  const dispatch = useDispatch();

  const fieldOnClick = (page, field) => {
    dispatch(setCurrentPage(page));
    dispatch(setActiveField(field));
  };
  return (
    <Draggable
      key={`${field.data.id}`}
      draggableId={`${field.data.id}`}
      index={index}
    >
      {(provided) => (
        <>
          <div
            className={index % 2 === 0 ? 'even' : 'odd'}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <FieldItem
              field={field}
              fields={fields}
              page={page}
              activeField={activeField}
              fieldOnClick={fieldOnClick}
            />
            {field.data.type === 'group' && (
              <GrouppedFields
                field={field}
                fields={fields}
                num={index}
                page={page}
                activeField={activeField}
                fieldOnClick={fieldOnClick}
              />
            )}
          </div>
        </>
      )}
    </Draggable>
  );
};
