import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Loader from 'react-loader-spinner'
import server from 'api/server'
import { fetchUser } from 'redux/actions'

export default (props) => {

  return (
    <div className='auth_loader_container'>
      <Loader
        type="Oval"
        color="#ccc"
        height={30}
        width={30} />
    </div>
  )
}