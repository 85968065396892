import { openDrawerTab } from 'components/_common/Drawer';
import React from 'react';
import { useSelector } from 'react-redux';
import { getDocument, getEditorMode } from 'redux/selectors';
import EditorOptions from './components/EditorOptions';
import SaveButton from './components/SaveButton';
import SelectorButtons from './components/SelectorButtons';
import './Toolbar.scss';

const Toolbar = (props) => {
  const document = useSelector((state) =>
    getDocument(state, props.document.id)
  );
  const editorMode = useSelector(getEditorMode);
  function render() {
    return (
      <nav
        className="navbar toolbar has-background-white-ter "
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          {/* <div className="navbar-item">
          <Link className='button is-rounded is-link' to='/documents'><i className="fas fa-arrow-left"></i></Link>
        </div> */}
          <div className="navbar-item">
            {editorMode === 'editor' ?
              <SelectorButtons id="document-editor-selector" /> :
              null}
          </div>
        </div>
        <div className="navbar-start is-centered">
          <div className="navbar-item document_title">
            <p
              onClick={(e) => {
                openDrawerTab('document_editor', 'Document Settings');
              }}
            >
              {document.name}
            </p>
          </div>
        </div>
        <div className="navbar-end">
          <div className="navbar-item">
            <EditorOptions documentId={props.document.id} />
          </div>
          <div className="navbar-item">
            <SaveButton documentId={props.document.id} />
          </div>
        </div>
      </nav>
    );
  }
  return render();
};

export default Toolbar;
