import tooltips from 'components/DocumentEditor/tooltips';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { numericality } from 'redux-form-validators';
import { updateFieldName } from 'redux/actions';
import {
  BIUButtons,
  Checkbox,
  ColorPicker,
  Conditional,
  DeleteField,
  Dropdown,
  FontPicker,
  HorizontalAlignment,
  Slider,
  TagsInput,
  TextField,
  VerticalAlignment
} from './components';
import FieldTypeDropdown from './components/FieldTypeDropdown';

const TextFieldEditor = (props) => {
  const values = useSelector((state) => getFormValues(props.form)(state));
  const dispatch = useDispatch();

  useEffect(() => {
    props.onChange(values);
  }, [values]);

  useEffect(() => {
    props.initialize(props.initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fieldNames = [
    ...props.fields.map((f) => f.data.name),
    'generatedDocument'
  ];

  useEffect(() => {
    if (props.initialValues.name !== values.name) {
      let nameMap = {};
      nameMap[props.initialValues.name] = values.name;
      dispatch(updateFieldName(nameMap));
    }
  }, [values.name]);

  function renderGeneral() {
    return (
      <React.Fragment>
        <FieldTypeDropdown
          type={props.field.data.type}
          onFieldChange={props.onFieldChange}
        />
        <Field
          component={TextField}
          alphaNumeric
          required
          tooltip={tooltips.name}
          // illegal={fieldNames}
          max={64}
          label="Name"
          name="name"
          type="text"
          placeholder="Field Name"
        />
        <Field
          label="Label"
          component={TextField}
          tooltip={tooltips.label}
          name="label"
          type="text"
          placeholder="Form Label"
        />
        <Field
          label="Description"
          component={TextField}
          tooltip={tooltips.description}
          name="description"
          type="text"
          placeholder="Form Description"
        />
        <Field
          label="Default"
          component={TextField}
          tooltip={tooltips.default}
          name="default"
          type="input"
          placeholder="Text"
        />
        <Field
          label="Data Type"
          tooltip={tooltips['datatype-text']}
          options={[
            { value: 'text', label: 'Text' },
            { value: 'email', label: 'Email' },
            { value: 'url', label: 'URL' },
            { value: 'paragraph', label: 'Paragraph' },
            { value: 'multiple', label: 'Multiple Choice' }
          ]}
          component={Dropdown}
          name="dataType"
        />
        {values && values.dataType === 'multiple' && (
          <Field
            label="Field Options"
            component={TagsInput}
            name="fieldOptions"
            tooltip={tooltips['field-options']}
          />
        )}
        <Field
          label="Required"
          component={Checkbox}
          name="required"
          tooltip={tooltips.required}
        />
        <Field
          label="Hide in Document"
          component={Checkbox}
          name="hidden"
          tooltip={tooltips.hidden}
        />
        <Field
          label="Hide in Form"
          component={Checkbox}
          name="hiddenForm"
          tooltip={tooltips.hiddenForm}
        />
        <Field
          label="Static"
          component={Checkbox}
          name="static"
          tooltip={tooltips.static}
        />
        <div className="is-divider" data-content="APPEARANCE"></div>
        <Field
          label="Comb"
          component={Checkbox}
          name="comb"
          tooltip={tooltips.comb}
        />
        {values && values.comb === true && (
          <Field
            label="Comb Length"
            validate={numericality}
            component={TextField}
            name="combLength"
            tooltip={tooltips.combLength}
            type="number"
            placeholder="Comb Length"
          />
        )}
        <Field
          label="Font"
          // Don't delete these options.
          options={[
            { value: 'courier', label: 'Courier' },
            { value: 'helvetica', label: 'Helvetica' },
            { value: 'timesNewRoman', label: 'Times New Roman' }
          ]}
          component={FontPicker}
          name="fontFamily"
        />

        <Field
          label="Size"
          component={TextField}
          name="fontSize"
          type="number"
          step="1"
          placeholder="Font Size"
        />
        <Field
          label="Overflow"
          options={[
            { value: 'flow', label: 'Flow' },
            { value: 'wrap', label: 'Wrap' }
          ]}
          component={Dropdown}
          name="overflow"
        />
        <Field component={BIUButtons} name="boldItalicUnderline" />
        {/* <Field component={AlignmentButtons} name='textAlign' /> */}

        <Field component={HorizontalAlignment} name="textAlign" />
        <Field component={VerticalAlignment} name="verticalAlign" />

        <Field component={ColorPicker} name="color" />
        <div className="is-divider" data-content="VALIDATION"></div>

        <Field
          label="Minimum Length"
          validate={numericality}
          component={TextField}
          name="minLength"
          type="number"
          placeholder="Minimum Length"
        />
        <Field
          label="Maximum Length"
          validate={numericality}
          component={TextField}
          name="maxLength"
          type="number"
          placeholder="Letter Spacing"
        />
        <div className="is-divider" data-content="POSITION"></div>
        <Field
          component={Slider}
          step={0.1}
          min={0}
          max={100}
          name="x"
          label="X"
        />
        <Field
          component={Slider}
          step={0.1}
          min={0}
          max={100}
          name="y"
          label="Y"
        />
        <Field
          component={Slider}
          step={0.1}
          min={0}
          max={100}
          name="width"
          label="Width"
        />
        <Field
          component={Slider}
          step={0.1}
          min={0}
          max={100}
          name="height"
          label="Height"
        />

        <div className="is-divider"></div>
        <DeleteField onDelete={props.onDelete} />
      </React.Fragment>
    );
  }
  function renderAdvanced() {
    return (
      <React.Fragment>
        <Field
          component={Conditional}
          tooltip={tooltips['condition-formula']}
          field={props.field}
          fields={props.fields}
          name="conditionalFormula"
        />
      </React.Fragment>
    );
  }
  function render() {
    return (
      <div className="text_field_editor" onClick={props.onSelect}>
        <form
          onKeyPress={(e) => {
            e.key === 'Enter' && e.preventDefault();
          }}
          onSubmit={(e) => e.preventDefault()}
        >
          {props.view === 'data' ? renderGeneral() : renderAdvanced()}
        </form>
      </div>
    );
  }
  return render();
};
export default reduxForm()(TextFieldEditor);
