import server from 'api/server';
import CollapsibleCard from 'components/_common/CollapsibleCard';
import { Field, Form, Formik } from 'formik';
import useOnClickOutside from 'hooks/useOnClickOutside';
import React, { useEffect, useRef, useState } from 'react';
import { ChromePicker } from 'react-color';
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-responsive-modal';
import { toast } from 'react-toastify';
import { fetchUser } from 'redux/actions';
import { getUser } from 'redux/selectors';
import '../Settings.scss';
import ImageField from './ImageField';

const NewBrandModal = ({ open, onClose, onSuccess, brandQuota }) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [error, setError] = useState(null);
  const onSubmit = async () => {
    try {
      setLoading(true);
      const { data } = await server.post('brands/', { name });
      setLoading(false);
      onClose();
      await onSuccess();
      // if (data.id) window.location = `/integrations/${data.id}`;
    } catch (err) {
      setLoading(false);
      if (err.response && err.response.data && err.response.data.message)
        setError(err.response.data.message);
      else {
        console.log(err);
        onClose();
        toast.info('Could not invite team member!');
      }
    }
  };
  return (
    <Modal open={open} onClose={onClose} closeOnOverlayClick={false}>
      <div className="modal_top">
        <h3 className="title is-h3">Add New Brand Identity</h3>
        <p>
          Use brand identities to stylize workflow forms and emails to match
          your brand.
        </p>
        <p>
          There can be a maximum of {brandQuota || 1} brand identities in this
          account.
        </p>

        <div className="field">
          <label className="label">Brand Name</label>
          <div className="control">
            <input
              className="input is-small"
              type="text"
              placeholder="Brand Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        </div>
        {error && <p className="help is-danger">{error}</p>}
        <hr />
        <div className="field is-grouped">
          <div className="control">
            <button
              onClick={onSubmit}
              className={`button is-info ${loading ? 'is-loading' : ''}`}
            >
              Create
            </button>
          </div>
          <div className="control">
            <button onClick={onClose} className="button is-info is-light">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const ColorSwatch = ({ input, defaultColor, title }) => {
  const [value, setValue] = useState(input.value || defaultColor);
  const { onChange } = input;
  const ref = useRef();
  const [showPicker, setShowPicker] = useState(false);
  useOnClickOutside(ref, () => {
    setShowPicker(false);
  });
  return (
    <div className="color_swatch" ref={ref}>
      <label className="label">{title}</label>
      <br />
      <div
        className="color_block"
        style={{
          padding: '5px',
          background: '#EFF2F5',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer'
        }}
        onClick={() => setShowPicker(!showPicker)}
      >
        <div
          style={{
            width: '120px',
            height: '34px',
            borderRadius: '2px',
            background: value
          }}
        />
      </div>
      {showPicker && (
        <div
          style={{
            position: 'absolute',
            zIndex: '9999'
          }}
        >
          <div
            style={{
              position: 'relative',
              top: '-280px',
              right: '0px',
              bottom: '0px',
              left: '0px'
            }}
          >
            <ChromePicker
              color={value}
              onChange={(color, e) => {
                setValue(color ? color.hex : value);
                onChange(color ? color.hex : value);
              }}
              disableAlpha={true}
            />
          </div>
        </div>
      )}
    </div>
  );
};
const BrandOptions = ({ brand, onSave, onDelete }) => {
  const [loading, setLoading] = useState(false);
  return (
    <Formik
      initialValues={brand}
      onSubmit={async (values) => {
        try {
          setLoading(true);
          const { data } = await server.post(`brands/${brand.id}`, {
            ...values
          });
          setLoading(false);
          toast.info('Brand identity successfully updated!');
          await onSave();
        } catch (err) {
          setLoading(false);
          console.log(err);
          toast.info('Could not update brand identity!');
        }
      }}
    >
      <Form className="brand_options">
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Brand Name</label>
          </div>
          <div className="field-body">
            <div className="field">
              <Field name="name">
                {({ field: { value }, form: { setFieldValue } }) => (
                  <input
                    className="input"
                    value={value}
                    onChange={(e) => setFieldValue('name', e.target.value)}
                  />
                )}
              </Field>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Brand Logo</label>
          </div>
          <div className="field-body">
            <div className="field image_field">
              <Field name="logo" type="text">
                {({ field: { value }, form: { setFieldValue } }) => (
                  <ImageField
                    input={{
                      value,
                      onChange: (value) => setFieldValue('logo', value)
                    }}
                    meta={{}}
                  />
                )}
              </Field>
            </div>
          </div>
        </div>
        {/* <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Brand Icon</label>
          </div>
          <div className="field-body">
            <div className="field image_field">
              <Field name="icon" type="text">
                {({ field: { value }, form: { setFieldValue } }) => (
                  <ImageField
                    input={{
                      value,
                      onChange: (value) => setFieldValue('icon', value)
                    }}
                    meta={{}}
                  />
                )}
              </Field>
            </div>
          </div>
        </div> */}
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Default colors</label>
          </div>
          <div className="field-body">
            <div className="field color_swatches">
              <Field name="accentColor" type="text">
                {({ field: { value }, form: { setFieldValue } }) => (
                  <ColorSwatch
                    title="Accent Color"
                    input={{
                      value,
                      onChange: (value) => setFieldValue('accentColor', value)
                    }}
                    defaultColor="#182838"
                  />
                )}
              </Field>
              <Field name="backgroundColor" type="text">
                {({ field: { value }, form: { setFieldValue } }) => (
                  <ColorSwatch
                    title="Background Color"
                    input={{
                      value,
                      onChange: (value) =>
                        setFieldValue('backgroundColor', value)
                    }}
                    defaultColor="#fff"
                  />
                )}
              </Field>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label"></label>
          </div>
          <div className="field-body">
            <div className="level brand_button_row">
              <div className="level-left">
                <div className="level-item">
                  <button
                    className={`button is-small ${loading ? 'is-loading' : ''}`}
                  >
                    <span className="icon">
                      <i className="far fa-save" />
                    </span>
                    <span>Save</span>
                  </button>
                </div>
              </div>
              <div className="level-right">
                <div className="level-item">
                  <button
                    onClick={async (e) => {
                      const { id } = brand;
                      e.preventDefault();
                      if (
                        window.confirm(
                          'Are you sure you want to delete this brand identity?'
                        )
                      ) {
                        try {
                          setLoading(true);
                          const { data } = await server.delete(`brands/${id}`);
                          setLoading(false);
                          toast.info('Brand identity successfully deleted!');
                          onDelete();
                        } catch (err) {
                          setLoading(false);
                          console.log(err);
                          toast.info('Could not delete brand identity!');
                        }
                      }
                    }}
                    className={`button is-small ${loading ? 'is-loading' : ''}`}
                  >
                    <span className="icon">
                      <i className="far fa-trash-alt" />
                    </span>
                    <span>Delete</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </Formik>
  );
};

const Brands = ({ newModal, setNewModal }) => {
  const [loading, setLoading] = useState(false);
  const [brands, setBrands] = useState([]);
  const user = useSelector(getUser);
  const dispatch = useDispatch();

  const fetchBrands = async () => {
    try {
      setLoading(true);
      const { data } = await server.get('brands/');
      setLoading(false);
      setBrands(data);
      // if (data.id) window.location = `/integrations/${data.id}`;
    } catch (err) {
      setLoading(false);
      console.log(err);
      toast.info('Could not fetch brand identities!');
    }
  };

  useEffect(() => {
    dispatch(fetchUser());
    fetchBrands();
  }, []);

  const renderLoading = () => {
    return (
      <div className="billing_loader_container">
        <Loader type="Oval" color="#ccc" height={30} width={30} />
      </div>
    );
  };

  const renderEmpty = () => {
    return (
      <div className="container" style={{ maxHeight: '350px' }}>
        <div className="empty">
          <div className="content">
            <p>
              <i className="fas fa-swatchbook"></i>
            </p>
            <p>You have not set up any brand identities.</p>
            <p></p>
          </div>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    if (brands && brands.length > 0)
      return (
        <>
          <div>
            {/* <p>
            <strong>Brand Identities</strong>
          </p>
          <p className="brand_description">
            Use brand identities to stylize workflow forms and emails to match
            your brand.
          </p> */}

            {brands &&
              brands.map((brand) => (
                <div className="margin-bottom-10">
                  <CollapsibleCard id={brand.id} title={brand.name}>
                    <BrandOptions
                      brand={brand}
                      onSave={() => {}}
                      onDelete={() => {
                        setBrands(brands.filter((b) => b.id !== brand.id));
                      }}
                    />
                  </CollapsibleCard>
                </div>
              ))}
          </div>
        </>
      );
    else return renderEmpty();
  };
  return (
    <div className="billing">
      <NewBrandModal
        open={newModal}
        onClose={(e) => setNewModal(false)}
        onSuccess={fetchBrands}
        brandQuota={user.usage.usageData.quota.brand_identities}
      />
      {loading ? renderLoading() : renderContent()}
    </div>
  );
};

export default Brands;
