import server from 'api/server';
import FileTree from 'components/_common/FileTree';
import React, { useState } from 'react';
import Loader from 'react-loader-spinner';
import Modal from 'react-responsive-modal';
import { toast } from 'react-toastify';

const MoveModal = ({ open, onClose, file, folderTree }) => {
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [fetchingFolders, setFetchingFolders] = useState(false);
  const [tree, setTree] = useState(folderTree);
  const [selected, setSelected] = useState(false);

  const moveFile = async () => {
    if (!selected) {
      setError('Please select a destination folder');
      return;
    }
    try {
      const { data } = await server.post(`/folders/move`, {
        type: file.type,
        id: file.id,
        parentId: selected === 'root' ? null : selected
      });
      toast.info('File moved successfully');
      onClose();
    } catch (err) {
      toast.info('Something went wrong!');
    }
  };

  const Loading = () => {
    return (
      <div className="loader_container">
        <Loader type="Oval" color="#ccc" height={30} width={30} />
      </div>
    );
  };

  return (
    <Modal open={open} onClose={onClose} closeOnOverlayClick={false}>
      <div className="modal_top move_modal">
        <h3 className="title is-h3">Manage Files</h3>
        <div className="field">
          <p>
            From the list below, select the destination folder to move your
            file.
          </p>
          <div className="control">
            {fetchingFolders ? (
              <Loading />
            ) : (
              <FileTree
                tree={tree}
                selected={selected}
                setSelected={setSelected}
                showRoot={true}
              />
            )}
          </div>
          {error && <p className="help is-danger">{error}</p>}
        </div>
        <hr />
        <div className="field is-grouped">
          <div className="control">
            <button
              id="move-modal-button-save"
              onClick={async () => {
                moveFile();
              }}
              className={`button is-info is-small ${loading ? 'is-loading' : ''}`}
            >
              Save Changes
            </button>
          </div>
          <div className="control">
            <button
              id="move-modal-button-cancel"
              onClick={() => {
                setError('');
                onClose();
              }}
              className="button is-info is-small is-light"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default MoveModal;
