import React from 'react';

export const renderIcon = (type) => {
  if (type === 'text') {
    return <i className="fas fa-font" aria-hidden="true"></i>;
  } else if (type === 'number') {
    return <i className="fas fa-hashtag" aria-hidden="true"></i>;
  } else if (type === 'signature') {
    return <i className="fas fa-signature" aria-hidden="true"></i>;
  } else if (type === 'checkbox') {
    return <i className="fas fa-check" aria-hidden="true"></i>;
  } else if (type === 'dateTime') {
    return <i className="far fa-calendar-alt" aria-hidden="true"></i>;
  } else if (type === 'image') {
    return <i className="far fa-image" aria-hidden="true"></i>;
  } else if (type === 'group') {
    return <i className="fa fa-link" aria-hidden="true"></i>;
  }
};

export function reorderObjectsByGroup(objects, source, destination) {
  const sourceIndex = source.index;
  const destinationIndex = destination.index;

  if (destination.droppableId === 'droppable_root') {
    // Non-parent objects have been moved.
    const childrenIds = [];
    objects
      .filter((obj) => obj.data.type === 'group')
      .forEach((obj) =>
        childrenIds.push(...obj.data.fields.map((f) => f.value))
      );
    const children = objects.filter((obj) => childrenIds.includes(obj.data.id));
    const orphans = objects.filter((obj) => !childrenIds.includes(obj.data.id));

    let list = [...orphans];
    const [removed] = list.splice(sourceIndex, 1);
    list.splice(destinationIndex, 0, removed);
    list.push(...children);
    return list;
  } else {
    // Siblings have been moved.
    const parentId = destination.droppableId.split('_')[1];
    const parent = objects.find((obj) => obj.data.id === parentId);
    let siblings = objects.filter((obj) =>
      parent.data.fields.map((f) => f.value).includes(obj.data.id)
    );
    const siblingIds = siblings.map((s) => s.data.id);
    const [removed] = siblings.splice(sourceIndex, 1);
    siblings.splice(destinationIndex, 0, removed);

    const others = objects.filter((obj) => !siblingIds.includes(obj.data.id));

    let list = [...others, ...siblings];
    return list;
  }
}
