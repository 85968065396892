export default {
  name: 'Name of the field.',
  label: 'Label to display on <br/> online form for this field.',
  description: 'Description is shown <br/> below the field on online form.',
  default: 'Default value of the field.',
  'datatype-text': 'Add data specific <br/> validation and form element.',
  'datatype-number': 'Add data formatting options according to data type.',
  'field-options':
    'These options willl <br/> be shown as a dropdown on the form.',
  required: 'Make the field required.',
  hidden: 'Make the field hidden on <br/>rendered PDF',
  hiddenForm: 'Make the field hidden on <br/>online form',
  static: 'Disable user input. Best used with a default value.',
  comb: 'Display data in equally spaced comb boxes.',
  combLength: 'Number of boxes in comb field.',
  font: '',
  size: '',
  overflow: '',
  style: '',
  alignment: 'Verical Alignment of text.',
  color: '',
  'max-length': 'Maximum input length.',
  'min-length': 'Minimum input length.',
  'max-val': 'Maximum input value.',
  'min-val': 'Minimum input value.',
  x: 'Horizontal position',
  y: 'Vertical position',
  width: '',
  height: '',
  'condition-type': 'Select to show or hide<br/> the field based on the rules.',
  'conditional-formula': 'Use the formula only<br/> when the rules match.',
  'format-date': 'Date format',
  'data-source':
    'Specify wheather user will provide<br/> the signature (User Input) or to always<br/> use the signature drawn below (Predefined).',
  'fit-mode':
    'Fit preserves the aspect ratio whereas cover stretches to fill the entire field area.',
  commaSeparated: 'Separate thousands with a comma.',
  currencyPosition: 'Prefix or postfix position for currency symbol.',
  currencySymbol: 'Currency Symbol to display with the number.',
  decimalPlaces: 'Number of digits to display after the decimal place.',
  inputTypes: 'Specify which signature input type to allow users.',
  language: 'Specify the language of this signature field.'
};
