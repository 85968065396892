import server from 'api/server';
import axiosRetry from 'axios-retry';
import { toast } from 'react-toastify';
import * as types from 'redux/actions/types';

axiosRetry(server, {
  retries: 3,
  retryDelay: (retryCount) => {
    return retryCount * 1000;
  }
});

// Sync qction creators
export const setCurrentStep = (step) => {
  return {
    type: types.SET_CURRENT_STEP,
    payload: step
  };
};

export const updateStep = (step) => {
  return {
    type: types.UPDATE_STEP,
    payload: step
  };
};

export const updateWorkflowData = (order, data) => {
  return {
    type: types.UPDATE_WORKFLOW_DATA,
    payload: { order, data }
  };
};

// Async action creators
export const createWorkflow = (name) => async (dispatch) => {
  try {
    dispatch({ type: types.CREATE_WORKFLOW_REQUEST });
    const { data } = await server.post(`/workflows/create`, {
      name: name || 'Draft'
    });
    dispatch({
      type: types.CREATE_WORKFLOW_SUCCESS,
      payload: data
    });
    window.location.href = `${process.env.REACT_APP_BASE_URL}/workflows/editor/${data.id}`;
    
  } catch (error) {
    dispatch({ type: types.CREATE_WORKFLOW_FAILURE, payload: error.message });
    console.log(error);
  }
};

export const deleteWorkflow = (workflowId) => async (dispatch) => {
  try {
    dispatch({ type: types.DELETE_WORKFLOW_REQUEST });
    await server.post(`/workflows/delete/${workflowId}`);
    dispatch({
      type: types.DELETE_WORKFLOW_SUCCESS,
      payload: workflowId
    });
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      // mixpanel.track('Delete Workflow', { workflowId })
    }
  } catch (error) {
    dispatch({ type: types.DELETE_WORKFLOW_FAILURE, payload: error.message });
    console.log(error);
  }
};

export const cloneWorkflow = (workflowId) => async (dispatch) => {
  try {
    dispatch({ type: types.CLONE_WORKFLOW_REQUEST });
    const { data } = await server.put(`/workflows/${workflowId}/clone`);
    dispatch({
      type: types.CLONE_WORKFLOW_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({ type: types.CLONE_WORKFLOW_FAILURE, payload: error.message });
    console.log(error);
  }
};

export const saveWorkflowToServer = (workflow) => async (dispatch) => {
  try {
    dispatch({ type: types.UPDATE_WORKFLOW_REQUEST });
    const { id, name, active } = workflow;
    await server.post(`/workflows/update`, { id, name, active });
    dispatch({
      type: types.UPDATE_WORKFLOW_SUCCESS,
      payload: workflow
    });
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      // mixpanel.track('Edit Workflow', { workflowId: workflow.id })
    }
  } catch (error) {
    dispatch({ type: types.UPDATE_WORKFLOW_FAILURE, payload: error.message });
    console.log(error);
  }
};

export const updateWorkflow = (workflow) => async (dispatch) => {
  try {
    dispatch({ type: types.UPDATE_WORKFLOW_REQUEST });
    const { id, name, active, settings } = workflow;
    await server.post(`/workflows/update`, { id, name, active, settings });
    dispatch({
      type: types.UPDATE_WORKFLOW_SUCCESS,
      payload: workflow
    });
    toast.info('Workflow settings updated successfully!');
  } catch (error) {
    dispatch({ type: types.UPDATE_WORKFLOW_FAILURE, payload: error.message });
    console.log(error);
    toast.info('Workflow settings could not be updated!');
  }
};

export const activateWorkflow = (workflow) => async (dispatch) => {
  try {
    dispatch({ type: types.ACTIVATE_WORKFLOW_REQUEST });
    const { id, name } = workflow;
    await server.post(`/workflows/update`, { id, name, active: true });
    dispatch({
      type: types.ACTIVATE_WORKFLOW_SUCCESS,
      payload: workflow.id
    });
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      // mixpanel.track('Activate Workflow', { workflowId: workflow.id })
    }
  } catch (error) {
    dispatch({ type: types.ACTIVATE_WORKFLOW_FAILURE, payload: error.message });
    console.log(error);
  }
};

export const deactivateWorkflow = (workflow) => async (dispatch) => {
  try {
    dispatch({ type: types.DEACTIVATE_WORKFLOW_REQUEST });
    const { id, name } = workflow;
    await server.post(`/workflows/update`, { id, name, active: false });
    dispatch({
      type: types.DEACTIVATE_WORKFLOW_SUCCESS,
      payload: workflow.id
    });
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      // mixpanel.track('Deactivate Workflow', { workflowId: workflow.id })
    }
  } catch (error) {
    dispatch({
      type: types.DEACTIVATE_WORKFLOW_FAILURE,
      payload: error.message
    });
    console.log(error);
  }
};

export const deleteStep = (stepId) => async (dispatch) => {
  try {
    dispatch({ type: types.DELETE_STEP_REQUEST });
    await server.post(`/workflows/step/delete/${stepId}`);
    dispatch({
      type: types.DELETE_STEP_SUCCESS,
      payload: stepId
    });
    dispatch(setCurrentStep(''));
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      // mixpanel.track('Delete Step', { stepId })
    }
  } catch (error) {
    dispatch({ type: types.DELETE_STEP_FAILURE, payload: error.message });
    console.log(error);
  }
};

export const addStep = (newStep) => async (dispatch) => {
  try {
    dispatch({ type: types.ADD_STEP_REQUEST });
    const { data } = await server.post(`/workflows/step/create`, {
      step: newStep
    });
    dispatch({
      type: types.ADD_STEP_SUCCESS,
      payload: data
    });
    dispatch(setCurrentStep(data.id));
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      // mixpanel.track('Add Step', { stepId: data.id })
    }
  } catch (error) {
    dispatch({ type: types.ADD_STEP_FAILURE, payload: error.message });
    console.log(error);
  }
};

export const fetchWorkflows = () => async (dispatch) => {
  try {
    dispatch({ type: types.GET_WORKFLOWS_REQUEST });
    const response = await server.get('/workflows/');
    dispatch({
      type: types.GET_WORKFLOWS_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    dispatch({ type: types.GET_WORKFLOWS_FAILURE, payload: error.message });
    console.log(error);
  }
};

export const fetchWorkflowSteps = (workflowId) => async (dispatch) => {
  try {
    dispatch({ type: types.GET_WORKFLOWSTEPS_REQUEST });
    const response = await server.get(`/workflows/steps/${workflowId}`);
    dispatch({
      type: types.GET_WORKFLOWSTEPS_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    dispatch({ type: types.GET_WORKFLOWSTEPS_FAILURE, payload: error.message });
    console.log(error);
  }
};
export const saveStepToServer = (step) => async (dispatch) => {
  try {
    dispatch({ type: types.UPDATE_STEP_REQUEST });
    await server.post(`/workflows/step/update`, { step });
    dispatch({ type: types.UPDATE_STEP_SUCCESS });
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      // mixpanel.track('Update Step', { stepId: step.id })
    }
  } catch (error) {
    dispatch({ type: types.UPDATE_STEP_FAILURE, payload: error.message });
    console.log(error);
  }
};

export const fetchWorkflowHistory = () => async (dispatch) => {
  try {
    dispatch({ type: types.GET_WORKFLOWRUNS_REQUEST });
    const response = await server.get(`/workflows/runs/?page=0&size=5`);
    dispatch({
      type: types.GET_WORKFLOWRUNS_SUCCESS,
      payload: response.data.rows
    });
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      // mixpanel.track('View History')
    }
  } catch (error) {
    dispatch({ type: types.GET_WORKFLOWRUNS_FAILURE, payload: error.message });
    console.log(error);
  }
};
