import { drawerReducer } from 'components/_common/Drawer/redux';
import { loadingBarReducer } from 'react-redux-loading-bar';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { createActionLog } from 'redux-action-log';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
// Reducers
import { reducer as formReducer } from 'redux-form';
import thunk from 'redux-thunk';
// Uncomment below to enable action tracing
// import * as actionCreators from './actions/index';
import {
  appsReducer,
  authReducer,
  documentEditorReducer,
  documentsReducer,
  filesReducer,
  pendingReducer,
  workflowEditorReducer,
  workflowsReducer
} from 'redux/reducers';

let initialState = {
  document: {
    currentPage: 1,
    numPages: 1,
    activeField: null,
    renderedPages: {},
    annotation: {},
    fields: [],
    leftPanel: 'fields',
    currentSelector: 'text',
    editorMode: 'editor',
    renderedPDF: ''
  }
};

let reducers = combineReducers({
  auth: authReducer,
  apps: appsReducer,
  documents: documentsReducer,
  documentEditor: documentEditorReducer,
  workflows: workflowsReducer,
  workflowEditor: workflowEditorReducer,
  form: formReducer,
  drawer: drawerReducer,
  loadingBar: loadingBarReducer,
  pending: pendingReducer,
  files: filesReducer
});

export const actionLog = createActionLog({ limit: 10 });

// Uncomment below to enable action tracing
// const composeEnhancers = composeWithDevTools({
//   actionCreators,
//   trace: true,
//   traceLimit: 25
// });
const composeEnhancers = composeWithDevTools;

const store = createStore(
  reducers,
  initialState,
  composeEnhancers(applyMiddleware(thunk), actionLog.enhancer)
);

export default store;
