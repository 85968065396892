/**
 * Will refresh the current page 
 * if the error message is "Account is no longer active."
 * 
 * @param {Error} error - The error object
*/
const isInactiveAccountError = (error) => {
    if (
        error?.response?.status === 400 &&
        error?.response?.data?.message === "Account is no longer active."
    ) {
        // Force reload; Account is no longer active.
        window.location.reload();
    }
}

export { isInactiveAccountError }