import {SAVE_DOCUMENT} from './types';
import * as types from './types';
import server from 'api/server';

export const saveDocument = (document) => {
  return {
    type: SAVE_DOCUMENT,
    payload: document
  }
}

export const cloneDocument = (documentId) => async (dispatch) => {
  try {
    console.log('inside action');
    dispatch({ type: types.CLONE_DOCUMENT_REQUEST });
    const { data } = await server.put(`/documents/${documentId}/clone`);
    dispatch({
      type: types.CLONE_DOCUMENT_SUCCESS,
      payload: data
    });
  } catch (error) {
    console.log('error',error);
    dispatch({ type: types.CLONE_DOCUMENT_FAILURE, payload: error.message });
    console.log(error);
  }
};