/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import ReactTooltip from 'react-tooltip';

const Dropdown = props => {
  const { value, onChange } = props.input;
  return (
    <div className='field font_select is-horizontal'>
      <div className="field-label is-small">
        <label htmlFor={props.label} className="label">{props.label}</label>
      </div>
      <div className="field-body">
        <p className="control is-expanded select_control">
          <span className="select is-small is-fullwidth">
            <select value={value} onChange={e => { onChange(e.target.value) }}>
              {
                props.options.map(o => <option value={o.value}>{o.label}</option>)
              }
            </select>
          </span>
          {props.tooltip && <i className="fas fa-question-circle" data-tip={props.tooltip}></i>}
        </p>
      </div>
      <ReactTooltip className='field_tooltip' place='left' effect='solid' offset={{ top: 0 }} multiline={true} />
    </div>
  )
}

export default Dropdown;