/* eslint-disable jsx-a11y/anchor-is-valid */
import server from 'api/server';
import useOnClickOutside from 'hooks/useOnClickOutside';
import React, { useEffect, useRef, useState } from 'react';
import ContentLoader from 'react-content-loader';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Moment from 'react-moment';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteDocument } from 'redux/actions';
import { cloneDocument } from 'redux/actions/documentActions';
import './DocumentCard.scss';

const DeleteDocumentModal = (props) => {
  const dispatch = useDispatch();

  const [safe, setSafe] = useState(false);
  useEffect(() => {
    async function checkSafeToDelete() {
      try {
        const response = await server.post('/documents/delete/safe', {
          id: props.id
        });
        if (response.data.safe) {
          setSafe(true);
        } else {
          setSafe(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
    checkSafeToDelete();
  }, [props.open]);

  const onDelete = () => {
    if (safe) {
      dispatch(deleteDocument(props.id));
      toast.info('Document deleted successfully.');
      props.onClose();
    }
  };
  return (
    <div className={`modal ${props.open && 'is-active'}`}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title is-small">Delete Document?</p>
        </header>
        <section className="modal-card-body" style={{ fontSize: '14px' }}>
          {safe &&
            'This will permanently remove the document. This action cannot be undone.'}
          {!safe &&
            'This document is used in one or many Workflows and cannot be deleted.'}
        </section>
        <footer className="modal-card-foot">
          <button
            id="document-card-delete-document-modal-button-cancel"
            className="button is-small"
            onClick={props.onClose}
          >
            Cancel
          </button>
          <button
            id="document-card-delete-document-modal-button-delete"
            disabled={!safe}
            className="button is-small is-danger"
            onClick={onDelete}
          >
            Delete
          </button>
        </footer>
      </div>
    </div>
  );
};

const DuplicateDocumentModal = (props) => {
  const dispatch = useDispatch();

  const onPress = () => {
    dispatch(cloneDocument(props.id));
    toast.info('Duplicating and processing the document...');
    props.onClose();
  };
  return (
    <div className={`modal ${props.open && 'is-active'}`}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title is-small">Duplicate Document?</p>
        </header>
        <section className="modal-card-body" style={{ fontSize: '14px' }}>
          Are you sure you want to duplicate {props.name}?
        </section>
        <footer className="modal-card-foot">
          <button 
            id="document-card-duplicate-document-modal-button-cancel"
            className="button is-small" 
            onClick={(e) => props.onClose()}
          >
            Cancel
          </button>
          <button 
            id="document-card-duplicate-document-modal-button-duplicate"
            className="button is-small is-info" 
            onClick={onPress}
          >
            Duplicate
          </button>
        </footer>
      </div>
    </div>
  );
};

const LoadingThumbnail = ({ url }) => {
  const [loaded, setLoaded] = useState(false);
  return (
    <>
      {loaded ? null : (
        <div style={{ marginTop: '-4px' }}>
          <SkeletonTheme color="#fff" highlightColor="#dddddd">
            <Skeleton duration={1} width={270} height={170} count={1} />
          </SkeletonTheme>
        </div>
      )}
      <div style={{ overflow: 'hidden', maxHeight: '170px' }}>
        <img
          style={
            loaded
              ? {
                minHeight: '180% !important',
                backgroundPosition: 'center center;',
                backgroundRepeat: 'no-repeat',
                overflow: 'hidden',
                objectFit: 'cover'
              }
              : { display: 'none' }
          }
          src={url}
          onLoad={() => setLoaded(true)}
        />
      </div>
    </>
  );
};

const DocumentCard = (props) => {
  const ref = useRef();

  const [isActive, updateIsActive] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [duplicateModalOpen, setDuplicateModalOpen] = useState(false);

  useOnClickOutside(ref, () => {
    updateIsActive(false);
  });

  const renderDropdown = () => {
    return (
      <div className={`dropdown is-right ${isActive ? 'is-active' : ''}`}>
        <div className="dropdown-trigger">
          <button
            id={`document-card-dropdown-open-${props.index}`}
            aria-haspopup="true"
            aria-controls="dropdown-menu"
            className="button is-small"
            onClick={(e) => {
              updateIsActive(!isActive);
            }}
          >
            <i className="menu fas fa-ellipsis-h"></i>
          </button>
        </div>

        <div
          className="dropdown-menu"
          style={{ width: `${props.width}px` }}
          id="dropdown-menu"
          role="menu"
          ref={ref}
        >
          <div className="dropdown-content">
            <Link
              id={`document-card-dropdown-edit-${props.index}`}
              to={`/documents/editor/${props.data.id}`}
              className="dropdown-item"
            >
              <i className="far fa-edit"></i> Edit
            </Link>
            <a
              id={`document-card-dropdown-duplicate-${props.index}`}
              href="#"
              onClick={(e) => {
                setDuplicateModalOpen(true);
                updateIsActive(!isActive);
              }}
              className="dropdown-item"
            >
              <i className="far fa-copy"></i> Duplicate
            </a>
            <a
              id={`document-card-dropdown-delete-${props.index}`}
              href="#"
              onClick={(e) => {
                setDeleteModal(true);
                updateIsActive(!isActive);
                // dispatch((deleteDocument(props.data.id)))
              }}
              className="dropdown-item delete-item"
            >
              <i className="far fa-trash-alt"></i> Delete
            </a>
          </div>
        </div>
      </div>
    );
  };

  const render = () => {
    
  const history = useHistory();

    return (
      <React.Fragment>
        <DeleteDocumentModal
          open={deleteModal}
          id={props.data.id}
          onClose={(e) => setDeleteModal(false)}
        />
        <DuplicateDocumentModal
          open={duplicateModalOpen}
          id={props.data.id}
          name={props.data.name}
          onClose={(e) => setDuplicateModalOpen(false)}
        />
        <div className="document_card tile is-child">
          <div className="card">
            <div
              id={`document-card-card-image-${props.index}`}
              onClick={(e) => {
                history.push(`/documents/editor/${props.data.id}`);
              }}
              className="card-image"
            >
              <LoadingThumbnail url={props.data.thumbnail} />
            </div>
            <div className="card-content">
              <div className="content">
                <i className="fas fa-file-pdf"></i>
                {props.data.name.length < 20
                  ? props.data.name
                  : `${props.data.name.slice(0, 20)}...`}
              </div>
              <nav className="level">
                <div className="level-left updated_date">
                  <Moment className="has-text-grey" format="ddd, MMM Do YYYY">
                    {props.data.updatedAt}
                  </Moment>
                </div>
                <div className="level-right">{renderDropdown()}</div>
              </nav>
            </div>
            {/* <footer className="card-footer">
            <Link to={`/documents/editor/${props.data.id}`} className="card-footer-item card_edit"><i className="far fa-edit"></i> Edit</Link>
            <a href="#" onClick={e => dispatch((deleteDocument(props.data.id)))} className="card-footer-item card_delete"><i className="far fa-trash-alt"></i> Delete</a>
          </footer> */}
          </div>
        </div>
      </React.Fragment>
    );
  };

  if (props.data.processing) {
    return (
      <div className="card processing_document">
        <ContentLoader
          speed={1}
          width={270}
          height={270}
          viewBox="0 0 220 270"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="7" y="6" rx="0" ry="0" width="255" height="165" />
          <rect x="7" y="190" rx="5" ry="5" width="255" height="15" />
          <rect x="7" y="220" rx="5" ry="5" width="140" height="10" />
        </ContentLoader>
      </div>
    );
  } else {
    return render();
  }
};
export default DocumentCard;
