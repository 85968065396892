import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import Dropzone from 'react-dropzone-uploader';

import server from 'api/server';
import { refreshToken } from 'redux/actions';
import { getAccessToken } from 'redux/selectors';

import './Uploader.scss';
import { toast } from 'react-toastify';

const ReplaceDocUploader = (props) => {
  //API: https://react-dropzone-uploader.js.org/docs/api
  useEffect(() => {
    dispatch(refreshToken());
  }, []);

  const dispatch = useDispatch();
  const accessToken = useSelector(getAccessToken);

  const handleChangeStatus = ({ meta, file }, status) => {
    if (status === 'done') {
      setTimeout(() => {
        props.onSuccess();
        toast.info('Processing Document, Please Wait...');
      }, 2000);
    }
  };
  const uploadParams = (meta) => {
    return {
      url: `${server.defaults.baseURL}/documents/re-upload/${props.documentId}`,
      headers: {
        authorization: `bearer ${accessToken}`
      }
    };
  };
  const handleSubmit = (params) => {};
  return (
    <Dropzone
      id="uploader-pdf-dropzone"
      getUploadParams={uploadParams}
      onChangeStatus={handleChangeStatus}
      maxFiles={1}
      multiple={false}
      canCancel={false}
      onSubmit={handleSubmit}
      inputContent={'Drop or click to upload PDF file'}
      SubmitButtonComponent={null}
      accept=".pdf"
    />
  );
};
export default ReplaceDocUploader;
