import server from 'api/server';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import Modal from 'react-responsive-modal';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

const CreateFolderModal = ({
  open,
  onClose,
  currentFolderId,
  siblingFolders
}) => {
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Folder name must be at least 2 characters')
      .max(64, 'Folder name must be less than 65 characters')
      .matches(
        /^[\w\-\s]+$/,
        'Folder name can only contain letters, numbers, underscores and hyphens'
      )
      .required('Folder name is required')
      .test('is-unique', 'Folder name already exists', (value) => {
        return !siblingFolders.includes(value);
      })
  });
  const formik = useFormik({
    initialValues: {
      name: ''
    },
    validationSchema,
    onSubmit: async ({ name }) => {
      try {
        setLoading(true);
        const response = await server.post('/folders', {
          folderName: name,
          parentId: currentFolderId
        });
        setLoading(false);
        onClose();
      } catch (error) {
        toast.info('Something went wrong!');
        onClose();
      }
    }
  });

  const [loading, setLoading] = useState(false);

  return (
    <Modal open={open} onClose={onClose} closeOnOverlayClick={false}>
      <div className="modal_top">
        <h3 className="title is-h3">Create New Folder</h3>
        <form onSubmit={formik.handleSubmit}>
          <div className="field">
            <div className="control">
              <input
                id="create-folder-modal-input-folder-name"
                className="input is-small"
                type="text"
                name="name"
                placeholder="Folder Name..."
                value={formik.values.name}
                onChange={formik.handleChange}
              />
            </div>
            {formik.errors.name && (
              <p className="help is-danger">{formik.errors.name}</p>
            )}
          </div>
          <hr />
          <div className="field is-grouped">
            <div className="control">
              <button
                id="create-folder-modal-button-create"
                type="submit"
                className={`button is-info is-small ${loading ? 'is-loading' : ''}`}
              >
                Create Folder
              </button>
            </div>
            <div className="control">
              <button
                id="create-folder-modal-button-cancel"
                type="button"
                onClick={onClose}
                className="button is-info is-small is-light"
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default CreateFolderModal;
