import server from 'api/server';
import Page from 'components/Page';
import { startCase } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import Loader from 'react-loader-spinner';
import Moment from 'react-moment';
import './ActivityLog.scss';
import ObjectButton from './ObjectButton';

const ActivityLog = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [meta, setMeta] = useState({});
  const [toggleResetDefaultPage, setToggleResetDefaultPage] = useState(false);

  const fetchData = async (page, rowsPerPage) => {
    setLoading(true);

    const response = await server.get(
      `/company/activity/?page=${page - 1}&size=${rowsPerPage}`
    );

    setData(response.data.rows);
    setTotalRows(response.data.totalRows);
    setMeta(response.data.meta);

    setLoading(false);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newRowsPerPage) => {
    setCurrentPage(1);
    setRowsPerPage(newRowsPerPage);
    setToggleResetDefaultPage(!toggleResetDefaultPage);
  };

  useEffect(() => {
    fetchData(currentPage, rowsPerPage);
  }, [currentPage, rowsPerPage]);

  const columns = useMemo(
    () => [
      {
        id: 'activity-log-date',
        name: 'Date',
        selector: 'updatedAt',
        sortable: true,
        width: '11rem',
        cell: (row) => <Moment format="lll">{row.updatedAt}</Moment>
      },
      {
        id: 'activity-log-user',
        name: 'User',
        sortable: true,
        selector: 'user.name',
        cell: (row) => <span>{row['user.name']}</span>
      },
      {
        id: 'activity-log-item',
        name: 'Item',
        sortable: true,
        selector: 'objectType',
        width: '20rem',
        cell: (row) => <ObjectButton object={row} />
      },
      {
        id: 'activity-log-event',
        name: 'Event',
        sortable: true,
        selector: 'eventType',
        width: '11rem',
        cell: (row) => <span>{startCase(row.eventType)}</span>
      },
      {
        id: 'activity-log-description',
        name: 'Description',
        sortable: false,
        selector: 'description',
        width: '20rem',
        cell: (row) => <span>{row.description}</span>
      }
    ],
    []
  );

  const Loading = () => {
    return (
      <div className="loader_container">
        <Loader type="Oval" color="#ccc" height={30} width={30} />
      </div>
    );
  };

  const renderTable = () => {
    return (
      <>
        <section className="section activity_log_section">
          <div className="container">
            <div className="box">
              <div className="log_table">
                <div className="filters">
                  <nav className="level is-mobile">
                    <div className="level-left">
                      <div className="level-item"></div>
                      <div className="level-item"></div>
                    </div>
                  </nav>
                </div>

                <DataTable
                  title=""
                  columns={columns}
                  data={data}
                  progressPending={loading}
                  progressComponent={<Loading />}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  paginationPerPage={rowsPerPage}
                  paginationRowsPerPageOptions={[25, 50, 100, 500]}
                  paginationDefaultPage={1}
                  paginationResetDefaultPage={toggleResetDefaultPage}
                />
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };

  return (
    <Page title="Activity Log" action={<></>} filters={[]}>
      <div className="page activity_log_page">{renderTable()}</div>
    </Page>
  );
};
export default ActivityLog;
