import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux'
import { useHistory } from "react-router-dom";
import Loader from 'react-loader-spinner'
import { logout } from 'redux/actions'

export default (props) => {
  const dispatch = useDispatch()
  const history = useHistory();
  useEffect(() => {
    dispatch(logout())
  }, [])
  return (
    <div className='auth_loader_container'>
      <Loader
        type="Oval"
        color="#ccc"
        height={30}
        width={30} />
    </div>
  )
}