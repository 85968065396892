import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const UseLogic = () => {

    const LoadPdfAsync = async (pdfURL) => {
        if (!pdfURL) {
            return;
        }

        const loadingTask = pdfjs.getDocument(pdfURL);
        var pdfDoc = await loadingTask.promise;

        const pages = [];
        for (let i = 0; i < pdfDoc.numPages; i++) {
            pages.push(getPage(i + 1, pdfDoc));
        }
        
        return await Promise.all(pages)
    }

    const getPage = (num, pdf) => {
        return new Promise((resolve, reject) => {
            pdf.getPage(num).then(page => {
                const scale = 1.5;
                const viewport = page.getViewport({ scale: scale });
                const canvas = document.createElement('canvas');
                const canvasContext = canvas.getContext('2d');
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                page.render({
                    canvasContext, viewport
                })
                    .promise.then(() => {
                        resolve(canvas.toDataURL('image/jpeg'));
                    })
            })
        })
    }
    return {
        LoadPdfAsync
    }
}

export default UseLogic;