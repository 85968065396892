/* eslint-disable jsx-a11y/anchor-is-valid */
import server from 'api/server';
import Page from 'components/Page';
import { lowerCase, startCase } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import Loader from 'react-loader-spinner';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import DropdownButton from './DropdownButton';
import { DropdownFilter } from './filters';
import './History.scss';
import { getStatusIcon } from './utils';

const DocumentHistory = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [count, setcount] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [pageSize, setPageSize] = useState(25);
  const [meta, setMeta] = useState({});
  const [clearRows, setClearRows] = useState(false);
  const [workflowFilter, setWorkflowFilter] = useState(null);
  const fetchData = async (page, updateLoading = true) => {
    if (updateLoading) setLoading(true);
    const response = await server.get(
      `/workflows/docs/?page=${page - 1}&size=${pageSize}${
        workflowFilter ? '&workflow=' + workflowFilter : ''
      }`
    );
    setData(response.data.rows);
    setcount(response.data.count);
    setTotalPages(response.data.totalPages);
    setMeta(response.data.meta);
    if (updateLoading) setLoading(false);
  };

  const handlePageChange = (page) => {
    fetchData(page);
  };

  const handlePerRowsChange = async (newsize, page) => {
    setLoading(true);
    setPageSize(newsize);
    fetchData(page);
  };

  useEffect(() => {
    fetchData(1);
  }, [workflowFilter]);

  useEffect(() => {
    fetchData(1);
  }, []);

  const columns = useMemo(
    () => [
      {
        name: 'File Name',
        sortable: true,
        selector: 'fileName',
        cell: (row) => (
          <Link to={`/history/document/${row.id}`}>
            {row.fileName
              ? `${row.fileName}${row.type === 'PDF' ? '.pdf' : ''}`
              : 'Unnamed'}
          </Link>
        )
      },
      {
        name: 'Status',
        sortable: true,
        selector: 'status',
        cell: (row) => (
          <span>
            {getStatusIcon(row.status)} {startCase(lowerCase(row.status))}
          </span>
        )
      },
      {
        name: 'Workflow',
        sortable: true,
        selector: 'workflowName',
        cell: (row) => row?.workflowName || ' '
      },
      {
        name: 'Last Updated',
        selector: 'updatedAt',
        sortable: true,
        cell: (row) => <Moment format="lll">{row.updatedAt}</Moment>
      },
      {
        cell: (row) => <DropdownButton data={row} />
      }
    ],
    []
  );

  const Loading = () => {
    return (
      <div className="loader_container">
        <Loader type="Oval" color="#ccc" height={30} width={30} />
      </div>
    );
  };

  const renderTop = () => {
    return (
      <nav className="level is-mobile">
        <div className="level-left">
          <div className="level-item">
            <h3 className="title is-4">Document History</h3>
          </div>
        </div>
        <div className="level-right">
          <div className="level-item search">
            {/* <GlobalSearch
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
            /> */}
          </div>
        </div>
      </nav>
    );
  };

  const handleDeleteDocumentHistory = async () => {
    try {
      if (
        window.confirm(
          `Are you sure you want to delete the selected document${
            selectedDocuments.length > 1 ? 's' : ''
          }?`
        )
      ) {
        setLoading(true);
        const response = await server.delete(`/workflows/docs/delete`, {
          data: { documentIds: selectedDocuments }
        });
        await fetchData(1);
        setLoading(false);
        setSelectedDocuments([]);
        toast.info(
          `Document${
            selectedDocuments.length > 1 ? 's' : ''
          } deleted successfully!`
        );
      }
      setClearRows(!clearRows);
    } catch (error) {
      toast.info(
        `Error Deleting Document${selectedDocuments.length > 1 ? 's' : ''}`
      );
      setLoading(false);
      setSelectedDocuments([]);
    }
  };

  const handleDocumentSelected = ({
    allSelected,
    selectedCount,
    selectedRows
  }) => {
    const selectedDocuments = selectedRows.reduce((acc, val) => {
      acc.push(val.id);
      return acc;
    }, []);
    setSelectedDocuments(selectedDocuments);
  };

  const renderTable = () => {
    return (
      <>
        <section className="section documents_table">
          <div className="container">
            {/* <div className="filters filter-box ">
              <DropdownFilter
                options={['All Workflows', ...(meta?.workflows || [])]}
                defaultValueIndex={0}
                onFilter={(value) => {
                  if (value === 'All Workflows') {
                    setWorkflowFilter(null);
                  } else {
                    setWorkflowFilter(value);
                  }
                }}
              />
              <div>
                {selectedDocuments.length > 0 && (
                  <button
                  onClick={handleDeleteDocumentHistory}
                  className="button delete-button upload is-small"
                >
                  <span className="icon">
                    <i className="far fa-trash-alt"></i>
                  </span>
                  <span>Delete</span>
                </button>
                )}
              </div>
            </div> */}
            <div className="box">
              <div className="history_table">
                <div className="filters">
                  <nav className="level is-mobile">
                    <div className="level-left">
                      <div className="level-item"></div>
                      <div className="level-item"></div>
                    </div>
                  </nav>
                </div>

                <DataTable
                  title=""
                  columns={columns}
                  data={data}
                  progressPending={loading}
                  progressComponent={<Loading />}
                  selectableRows
                  pagination
                  paginationServer
                  paginationTotalRows={count}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  paginationPerPage={pageSize}
                  paginationRowsPerPageOptions={[25, 50, 100, 500]}
                  // selectableRowsComponent={Checkbox}
                  // selectableRowsComponentProps={{ inkDisabled: true }}
                  onSelectedRowsChange={handleDocumentSelected}
                  clearSelectedRows={clearRows}
                />
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };

  return (
    <Page
      title="Document History"
      action={
        <Link
          to="/history/workflow"
          className="button is-small action_button_white"
        >
          <i className="fas fa-stream" style={{ marginRight: '10px' }} />
          View Workflow History
        </Link>
      }
      filters={[
        <DropdownFilter
          options={['All Workflows', ...(meta?.workflows || [])]}
          defaultValueIndex={0}
          onFilter={(value) => {
            if (value === 'All Workflows') {
              setWorkflowFilter(null);
            } else {
              setWorkflowFilter(value);
            }
          }}
        />,
        <div>
          <button
            onClick={handleDeleteDocumentHistory}
            className="button delete-button is-small"
            disabled={selectedDocuments.length === 0}
          >
            <span className="icon">
              <i className="far fa-trash-alt"></i>
            </span>
            <span>Delete</span>
          </button>
        </div>
      ]}
    >
      <div className="page historyPage document_history_page">
        {/* <section className="section">
          <div className="container">{renderTop()}</div>
        </section> */}
        {renderTable()}
      </div>
      {/* <Footer /> */}
    </Page>
  );
};
export default DocumentHistory;
