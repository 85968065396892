import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Field, reduxForm, isValid } from 'redux-form';

import GoogleLogin from 'react-google-login';
import GooglePicker from 'react-google-picker';

import EditableLabel from 'react-inline-editing';

import { FieldSelect, ReSelect } from '../components/Select';

import { updateStep, saveStepToServer } from 'redux/actions/workflowActions';
import { fetchDriveApps } from 'redux/actions/appActions';
import { getDriveApps, isActionPending } from 'redux/selectors';

import useExposedFields from './useExposedFields';

import useDimensions from 'hooks/useDimensions';

import { required } from 'redux-form-validators';

import server from 'api/server';

const DriveSettingsComponent = (props) => {
  const step = props.step;
  const dispatch = useDispatch();
  const valid = useSelector((state) => isValid(props.form)(state));
  const prevData = useExposedFields(step.order);
  const drives = useSelector(getDriveApps);
  const pendingUpdate = useSelector((state) =>
    isActionPending(state, 'UPDATE_STEP')
  );
  const driveOptions = drives
    ? [...drives.map((e) => ({ label: e.name, value: e.id, type: e.type }))]
    : [];

  const [ref, { x }] = useDimensions(true);

  useEffect(() => {
    props.initialize(props.initialValues);
    dispatch(fetchDriveApps());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function submit(values) {
    const { driveAccount, filePath, driveFolder } = values;
    console.log(driveAccount, filePath);
    const newStep = {
      ...step,
      name,
      data: {
        ...step.data,
        valid,
        stepData: {
          driveAccount,
          filePath,
          driveFolder
        },
        stepSettings: { driveAccount, filePath, driveFolder },
        exposedFields: []
      }
    };
    dispatch(updateStep(newStep));
    dispatch(saveStepToServer(newStep));
  }

  const gmailAuth = () => {
    const GAPI_CONFIG = {
      clientId:
        '617498649568-r8ar934gsohn7tvafsp7gcbmf9pktr8m.apps.googleusercontent.com',
      //SOURCE: https://stackoverflow.com/a/26940406
      scope: 'openid profile https://www.googleapis.com/auth/drive.file'
    };
    const onSuccess = async (resp) => {
      const authCode = resp.code;
      const response = await server.post(`/apps/drive/auth`, {
        code: authCode
      });
      if (response.data && response.data.success) {
        console.log(response.data);
        dispatch(fetchDriveApps());
      } else {
        console.log(response.error);
      }
    };
    const onFailure = (resp) => {
      console.log(resp);
    };
    //API: https://www.npmjs.com/package/react-google-login
    return (
      <GoogleLogin
        clientId={GAPI_CONFIG.clientId}
        buttonText="Add Drive Account"
        responseType="code"
        scope={GAPI_CONFIG.scope}
        accessType="offline"
        onSuccess={onSuccess}
        onFailure={onFailure}
        cookiePolicy={'single_host_origin'}
      />
    );
  };
  const FolderPicker = (props) => {
    const GAPI_CONFIG = {
      clientId:
        '617498649568-r8ar934gsohn7tvafsp7gcbmf9pktr8m.apps.googleusercontent.com',
      developerKey: 'AIzaSyAANwDj4i1K6XUxrh6RX_lM_wCkID6rL3c'
    };

    return (
      <div className="field is-grouped">
        <div className="control is-expanded">
          <input
            placeholder="Google Drive Folder"
            className="input"
            value={props.input.value.name}
          />
        </div>
        <div className="control">
          <GooglePicker
            clientId={GAPI_CONFIG.clientId}
            developerKey={GAPI_CONFIG.developerKey}
            scope={['https://www.googleapis.com/auth/drive.readonly']}
            onChange={(data) => console.log('on change:', data)}
            onAuthFailed={(data) => console.log('on auth failed:', data)}
            mimeTypes={['application/vnd.google-apps.folder']}
            multiselect={false}
            navHidden={true}
            viewId={'FOLDERS'}
            createPicker={(google, oauthToken) => {
              const googleViewId = google.picker.ViewId.FOLDERS;
              const docsView = new google.picker.DocsView(googleViewId)
                .setIncludeFolders(true)
                .setMimeTypes('application/vnd.google-apps.folder')
                .setSelectFolderEnabled(true);

              const picker = new window.google.picker.PickerBuilder()
                .addView(docsView)
                .setOAuthToken(oauthToken)
                .setDeveloperKey(GAPI_CONFIG.developerKey)
                .setCallback((data) => {
                  if (data.docs) {
                    props.input.onChange({
                      id: data.docs[0].id,
                      name: data.docs[0].name
                    });
                  }
                });

              picker.build().setVisible(true);
            }}
          >
            <button className="button picker-button is-small">
              Select Folder
            </button>
          </GooglePicker>
        </div>
      </div>
    );
  };
  const [name, setName] = useState(step.name || props.defaultName);
  const Topbar = ({ name, setName }) => {
    return (
      <div className="step_bar">
        <nav className="level">
          <div className="level-left">
            <div className="level-item">
              <span className="step_name">
                <Field
                  name="stepName"
                  component={(props) => (
                    <EditableLabel
                      onFocusOut={(text) => {
                        setName(text);
                        props.input.onChange(name);
                      }}
                      inputClassName="input"
                      text={name}
                    />
                  )}
                />
              </span>
            </div>
          </div>
          <div className="level-right">
            <button
              type="submit"
              disabled={pristine}
              className={`button is-small is-info ${
                pendingUpdate && pendingUpdate.pending ? 'is-loading' : ''
              }`}
            >
              Save
            </button>
          </div>
        </nav>
      </div>
    );
  };
  const { handleSubmit, pristine, submitting } = props;
  return (
    <div className="step_setting" ref={ref}>
      {/* <h5 className='title is-5'>Configure Email Notification</h5> */}
      <form onSubmit={handleSubmit(submit)} className="email_setting">
        <Topbar name={name} setName={setName} />
        <div className="fields">
          <label className="label">
            Account <span className="has-text-grey">(required)</span>
          </label>
          <div className="field is-grouped">
            <div className="control is-expanded">
              <Field
                name="driveAccount"
                className="from is-fullwidth"
                component={ReSelect}
                validate={[
                  required({
                    msg: 'Please select Google Drive account to use.'
                  })
                ]}
                options={driveOptions}
                placeholder="Google Drive"
              />
            </div>
            <div className="control">{gmailAuth()}</div>
          </div>
          <p className="help pull-up">
            Select from Google Drive accounts added already or click on Add
            Drive Account.
          </p>
          <article className="message is-warning">
            <div className="message-body">
              <strong>Note: </strong>Using Drive account is an experimental
              feature as Docdown is not a Google verified vendor.
            </div>
          </article>

          <label className="label">
            Folder <span className="has-text-grey">(required)</span>
          </label>
          <Field
            name="driveFolder"
            className="input is-fullwidth"
            component={FolderPicker}
          />
          <p className="help pull-up">
            Select a folder on your Drive to store your files.
          </p>
          <div className="field">
            <label className="label">
              File To Upload <span className="has-text-grey">(required)</span>
            </label>
            <div className="control">
              <Field
                name="filePath"
                component={FieldSelect}
                showMenuOnTouch={true}
                width={x}
                options={prevData}
                validate={[
                  required({ msg: 'Please select a file from the dropdown.' })
                ]}
                exclusive={true}
                typeFilter="attachment"
                openMenuOnClick={false}
                placeholder="File"
              />
            </div>
            <p className="help">Click to select a file from previous steps.</p>
          </div>
        </div>
      </form>
    </div>
  );
};

const DriveSettings = reduxForm()(DriveSettingsComponent);
export default DriveSettings;
