import useDimensions from 'hooks/useDimensions';
import React, { useEffect, useState } from 'react';
import EditableLabel from 'react-inline-editing';
import ReactJson from 'react-json-view';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Field, getFormValues, isValid, reduxForm } from 'redux-form';
import { required } from 'redux-form-validators';
import { fetchDocuments, fetchDocumentSchemas } from 'redux/actions';
import { saveStepToServer, updateStep } from 'redux/actions/workflowActions';
import {
  getDocuments,
  getDocumentSchemas,
  isActionPending
} from 'redux/selectors';
import { validFileName } from 'utils/validators';
import { FieldSelect, ReSelect } from '../components/Select';

/**
 * Settings component for IncomingWebhook workflow step type.
 * 1. Defines local state and reference gloabal state using hooks.
 * 2. Defines other hooks.
 * 3. Define side effects using useEffect hooks. Initializes from
 * `props.initialValues` and fetches documents to display in the settings.
 * 4. Define submit function that saves stepData to server.
 * 5. Define Topbar component of the settings pane.
 * 6. Finally Render the settings form.
 */
const IncomingWebhook = (props) => {
  const step = props.step;

  // ##########################################################################
  // 1. Define local state and reference gloabal state using hooks.
  // ##########################################################################

  const [name, setName] = useState(step.name || props.defaultName);
  const [prevData, updatePrevData] = useState({});

  const documents = useSelector(getDocuments);
  const schemas = useSelector(getDocumentSchemas);
  const valid = useSelector((state) => isValid(props.form)(state));

  const pendingUpdate = useSelector((state) =>
    isActionPending(state, 'UPDATE_STEP')
  );
  const currentValues = useSelector((state) =>
    getFormValues(props.form)(state)
  );

  // ##########################################################################
  // 2. Define other hooks.
  // ##########################################################################

  const dispatch = useDispatch();
  const [ref, { x }] = useDimensions(true);

  // ##########################################################################
  // 3. Define side effects using useEffect hooks.
  // ##########################################################################

  useEffect(() => {
    props.initialize(props.initialValues);
    dispatch(fetchDocuments());
    dispatch(fetchDocumentSchemas());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (documents) {
      if (currentValues && currentValues.selectedDocument) {
        const document = documents.find(
          (d) => d.id === currentValues.selectedDocument.value
        );
        if (document) {
          let usableFields = [];
          document.fields.forEach((f) => {
            if (['text', 'number', 'dateTime'].includes(f.data.type))
              usableFields.push({
                type: 'field',
                name: f.data.name
              });
          });
          updatePrevData({
            '0': usableFields
          });
        }
      }
    }
  }, [currentValues, step, documents]);

  // ##########################################################################
  // 4. Define submit function that saves stepData to server.
  // ##########################################################################

  function submit(values) {
    function createUpdatedStep(values) {
      const { selectedDocument, fileName } = values;
      const document = documents.find((d) => d.id === selectedDocument.value);
      const newStep = {
        ...step,
        name,
        data: {
          ...step.data,
          valid,
          stepData: {
            fileName,
            documentId: document.id,
            documentName: document.name
          },
          stepSettings: {
            fileName,
            selectedDocument: { value: document.id, label: document.name }
          },
          exposedFields: [
            ...document.fields.map((f) => ({
              type: 'field',
              name: f.data.name
            })),
            { type: 'attachment', name: 'generatedDocument' }
          ]
        }
      };
      return newStep;
    }

    const newStep = createUpdatedStep(values);
    dispatch(updateStep(newStep));
    dispatch(saveStepToServer(newStep));
  }

  // ##########################################################################
  // 5. Define Topbar component of the settings pane.
  // ##########################################################################
  const Topbar = ({ name, setName }) => {
    return (
      <div className="step_bar">
        <nav className="level">
          <div className="level-left step-editable-name">
            <div className="level-item">
              <span className="step_name" title="Click to edit name">
                <Field
                  name="stepName"
                  value={name}
                  component={(props) => (
                    <>
                      <EditableLabel
                        onFocusOut={(text) => {
                          setName(text);
                          props.input.onChange(name);
                        }}
                        inputClassName="input"
                        text={name}
                      />
                    </>
                  )}
                />
              </span>
            </div>
            <div className="level-item">
              <i className="fa fa-edit" />
            </div>
          </div>
          <div className="level-right">
            <button
              type="submit"
              disabled={!documents || pristine}
              className={`button is-small is-info ${
                pendingUpdate && pendingUpdate.pending ? 'is-loading' : ''
              }`}
            >
              Save
            </button>
          </div>
        </nav>
      </div>
    );
  };

  // ##########################################################################
  // 6. Render the settings form.
  // ##########################################################################
  const { handleSubmit, pristine } = props;
  return (
    <div className="step_setting" ref={ref}>
      <form onSubmit={handleSubmit(submit)}>
        <Topbar name={name} setName={setName} />
        <div className="fields">
          <label className="label">Webhook Endpoint </label>

          <div className="field has-addons">
            <p className="control is-expanded">
              <input
                disabled
                value={`${process.env.REACT_APP_SERVER_BASE_URL}/trigger/${step.workflowId}`}
                className="input is-small"
              ></input>
            </p>
            <p className="control">
              <a
                className="button copy_button"
                onClick={() => {
                  navigator.clipboard.writeText(
                    `${process.env.REACT_APP_SERVER_BASE_URL}/trigger/${step.workflowId}`
                  );
                  toast.info('URL copied to clipboard');
                }}
              >
                <i className="far fa-copy"></i>
              </a>
            </p>
          </div>
          <p className="help pull-up help-long">
            Please check the{' '}
            <a
              href="https://developers.docdown.io/#introduction"
              target="_blank"
            >
              API reference
            </a>{' '}
            for details. You can find your API key{' '}
            <a href="/settings/account" target="_blank">
              here
            </a>
            . If the workflow is not active, incoming requests are not accepted.
          </p>
          <div className="field">
            <label className="label">
              Document <span className="has-text-grey">(required)</span>
            </label>
            <div className="control has-icons-left has-icons-right">
              <Field
                // onChange={e => { currentValues && submit(currentValues) }}
                name="selectedDocument"
                component={ReSelect}
                disabled={!documents ? true : false}
                options={documents.map((d, i) => ({
                  value: d.id,
                  label: d.name
                }))}
                validate={[required({ msg: 'Please select a document.' })]}
              />
            </div>
            <p className="help">Select document to generate request schema.</p>
          </div>
          <div className="field">
            <label className="label">Request Schema </label>
            {schemas &&
              currentValues &&
              currentValues.selectedDocument.value && (
                <ReactJson
                  name="schema"
                  src={
                    schemas[currentValues.selectedDocument.value]
                      ? schemas[currentValues.selectedDocument.value].fields
                      : {}
                  }
                  collapsed
                  displayDataTypes={false}
                  enableClipboard
                />
              )}
            <br />
            <p className="help pull-up">
              The request body should adhere to this schema object.
            </p>
          </div>

          <label className="label">
            File Name <span className="has-text-grey">(required)</span>
          </label>
          <div className="field">
            <p className="control">
              {/* <Field
                name="fileName"
                component={TextField}
                className="input"
                placeholder="File Name"
                validate={[
                  alphaNumeric,
                  required({
                    msg: 'Please provide a filename for the generated document.'
                  })
                ]}
              /> */}
              <Field
                name="fileName"
                component={FieldSelect}
                width={x}
                multiple=" "
                options={prevData}
                typeFilter="field"
                validate={[
                  validFileName,
                  required({
                    msg: 'Please provide a filename for the generated document.'
                  })
                ]}
              />
            </p>
            <p className="help">
              Alpha numeric characters only. File extension ".pdf" will be added
              automatically.
            </p>
          </div>
        </div>
      </form>
    </div>
  );
};

const IncomingWebhookSettings = reduxForm({
  form: 'incoming_webhook',
  enableReinitialize: true
})(IncomingWebhook);
export default IncomingWebhookSettings;
