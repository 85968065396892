import axios from 'axios';
import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { isInactiveAccountError } from '../helper.js';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const AuthForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const onSubmit = async (values) => {
    const { formPassword } = values;
    try {
      setLoading(true);
      setError(null);
      await axios.post(
        `${baseURL}/form/auth`,
        {
          stepId: props.stepId,
          formPassword
        },
        { headers: { Authorization: `public ${props.token}` } }
      );
      props.onSuccess();
    } catch (error) {
      isInactiveAccountError(error);

      if (
        error?.response?.status === 401
      ) {
        setError('Incorrect password');
      }
      else {
        setError("An error occurred while submitting the form.");
      }
    }
    setLoading(false);
  };

  const { handleSubmit, submitting, pristine } = props;

  return (
    <div className="form_auth">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="field">
          <label className="label">Please enter form password </label>
          <p className="control">
            <Field
              component="input"
              type="password"
              className={`input is-small ${submitting && 'is-loading'}`}
              name="formPassword"
            />
          </p>
          {error && <p className="help is-danger">{error}</p>}
        </div>
        <button
          disabled={pristine}
          type="submit"
          className={`button is-small is-info ${loading ? 'is-loading' : ''}`}
        >
          Submit
        </button>
      </form>
    </div>
  );
};

const wrappedForm = reduxForm({ form: 'triggerAuthForm' })(AuthForm);
export default wrappedForm;
