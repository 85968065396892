import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFields, getActiveField } from 'redux/selectors'
import FieldEditorPanel from './FieldEditorPanel';
import './RightSidebar.scss';

class FieldsContainer extends Component {

  render() {
    return (
      <div className='fields_container column is-pulled-right has-background-white-ter'>
        {/* eslint-disable-next-line array-callback-return*/}
        {this.props.fields.map((field, index) => {
          if (this.props.activeField === field.data.id) {
            return <FieldEditorPanel documentId={this.props.documentId} key={index} index={index} field={field} />
          }
        })}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  fields: getFields(state),
  activeField: getActiveField(state)
})
export default connect(mapStateToProps, null)(FieldsContainer);